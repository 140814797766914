<template>
  <div class="div_home">
    <!-- 主体样式 -->
    <el-card>
      <div class="div_home_title">
        <el-row :gutter="25">
          <!-- 搜索区域 -->
          <!-- 搜索添加 -->
          <!-- 搜索按钮 -->
          <!-- <el-col :span="10"> -->
          <el-col>
            <el-button type="primary" @click="toShowMassUpdate">
              批量更新
            </el-button>
            <el-button type="primary" @click="getList"> 刷新表格 </el-button>
            <el-button type="primary" @click="toShowMassUpdateVersion">
              批量版本更新
            </el-button>
          </el-col>
        </el-row>
      </div>
      <!-- 列表 border:边框  stripe隔行变色-->
      <el-table :data="tableList" border stripe>
        <el-table-column type="index"></el-table-column>
        <!-- 索引列 -->
        <el-table-column
          label="模板id"
          prop="templateId"
          align="center"
          width="180"
        ></el-table-column>
        <el-table-column
          label="模板名称"
          prop="templateId"
          align="center"
          width="180"
        >
          <template slot-scope="scope">
            {{
              templateMap[scope.row.templateId] != undefined
                ? templateMap[scope.row.templateId].name
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column
          label="模板版本号"
          prop="templateVersion"
          align="center"
          width="150"
        ></el-table-column>
        <el-table-column
          label="商家小程序版本号"
          prop="appVersion"
          align="center"
          width="150"
        ></el-table-column>
        <el-table-column
          label="操作时间"
          prop="time"
          align="center"
          width="180"
        ></el-table-column>
        <el-table-column
          label="记录时间"
          prop="gmtCreate"
          align="center"
          width="180"
        ></el-table-column>
        <el-table-column
          label="备注"
          prop="remark"
          align="center"
          width="300"
        ></el-table-column>
        <el-table-column
          label="成功数"
          prop="success"
          align="center"
          width="150"
          fixed="right"
        ></el-table-column>
        <el-table-column
          label="失败数"
          prop="error"
          align="center"
          width="150"
          fixed="right"
        ></el-table-column>
        <el-table-column label="操作" width="150" fixed="right">
          <template slot-scope="scope">
            <!-- 修改 -->
            <el-tooltip
              effect="dark"
              content="查看详情"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-search"
                size="mini"
                @click="showMassUpdateLog(scope.row)"
              ></el-button>
            </el-tooltip>
            <!-- 删除 -->
            <el-tooltip
              effect="dark"
              content="删除"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="deleteThis(scope.row.id)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- element分页组件 size-change每页最大变化数 current-change当前最大变化 layout功能组件-->
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pageNum"
          :page-sizes="[5, 10, 20, 100]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>
<script>
import { pageSelect, del } from "@/services/massUpdate.js";
export default {
  props: ["templateList", "showType", "flushTable"],
  data() {
    return {
      queryInfo: {
        //查询信息
        query: "",
        pageNum: 1, //当前页
        pageSize: 10, //每页最大数
      },
      tableList: [], // 表格列表
      total: 0, //总记录数
      // 模板id：模板信息
      templateMap: {},
    };
  },
  created() {
    this.init();
  },
  watch: {
    templateList() {
      this.initTemplateMap();
    },
    showType() {
      this.showTypeUpdate();
    },
  },
  methods: {
    init() {
      this.initTemplateMap();
      this.getList();
    },
    initTemplateMap() {
      let templateList = this.templateList;
      let templateMap = {};
      for (let i = 0; i < templateList.length; i++) {
        const single = templateList[i];
        templateMap[single.id] = single;
      }
      this.$set(this, "templateMap", templateMap);
    },
    showTypeUpdate() {
      let showType = this.showType;
      if (showType == 1) {
        //进入列表
        if (this.flushTable) {
          // 刷新表格
          this.$emit("setFlushTable", false);
          this.getList();
        }
      }
    },
    getNewList() {
      this.queryInfo.pageNum = 1;
      this.getList();
    },
    // 获取表格列表
    async getList() {
      const res = await this.$publicJs.request(
        this,
        pageSelect,
        this.queryInfo
      );
      if (!res.success) {
        return;
      }
      this.tableList = res.data.records;
      this.total = res.data.total;
    },
    //最大数变更
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getList();
    },
    //当前页面变化触发
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage;
      this.getList();
    },
    // 删除信息
    async deleteThis(id) {
      const result = await this.$confirm("此操作将永久删除,是否继续?", "提示", {
        confirmButtonText: "确定",
        cancleButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (result != "confirm") {
        //取消删除
        return;
      }
      const res = await this.$publicJs.request(this, del, id, true);
      if (!res.success) {
        return;
      }
      this.getList(); //刷新表格
    },
    //更改信息对话框
    async showMassUpdateLog(e) {
      this.$emit("showMassUpdateLog", e.id);
    },
    toShowMassUpdate() {
      this.$emit("showMassUpdate");
    },
    toShowMassUpdateVersion() {
      this.$emit("toShowMassUpdateVersion");
    },
  },
};
</script>
<style lang="less" scoped>
.el-breadcrumb {
  margin-bottom: 15px;
  font-size: 17px;
}
.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}
/deep/.el-form-item__label {
  width: 100px !important;
}
/deep/.el-form-item__content {
  margin-left: 100px !important;
}
</style>
