<template>
  <div class="div_main">
    <el-card>
      <el-form :model="formMes" :rules="rules" ref="formRef" label-width="auto">
        <el-form-item label="logo" prop="img">
          <el-upload
            class="avatar-uploader"
            list-type="picture-card"
            :auto-upload="false"
            action="#"
            :show-file-list="false"
            :on-change="imgChange"
          >
            <img class="div_main_img" v-if="formMes.img" :src="formMes.img" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <!-- 跳转类型 -->
        <el-form-item label="跳转类型" prop="jumpType">
          <el-radio v-model="formMes.jumpType" label="1">h5</el-radio>
          <el-radio v-model="formMes.jumpType" label="2">小程序</el-radio>
          <!-- <el-radio v-model="formMes.jumpType" label="3">生活号</el-radio> -->
        </el-form-item>
        <!-- h5路径 -->
        <el-form-item label="h5路径" prop="h5Url" v-if="formMes.jumpType == 1">
          <el-input
            type="textarea"
            :rows="2"
            v-model="formMes.h5Url"
            placeholder="请输入跳转h5路径"
          >
          </el-input>
        </el-form-item>
        <template v-if="formMes.jumpType == 2">
          <!-- 跳转小程序 -->
          <el-form-item label="小程序id" prop="appId">
            <el-input
              clearable
              v-model="formMes.appId"
              placeholder="请输入跳转的小程序id"
              maxlength="100"
            ></el-input>
          </el-form-item>
          <el-form-item label="页面路径" prop="appletPath">
            <el-input
              clearable
              v-model="formMes.appletPath"
              placeholder="请输入跳转的小程序页面路径(没有放空即可)"
              maxlength="100"
            ></el-input>
          </el-form-item>
        </template>

        <el-form-item
          label="生活号id"
          prop="lifeNumber"
          v-if="formMes.jumpType == 3"
        >
          <el-input
            clearable
            v-model="formMes.lifeNumber"
            placeholder="请输入跳转的生活号id"
            maxlength="100"
          ></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-switch v-model="formMes.status"> </el-switch>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="save">保 存</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { getMes, updateMes } from "@/services/promotionalPoster.js";
export default {
  data() {
    return {
      file: {
        imgFile: null,
      },
      formMes: {},
      // 表单验证
      rules: {
        h5Url: [
          { required: true, message: "请输入跳转的h5链接", trigger: "blur" },
        ],
        appId: [
          { required: true, message: "请输入跳转的小程序id", trigger: "blur" },
        ],
        lifeNumber: [
          { required: true, message: "请输入跳转的生活号id", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    // 初始化
    async init() {
      const res = await this.$publicJs.request(this, getMes);
      if (!res.success) {
        return;
      }
      this.$set(this, "formMes", res.data);
    },
    save() {
      this.$refs.formRef.validate(async (valid) => {
        if (!valid) return;
        const formData = this.$publicJs.formMesUpdateFormDate(
          this.formMes,
          this.file
        );
        let res = await this.$publicJs.request(this, updateMes, formData, true);
        if (!res.success) {
          return;
        }
        this.$set(this.file, "imgFile", null);
      });
    },
    imgChange(e) {
      this.$set(this.formMes, "img", e.url);
      this.$set(this.file, "imgFile", e.raw);
    },
  },
};
</script>

<style lang="less" scoped>
.div_main_img {
  height: 100%;
  width: 100%;
}
</style>
