<template>
    <div class="div_home">
      <el-card>
        <div class="div_home_title">
          <el-row :gutter="25">
            <!-- 搜索区域 -->
            <!-- 搜索添加 -->
            <div class="head_input">
              <div class="div_input">
                <label class="div_input_text">用户名:</label>
                <el-input
                  placeholder="请输入用户名"
                  v-model="queryInfo2.username"
                  clearable
                >
                </el-input>
              </div>
              <div class="div_input">
                <label class="div_input_text">手机号:</label>
                <el-input
                  placeholder="请输入手机号"
                  v-model="queryInfo2.mobile"
                  clearable
                >
                </el-input>
              </div>
             
              <div class="div_input">
                <label class="div_input_text">状态:</label>
                <el-select
                  v-model="queryInfo2.status"
                  clearable
                  placeholder="全部"
                >
                  <el-option v-for="item in statuslist" :key="item.id" :label="item.title" :value="item.id"> </el-option>               
                </el-select>
              </div>
              <el-button
                class="headButtonSingle"
                type="primary"
                @click="search()"
                icon="el-icon-search"
                >搜索
              </el-button>
              <el-button
                class="headButtonSingle"
                type="primary"
                @click="clearSearch()"
                icon="el-icon-refresh"
                >重置
              </el-button>
              <el-button
                class="headButtonSingle"
                type="primary"
                @click="addDialogVisible = true"
                icon="el-icon-plus"
                >新增
              </el-button>
            </div>
          </el-row>
        </div>
        <!-- 列表 border:边框  stripe隔行变色-->
        <el-table :data="pageMes.list" border stripe>
          <el-table-column type="index" fixed></el-table-column>
          <el-table-column
            label="用户名"
            prop="username"
            width="200"
            fixed
          ></el-table-column>
          <el-table-column
            label="手机号"
            prop="mobile"
            width="200"
          ></el-table-column>
          <el-table-column
            label="人头像"
            prop="headImg"
          >
          <template slot-scope="scope">
           <a :href="scope.row.headImg" target="_blank"><img :src="scope.row.headImg" width="30" height="30" /></a> 
          </template>
        </el-table-column>
          <el-table-column
            label="性别"
            prop="sex"
            width="50"
          ></el-table-column>
          <el-table-column
            label="邮箱"
            prop="email"
            width="200"
          ></el-table-column>
          <el-table-column
            label="创建时间"
            prop="createTime"
            width="200"
          ></el-table-column>
          <el-table-column label="状态" prop="status">
            <!-- 作用域插槽 -->
            <template slot-scope="scope">
              <!-- {{scope.row}}每一行封装的数据 -->
              <el-switch
                v-model="scope.row.status"
                @change="userStateChange(scope.row)"
              ></el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作" fixed="right" width="150">
            <template slot-scope="scope">
              <!-- 修改 -->
              <el-tooltip
                effect="dark"
                content="编辑"
                placement="top-start"
                :enterable="false"
              >
                <el-button
                  type="primary"
                  icon="el-icon-edit"
                  size="mini"
                  @click="showEditDialog(scope.row)"
                ></el-button>
              </el-tooltip>
              <!-- 删除 -->
              <el-tooltip
                effect="dark"
                content="删除"
                placement="top-start"
                :enterable="false"
              >
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  size="mini"
                  @click="deleteThis(scope.row.id)"
                ></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <!-- element分页组件 size-change每页最大变化数 current-change当前最大变化 layout功能组件-->
        <div class="block">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="queryInfo.pageNo"
            :page-sizes="[5, 10, 20, 100]"
            :page-size="queryInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageMes.total"
          >
          </el-pagination>
        </div>
      </el-card>
      <!-- 新增信息 -->
      <el-dialog
        title="添加"
        :visible.sync="addDialogVisible"
        width="50%"
        @close="addDialogClose"
      >
        <usersAdd :refaushList="getList" ref="addRef" />
      </el-dialog>
     
      <!-- 编辑信息 -->
      <el-dialog
        title="编辑"
        :visible.sync="updateDialogVisible"
        width="50%"
        @close="updateDialogClose"
      >
        <usersUpdate
          :refaushList="getList"
          :updateId="updateId"
          ref="updateRef"
        />
      </el-dialog>
    </div>
  </template>
  
  <script>
  import usersAdd from "./add.vue";
  import usersUpdate from "./update.vue";
  export default {
    components: {
      usersAdd,
      usersUpdate,
    },
    data() {
      return {
        statuslist:[
          {id:-1,title:"全部"},
          {id:1,title:"开启"},
          {id:0,title:"关闭"}
        ],
        // 搜索内容(点击搜索后将queryInfo2值赋予该对象)
        queryInfo: {
          //查询信息
          username: "",
          mobile: "",
          sortBy:"",
          isAsc:false,
          status: -1,
          pageNo: 1, //当前页
          pageSize: 10, //每页最大数
          smallCodeList: [], //查询的小程序唯一标识列表
        },
        // 搜索内容
        queryInfo2: {
          //查询信息
          username: "",
          mobile: "",
          sortBy:"",
          isAsc:false,
          status: -1,
          pageNo: 1, //当前页
          pageSize: 10, //每页最大数
          smallCodeList: [], //查询的小程序唯一标识列表
        },
        // 分页信息
        pageMes: {
          list: [],
          total: 0,
        },
        // 是否展示新增弹窗
        addDialogVisible: false,
        // 是否展示编辑弹窗
        updateDialogVisible: false,
        // 编辑的对象id
        updateId: "",
        // 是否展示配置弹窗
        updateSettingVisible: false,
        // 编辑的对象code
        updateCode: "",
      };
    },
    created() {
      // 获取当前需要展示的页码及条件(如果是消息模板返回的时候有值，没有则默认从第一页开始)
      this.getQueryInfo();
      this.getList();
    },
    methods: {
      // 获取表格列表查询条件
      getQueryInfo() {
        let query = this.$route.query;
        if (null == query || "" == query || undefined == query) {
          return;
        }
        let queryObj = query.queryInfo;
        if (undefined == queryObj || undefined == queryObj.pageNum) {
          return;
        }
        this.queryInfo = { ...queryObj };
        this.queryInfo2 = { ...queryObj };
        
      },
      //点击搜索
      search() {
        let queryInfo = this.queryInfo;
        let queryInfo2 = this.queryInfo2;
        queryInfo = { ...queryInfo2 };
        // console.log("对象："+JSON.stringify(queryInfo));
        this.queryInfo = queryInfo;
        this.getList();
      },
      // 重置搜索内容
      clearSearch() {
        let queryInfo = {
          username: "",
          mobile: "",
          sortBy:"",
          isAsc:false,
          status: -1,
          pageNo: 1, //当前页
          pageSize: 10, //每页最大数
          smallCodeList: [], //查询的小程序唯一标识列表
        };
        this.queryInfo = { ...queryInfo };
        this.queryInfo2 = { ...queryInfo };
        this.getList();
      },
      // 获取列表
      async getList() {
        const { data: res } = await this.$http.post(
          "/users/getList",
          this.queryInfo
        );
        if (res.list.length==0) {
          return this.$message.error("获取数据为空");
        }
        this.pageMes.list = res.list;
        console.log(this.pageMes.list);
        for (let index = 0; index < this.pageMes.list.length; index++) {        
          if(this.pageMes.list[index].status==1)
          {
            this.pageMes.list[index].status=true;
          }
          else
          {
            this.pageMes.list[index].status=false;
          }
        }
        this.pageMes.total = res.total;
      },
      // 最大数发生变化
      handleSizeChange(newSize) {
        this.queryInfo.pageSize = newSize;
        this.getList();
      },
      // 换页
      handleCurrentChange(newPage) {
        this.queryInfo.pageNo = newPage;
        this.getList();
      },
      // 关闭新增弹窗
      addDialogClose() {
        this.$refs.addRef.resetForm();
      },
      // 关闭编辑弹窗
      updateDialogClose() {
        this.updateId = "";
      },
      // 编辑
      showEditDialog(e) {
        if (this.$refs.updateRef == undefined) {
          this.updateId = e.id;
        } else {
          this.$refs.updateRef.refaushForm(e.id);
        }
        this.updateDialogVisible = true;
      },
      // 删除
      async deleteThis(id) {
        const result = await this.$confirm("此操作将永久删,是否继续?", "提示", {
          confirmButtonText: "确定",
          cancleButtonText: "取消",
          type: "warning",
        }).catch((err) => err);
        if (result != "confirm") {
          //取消删除
          return;
        }
        const loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        const { data: res } = await this.$http.delete(
          "/users/delete?id=" + id
        );
        loading.close();
        if ("200" != res.code) {
          return this.$message.error("删除失败:" + res.message);
        }
        this.$message.success("删除成功");
        this.getList(); //刷新表格
      },
      // 修改状态
      async userStateChange(e) {
        let status=0;
        if(e.status)
        {
          status=1;
        }
        const loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        const { data: res } = await this.$http.put(
          `/users/updatestatus`,
          {
            id: e.id,
            status: status
          }
        );
        loading.close();
        if (res.code != "200") {
          e.status = !e.status;
          return this.$message.error(res.message);
        }
        this.$message.success("操作成功");
      },
    },
  };
  </script>
  
  <style lang="less" scoped>
  .div_home {
    // height: 90%;
    .commodityImg {
      width: 100px;
      height: 100px;
    }
    .head_input {
      display: flex;
      .div_input {
        display: flex;
        align-items: center;
        // flex: 1;
        // max-width: 350px;
        padding-right: 5px;
        .div_input_text {
          white-space: nowrap;
          padding-right: 5px;
        }
      }
    }
    .head_input2 {
      padding-top: 10px;
    }
  }
  </style>
  