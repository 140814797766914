import Vue from "vue";
import VueRouter from "vue-router";
// 引入组件
import Login from "../components/Login.vue";
import Home from "../components/Home.vue";
import Welcome from "../components/Welcome.vue";
import UserList from "../components/home/UserList.vue";
import RoleList from "../components/home/RoleList";
import Commodity from "../components/home/Commodity";
import Company from "../components/company";
import SmallRoutine from "../components/smallRoutine/smallRoutine";
import MessageTemplate from "../components/smallRoutine/messageTemplate";
import MessageTemplateService from "../components/smallRoutine/messageTemplateService";
import MessageTemplateServiceRel from "../components/smallRoutine/messageTemplateServiceRel";
import SyncLabel from "../components/smallRoutine/syncLabel";
import YunMaConfiguration from "../components/yunMa/yunMaConfiguration";
import UserMoneyUpdateLog from "../components/userMoneyLog/updateLog";
import UserWithdrawalRecordLog from "../components/userMoneyLog/withdrawalRecordLog";
import Applet2Configuration from "../components/applet2/configuration/configuration";
import Applet2Advertisement from "../components/applet2/advertisement/advertisement";
import SmallRoutine2 from "../components/applet2/smallRoutine2/smallRoutine2";
import TaobaoCommodityInterface from "../components/taobao/commodityInterface/commodityInterface";
import SmallRoutine3 from "../components/applet3/smallRoutine3";
import MessageTemplate3 from "../components/applet3/messageTemplate";
import MessageTemplateService3Rel from "../components/applet3/messageTemplateServiceRel";
import AlipayUserAlipayPointSend from "../components/configuration/alipayUserAlipayPointSend";
import SmallRoutine3LuckDraw from "../components/applet3/smallRoutine3LuckDraw/smallRoutine3LuckDraw";
import SmallRoutine3BottomCommodityMenuOne from "../components/applet3/bottomCommodity/menuOne/list";
import SmallRoutine3BottomCommodityMenuTwo from "../components/applet3/bottomCommodity/menuTwo/list";
import SmallRoutine3BottomCommodity from "../components/applet3/bottomCommodity/commodity/list";
import FilmConfiguration from "../components/configuration/filmConfiguration";
import FilmOrderList from "../components/film/orderList";
import FilmStatistics from "../components/film/filmStatistics";
import TurntableReward from "../components/configuration/turntableReward/turntableReward";
import TemplateApplet from "../components/template/applet/applet";
import TemplateApplet1 from "../components/template/applet1/applet1";
import ShortLink from "../components/shortLink/shortLink/list";
import ShortLinkService from "../components/shortLink/shortLinkService/list";
import ThirdPartyApplications from "../components/thirdParty/applications/applications";
import ThirdPartyTemplate from "../components/thirdParty/template";
import ThirdPartySmallRoutine from "../components/thirdParty/smallRoutine";
import MerchantCoupon from "../components/aliPay/merchantCoupon";
import MarketingPictures from "../components/aliPay/marketingPictures";
import CreateSmallRoutine from "../components/createSmallRoutine";
import TikTokController from "../components/tiktok/tiktok";
import TikTokConfiguration from "../components/tiktok/tikTokConfiguration";
import H5CopyConfiguration from "../components/other/h5CopyConfiguration";
import ThirdPartyAppAd from "../components/thirdParty/smallRoutine/appad/list";
import Users from "../components/users/list";
import Plants from "../components/plants/plants/list";
import PlantsType from "../components/plants/plantstype/list";
import Stage from "../components/stage/list";
import GrowthRecords from "../components/growthrecords/list";
import WaterRecords from "../components/waterrecords/list";
import Fertilizing from "../components/fertilizing/list";
import Userenergy from "../components/userenergy/list";
import Energy from "../components/energy/list";
import Userconfigedit from "../components/userconfig/userconfigedit";
import Usersignins from "../components/usersignins/list";
import Usershippingaddresses from "../components/usershippingaddresses/list";
import Orders from "../components/orders/list";
// 口令红包默认
import PasswordRedEnvelopeDefault from "../components/thirdParty/other/passwordRedEnvelope/defaultMes.vue";
// 新人红包默认
import RedEnvelopeForNewcomersDefault from "../components/thirdParty/other/redEnvelopeForNewcomers/defaultConfiguration.vue";
// 新人红包列表
import RedEnvelopeForNewcomersList from "../components/thirdParty/other/redEnvelopeForNewcomers/contentList";
// 新人红包统一配置
import RedEnvelopeForNewcomersUnifiedConfiguration from "../components/thirdParty/other/redEnvelopeForNewcomersUnifiedConfiguration";
// 小程序批量更新
import MassUpdate from "../components/massUpdate";
// 活动小程序海报图
import PromotionalPoster from "../components/promotionalPoster";
import BasicInformation from "../components/basicInformation";
import CountPageView from "../components/countPageView";

// 遮罩层
import Mask from "../components/mask";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    component: Login,
    meta: { title: "登录" },
  },
  {
    path: "/home",
    component: Home,
    redirect: "/welcome",
    children: [
      {
        path: "/welcome",
        component: Welcome,
        meta: { title: "欢迎" },
      },
      {
        path: "/company",
        component: Company,
        meta: { title: "公司列表" },
      },
      {
        path: "/user",
        component: UserList,
        meta: { title: "用户列表" },
      },
      {
        path: "/role",
        component: RoleList,
        meta: { title: "角色列表" },
      },
      {
        path: "/commodity",
        component: Commodity,
        meta: { title: "商品列表" },
      },
      {
        path: "/smallRoutine",
        component: SmallRoutine,
        meta: { title: "小程序管理" },
      },
      {
        path: "/messageTemplate",
        component: MessageTemplate,
        meta: { title: "小程序管理——消息模板" },
      },
      {
        path: "/smallRoutineServiceRel",
        component: MessageTemplateServiceRel,
        meta: { title: "小程序管理——服务管理" },
      },
      {
        path: "/smallRoutineService",
        component: MessageTemplateService,
        meta: { title: "小程序服务管理" },
      },
      {
        path: "/syncLabel",
        component: SyncLabel,
        meta: { title: "小程序标签同步管理" },
      },
      {
        path: "/yunMaConfiguration",
        component: YunMaConfiguration,
        meta: { title: "云码配置管理" },
      },
      {
        path: "/userMoneyUpdateLog",
        component: UserMoneyUpdateLog,
        meta: { title: "用户余额变更记录" },
      },
      {
        path: "/userWithdrawalRecordLog",
        component: UserWithdrawalRecordLog,
        meta: { title: "用户提现记录" },
      },
      {
        path: "/applet2Advertisement",
        component: Applet2Advertisement,
        meta: { title: "小程序2广告管理" },
      },
      {
        path: "/thirdPartyAppAd",
        component: ThirdPartyAppAd,
        meta: { title: "广告配置" },
      },
      {
        path: "/users",
        component: Users,
        meta: { title: "用户管理" },
      },
      {
        path: "/plants",
        component: Plants,
        meta: { title: "植物管理" },
      },
      {
        path: "/plantstype",
        component: PlantsType,
        meta: { title: "植物分类" },
      },
      {
        path: "/stage",
        component: Stage,
        meta: { title: "成长阶段管理" },
      },
      {
        path: "/growthrecords",
        component: GrowthRecords,
        meta: { title: "植物成长管理" },
      },
      {
        path: "/waterrecords",
        component: WaterRecords,
        meta: { title: "植物浇水管理" },
      },
      {
        path: "/fertilizing",
        component: Fertilizing,
        meta: { title: "植物施肥管理" },
      },
      {
        path: "/userenergy",
        component: Userenergy,
        meta: { title: "用户当前能量管理" },
      },
      {
        path: "/energy",
        component: Energy,
        meta: { title: "用户能量记录" },
      },
      {
        path: "/userconfig",
        component: Userconfigedit,
        meta: { title: "能量参数配置" },
      },
      {
        path: "/usersignins",
        component: Usersignins,
        meta: { title: "用户签到" },
      },
      {
        path: "/usershippingaddresses",
        component: Usershippingaddresses,
        meta: { title: "收货地址管理" },
      },
      {
        path: "/orders",
        component: Orders,
        meta: { title: "订单信息管理" },
      },
      {
        path: "/applet2Configuration",
        component: Applet2Configuration,
        meta: { title: "小程序2配置管理" },
      },
      {
        path: "/smallRoutine2",
        component: SmallRoutine2,
        meta: { title: "小程序2管理" },
      },
      {
        path: "/taobaoCommodityInterface",
        component: TaobaoCommodityInterface,
        meta: { title: "商品接口管理" },
      },
      {
        path: "/smallRoutine3",
        component: SmallRoutine3,
        meta: { title: "小程序3管理" },
      },
      {
        path: "/messageTemplate3",
        component: MessageTemplate3,
        meta: { title: "小程序3消息模板管理" },
      },
      {
        path: "/smallRoutine3ServiceRel",
        component: MessageTemplateService3Rel,
        meta: { title: "小程序3服务管理" },
      },
      {
        path: "/alipayUserAlipayPointSend",
        component: AlipayUserAlipayPointSend,
        meta: { title: "集分宝管理" },
      },
      {
        path: "/smallRoutine3LuckDraw",
        component: SmallRoutine3LuckDraw,
        meta: { title: "转盘奖励管理" },
      },
      {
        path: "/smallRoutine3BottomCommodityMenuOne",
        component: SmallRoutine3BottomCommodityMenuOne,
        meta: { title: "小程序3底部商品一级菜单管理" },
      },
      {
        path: "/smallRoutine3BottomCommodityMenuTwo",
        component: SmallRoutine3BottomCommodityMenuTwo,
        meta: { title: "小程序3底部商品二级菜单管理" },
      },
      {
        path: "/smallRoutine3BottomCommodity",
        component: SmallRoutine3BottomCommodity,
        meta: { title: "小程序3底部商品管理" },
      },
      {
        path: "/filmConfiguration",
        component: FilmConfiguration,
        meta: { title: "电影管理" },
      },
      {
        path: "/filmOrderList",
        component: FilmOrderList,
        meta: { title: "电影订单列表" },
      },
      {
        path: "/filmStatistics",
        component: FilmStatistics,
        meta: { title: "电影数据统计" },
      },
      {
        path: "/turntableReward",
        component: TurntableReward,
        meta: { title: "转盘奖励管理" },
      },
      {
        path: "/templateApplet",
        component: TemplateApplet,
        meta: { title: "小程序模板管理" },
      },
      {
        path: "/templateApplet1",
        component: TemplateApplet1,
        meta: { title: "小程序1模板管理" },
      },
      {
        path: "/shortLink",
        component: ShortLink,
        meta: { title: "短链管理" },
      },
      {
        path: "/shortLinkService",
        component: ShortLinkService,
        meta: { title: "短链后台路径管理" },
      },
      {
        path: "/thirdPartyApplications",
        component: ThirdPartyApplications,
        meta: { title: "第三方应用管理" },
      },
      {
        path: "/thirdPartyTemplate",
        component: ThirdPartyTemplate,
        meta: { title: "模板列表管理" },
      },
      {
        path: "/thirdPartySmallRoutine",
        component: ThirdPartySmallRoutine,
        meta: { title: "小程序列表管理" },
      },
      {
        path: "/merchantCoupon",
        component: MerchantCoupon,
        meta: { title: "支付宝商家券管理" },
      },
      {
        path: "/marketingPictures",
        component: MarketingPictures,
        meta: { title: "支付宝营销图片管理" },
      },
      {
        path: "/createSmallRoutine",
        component: CreateSmallRoutine,
        meta: { title: "商家小程序授权" },
      },
      {
        path: "/tikTokController",
        component: TikTokController,
        meta: { title: "抖音管理" },
      },
      {
        path: "/tikTokConfiguration",
        component: TikTokConfiguration,
        meta: { title: "配置管理" },
      },
      {
        path: "/h5CopyConfiguration",
        component: H5CopyConfiguration,
        meta: { title: "h5复制页面管理" },
      },
      {
        path: "/redEnvelopeForNewcomersDefault",
        component: RedEnvelopeForNewcomersDefault,
        meta: { title: "新人红包默认管理" },
      },
      {
        path: "/passwordRedEnvelopeDefault",
        component: PasswordRedEnvelopeDefault,
        meta: { title: "口令红包默认管理" },
      },
      {
        path: "/redEnvelopeForNewcomersList",
        component: RedEnvelopeForNewcomersList,
        meta: { title: "新人红包列表" },
      },
      {
        path: "/redEnvelopeForNewcomersUnifiedConfiguration",
        component: RedEnvelopeForNewcomersUnifiedConfiguration,
        meta: { title: "新人红包统一配置" },
      },
      {
        path: "/mask",
        component: Mask,
        meta: { title: "遮罩层管理" },
      },
      {
        path: "/massUpdate",
        component: MassUpdate,
        meta: { title: "小程序批量更新" },
      },
      {
        path: "/promotionalPoster",
        component: PromotionalPoster,
        meta: { title: "活动小程序海报图" },
      },
      {
        path: "/basicInformation",
        component: BasicInformation,
        meta: { title: "基础信息管理" },
      },
      {
        path: "/countPageView",
        component: CountPageView,
        meta: { title: "小程序访问统计" },
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

//挂载路由导航守卫(拦截器)
router.beforeEach((to, from, next) => {
  const token = window.sessionStorage.getItem("token"); //取出当前token
  //to 将要访问
  //from 从哪访问
  //next 接着干 nest(url) 重定向到url上 next()继续访问 to路径
  if (to.path == "/login") return next(); //方向内容
  if (!token) return next("/login"); //token为空时访问就返回登录页
  next(); //符合要求 放行
});

export default router;
