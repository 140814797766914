<template>
  <div>
    <el-form
      :model="updateForm"
      :rules="updateFormRules"
      ref="updateFormRed"
      label-width="auto"
      v-loading="showLoading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
        <!-- 选择用户 -->
      <el-form-item label="选择用户" prop="userId">
       <el-select v-model="updateForm.userId" placeholder="请选择用户">
         <el-option  v-for="item in userlist" :key="item.id" :label="item.username" :value="item.id"></el-option>
       </el-select>
      </el-form-item>
       <!-- 选择类型 -->
       <el-form-item label="选择类型" prop="sort">
       <el-select v-model="updateForm.type" placeholder="请选择类型">
         <el-option  v-for="item in typelist" :key="item.value" :label="item.title" :value="item.value"></el-option>
       </el-select>
      </el-form-item>
      <!-- 成长阶段 -->
      <el-form-item label="能量数值" prop="energy">
        <el-input
         type="number"
          v-model="updateForm.energy"
          placeholder="请输入能量数值"
          clearable
        ></el-input>
      </el-form-item>
    </el-form>
    <!-- 底部按钮 -->
    <div class="buttomButton">
      <el-button type="primary" @click="tosave">确 定</el-button>
    </div>
  </div>
</template>

<script>

export default {
  name: "Update",
  props: ["updateId", "refaushList"],
  data() {
    return {
      userlist:[],
      typelist:[
          {value:0,title:"浇水"},
          {value:1,title:"施肥"},
        ],
      // 编辑内容
      updateForm: {

      },
      // 表单验证
      updateFormRules: {
        userId: [
          { required: true, message: "请选择用户", trigger: "blur" },
        ]
      },
      headerObj: { Authorization: window.sessionStorage.getItem("token") },
      showLoading: false, // 加载中
    };
  },
  created() {
    this.selectuserlist();
    this.refaushForm(this.updateId);
  },
  methods: {
    async selectuserlist()
    {
        const { data: res } = await this.$http.post(
          "/users/getListAll",
          null
        );
        if ("200" != res.code) {
          return this.$message.error(res.message);
        }
        this.userlist=res.message;
    },
    // 点击提交
    tosave() {
      this.$refs["updateFormRed"].validate((valid) => {
        if (!valid) {
          return;
        }
        this.save();
      });
    },
    // 发起请求
    async save() {
        let fielddata={...this.updateForm};
        if(fielddata.status==true)
        {
          fielddata.status=1;
        }
        else
        {
          fielddata.status=0;
        }
        const { data: res } = await this.$http.post(
        "/userenergy/update",
        {
          ...fielddata,
        }
      );
      if ("200" != res.code) {
        return this.$message.error(res.message);
      }
      this.$message.success("操作成功");
      this.refaushList();
    },
    // 刷新编辑对象
    async refaushForm(e) {
      // 根据id获取数据
      const { data: res } = await this.$http.get(
        "/userenergy/getOne?id=" + e
      );
      if (res.code != 200) {
        return this.$message.error(res.message);
      }
      let message = res.message; 
      if(message.status==1) {
        message.status=true;
      }
      else
      {
        message.status=false;
      }
      this.updateForm = message;
    },
    isnull(e) {
      return e == null || e == undefined || e == "";
    },
    // input校验不能中文
    inputCheck(e) {
      if (/.*[\u4e00-\u9fa5]+.*$/.test(e)) {
        this.$message.error("小程序唯一码不能含有中文！");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.buttomButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
