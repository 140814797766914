<template>
  <div>
    <el-form
      :model="updateForm"
      :rules="updateFormRules"
      ref="updateFormRed"
      label-width="auto"
      v-loading="showLoading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
        <!-- 选择用户 -->
      <el-form-item label="选择用户" prop="userId">
       <el-select v-model="updateForm.userId" placeholder="请选择用户">
         <el-option  v-for="item in userlist" :key="item.id" :label="item.username" :value="item.id"></el-option>
       </el-select>
      </el-form-item>
       <!-- 家庭|公司 -->
       <el-form-item label="家里|公司" prop="addressName">
        <el-input
         type="text"
          v-model="updateForm.addressName"
          placeholder="请输入家里|公司"
          clearable
        ></el-input>
      </el-form-item>
        <!-- 联系姓名 -->
        <el-form-item label="联系姓名" prop="contactName">
        <el-input
         type="text"
          v-model="updateForm.contactName"
          placeholder="请输入联系姓名"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="手机号码" prop="contactPhone">
        <el-input
         type="text"
          v-model="updateForm.contactPhone"
          placeholder="请输入手机号码"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="收货地址" prop="addressDetail">
        <el-input
         type="text"
          v-model="updateForm.addressDetail"
          placeholder="请输入收货地址"
          clearable
        ></el-input>
      </el-form-item>
        <!-- 邮政编码 -->
        <el-form-item label="邮政编码" prop="postalCode">
        <el-input
         type="text"
          v-model="updateForm.postalCode"
          placeholder="请输入邮政编码"
          clearable
        ></el-input>
      </el-form-item>
      <!-- 是否默认 -->
      <el-form-item label="是否默认" prop="isDefault">
        <el-switch v-model="updateForm.isDefault"> </el-switch>
      </el-form-item>
      
        <!-- 创建时间 -->
        <!-- <el-form-item label="创建时间" prop="createdAt">
        <el-input
         type="date"
          v-model="updateForm.createdAt"
          value-format="yyyy-MM-dd"
          placeholder="请输入创建时间"
          clearable
        ></el-input>
      </el-form-item> -->
       <!-- 更新时间 -->
      <!-- <el-form-item label="更新时间" prop="updatedAt">
        <el-input
         type="date"
          v-model="updateForm.updatedAt"
          value-format="yyyy-MM-dd"
          placeholder="请输入更新时间"
          clearable
        ></el-input>
      </el-form-item> -->
    </el-form>
    <!-- 底部按钮 -->
    <div class="buttomButton">
      <el-button type="primary" @click="tosave">确 定</el-button>
    </div>
  </div>
</template>

<script>

export default {
  name: "Update",
  props: ["updateId", "refaushList"],
  data() {
    return {
      userlist:[],     
      // 编辑内容
      updateForm: {

      },
      // 表单验证
      updateFormRules: {
        userId: [
          { required: true, message: "请选择用户", trigger: "blur" },
        ],
        addressName:[
          { required: true, message: "家里|公司", trigger: "blur"}
        ],
        contactName:[
          { required: true, message: "联系姓名", trigger: "blur"}
        ],
        contactPhone:[
          { required: true, message: "手机号码", trigger: "blur"}
        ],
        addressDetail:[
          { required: true, message: "请填写收货地址", trigger: "blur"}
        ],
        signInDate:[
          { required: true, message: "请填写日期", trigger: "blur"}
        ],
        signInTime:[
          { required: true, message: "请填写时间", trigger: "blur"}
        ],
      },
      headerObj: { Authorization: window.sessionStorage.getItem("token") },
      showLoading: false, // 加载中
    };
  },
  created() {
    this.selectuserlist();
    this.refaushForm(this.updateId);
  },
  methods: {
    async selectuserlist()
    {
        const { data: res } = await this.$http.post(
          "/users/getListAll",
          null
        );
        if ("200" != res.code) {
          return this.$message.error(res.message);
        }
        this.userlist=res.message;
    },
    // 点击提交
    tosave() {
      this.$refs["updateFormRed"].validate((valid) => {
        if (!valid) {
          return;
        }
        this.save();
      });
    },
    // 发起请求
    async save() {
        let fielddata={...this.updateForm};
        if(fielddata.isDefault==true)
        {
          fielddata.isDefault=1;
        }
        else
        {
          fielddata.isDefault=0;
        }
        const { data: res } = await this.$http.post(
        "/usershippingaddresses/update",
        {
          ...fielddata,
        }
      );
      if ("200" != res.code) {
        return this.$message.error(res.message);
      }
      this.$message.success("操作成功");
      this.refaushList();
    },
    // 刷新编辑对象
    async refaushForm(e) {
      // 根据id获取数据
      const { data: res } = await this.$http.get(
        "/usershippingaddresses/getOne?id=" + e
      );
      if (res.code != 200) {
        return this.$message.error(res.message);
      }
      let message = res.message; 
      if(message.isDefault==1) {
        message.isDefault=true;
      }
      else
      {
        message.isDefault=false;
      }
      // var signInDate=this.formatDate(message.signInDate);
      // if(message.payTime!=undefined && message.payTime!="")
      // {
      //   message.payTime=this.formatDate(message.payTime);
      // }
      // console.log("日期"+signInDate);
      // message.signInDate=signInDate;
      this.updateForm = message;
    },
    formatDate(cellValue) {
      const date = new Date(cellValue);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      // 将月份和日期补齐两位数
      month = month < 10 ? '0' + month : month;
      day = day < 10 ? '0' + day : day;
      
      return year + '-' + month + '-' + day;
    },
    formatDatetime(cellValue) {
      const date = new Date(cellValue);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let hours = date.getHours();
      let minu=date.getMinutes();
      let sec=date.getSeconds();
      // 将月份和日期补齐两位数
      month = month < 10 ? '0' + month : month;
      day = day < 10 ? '0' + day : day;
      hours = hours < 10 ? '0' + hours :hours;
      minu = minu < 10 ? '0' + minu :minu;
      sec = sec < 10 ? '0' + sec :sec;

      return year + '-' + month + '-' + day+' '+hours+':'+minu+':'+sec;
    },
    isnull(e) {
      return e == null || e == undefined || e == "";
    },
    // input校验不能中文
    inputCheck(e) {
      if (/.*[\u4e00-\u9fa5]+.*$/.test(e)) {
        this.$message.error("小程序唯一码不能含有中文！");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.buttomButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
