// 接口请求地址
import axios from 'axios'

const path = 'thirdPartySmallRoutine/';
/**
 * 获取所有小程名称与id序列
 * @param {*} params
 */
export async function getAllSmallRoutineList() {
  const { data: res } = await axios.get(
    path+`getAllAppIdAndNameList`
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}

/**
 * 获取所有活动小程序列表
 * @param {*} params
 */
export async function listAllActivitySmallRoutine() {
  const { data: res } = await axios.get(
    path+`listAllActivitySmallRoutine`
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}