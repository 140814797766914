<template>
  <!-- 菜单列表 -->
  <div class="div_home">
    <el-card>
      <div class="div_home_title">
        <el-row :gutter="25">
          <!-- 搜索区域 -->
          <!-- 搜索添加 -->
          <div class="head_input">
            <el-button
              class="headButtonSingle"
              type="primary"
              @click="showList()"
              icon="el-icon-arrow-left"
            >
              返回
            </el-button>
          </div>
        </el-row>
      </div>
      <!-- 按钮模块 -->
      <div class="div_button div_modle">
        <!-- <div class="div_title">执行按钮</div> -->
        <div class="div_button_modle">
          <div class="div_button_modle_title">营销</div>

          <el-button
            class="buttonSingle"
            type="primary"
            icon="el-icon-chat-dot-square"
            @click="showMessageTemplate"
          >
            消息模板
          </el-button>
          <el-button
            class="buttonSingle"
            type="primary"
            icon="el-icon-s-check"
            @click="lifeRelationVisible = true"
          >
            关注生活号
          </el-button>
          <el-button
            v-if="
              showSingleMes.appletType == 1 ||
              showSingleMes.appletType == 2 ||
              showSingleMes.appletType == 6
            "
            class="buttonSingle"
            type="primary"
            icon="el-icon-guide"
            @click="showServiceRel()"
          >
            服务管理
          </el-button>
          <el-button
            v-if="
              showSingleMes.appletType == 3 ||
              showSingleMes.appletType == 4 ||
              showSingleMes.appletType == 5
            "
            class="buttonSingle"
            type="primary"
            icon="el-icon-guide"
            @click="showMessageGaoDeTemplate()"
          >
            按钮管理
          </el-button>
        </div>
      </div>
      <div class="div_button div_modle">
        <!-- <div class="div_title">执行按钮</div> -->
        <div class="div_button_modle">
          <div class="div_button_modle_title">操作</div>
          <el-button
            class="buttonSingle"
            type="primary"
            icon="el-icon-setting"
            @click="configurationVisible = true"
            >小程序配置管理</el-button
          >

          <el-button
            class="buttonSingle"
            type="primary"
            icon="el-icon-table-lamp"
            @click="dataModificationVisible = true"
          >
            小程序展示数据编辑
          </el-button>
          <!-- <el-button
            class="buttonSingle"
            type="primary"
            icon="el-icon-document-copy"
            @click="layoutCopyShowVisible = true"
          >
            复制小程序排版
          </el-button> -->
        </div>
      </div>
    </el-card>
    <el-dialog
      title="关联生活号"
      :visible.sync="lifeRelationVisible"
      width="50%"
    >
      <lifeRelation
        :visible="lifeRelationVisible"
        :updateId="showSingleMes.id"
        :lifeRelationClose="lifeRelationClose"
      />
    </el-dialog>
    <!-- 小程序配置 -->
    <el-dialog
      title="小程序配置"
      :visible.sync="configurationVisible"
      width="50%"
    >
      <smallroutineConfiguration
        :showSingleId="showSingleMes.id"
        :poiList="poiList"
      />
    </el-dialog>
    <!-- 小程序页面展示数据编辑 -->
    <el-dialog
      title="小程序页面展示数据编辑"
      :visible.sync="dataModificationVisible"
      width="70%"
    >
      <dataModification :showSingleId="showSingleMes.id" />
    </el-dialog>
    <!-- 复制布局 -->
    <el-dialog
      title="复制布局"
      :visible.sync="layoutCopyShowVisible"
      width="50%"
    >
      <layoutCopy :showSingleId="showSingleMes.id" />
    </el-dialog>
  </div>
</template>

<script>
import { getAllPoi } from "@/services/poiCode/poiCode.js";
import lifeRelation from "./childrenPage/lifeRelation.vue";
import smallroutineConfiguration from "./childrenPage/smallroutineConfiguration.vue";
import dataModification from "./childrenPage/dataModification.vue";
import layoutCopy from "./childrenPage/layoutCopy.vue";
export default {
  name: "messageApplet1",
  props: [
    "showList",
    "showSingleMes",
    "showServiceRel",
    "showMessageTemplate",
    "showMessageGaoDeTemplate",
  ],
  components: {
    lifeRelation,
    smallroutineConfiguration,
    dataModification,
    layoutCopy,
  },
  data() {
    return {
      // 关注生活号
      lifeRelationVisible: false,
      // 配置
      configurationVisible: false,
      // 内容关联
      dataModificationVisible: false,
      // 复制布局
      layoutCopyShowVisible: false,
      // poi列表
      poiList: [],
    };
  },
  watch: {
    showSingleMes(e) {
      this.init(e);
    },
  },
  created() {
    this.init(this.showSingleMes);
    this.getPoiList();
  },
  methods: {
    // 初始化
    async init() {},
    isNull(e) {
      return e == null || e == undefined || e == "";
    },
    // 关闭生活号弹窗
    lifeRelationClose() {
      this.lifeRelationVisible = false;
    },
    // 获取poi列表
    async getPoiList() {
      let res = await getAllPoi();
      if (!res.success) {
        return this.$message.error(res.err);
      }
      this.poiList = res.data;
    },
  },
};
</script>

<style lang="less" scoped>
.div_home {
  // height: 90%;
  position: relative;
  .commodityImg {
    width: 100px;
    height: 100px;
  }
  .head_input {
    display: flex;
    .div_input {
      display: flex;
      align-items: center;
      // flex: 1;
      // max-width: 350px;
      padding-right: 5px;
      .div_input_text {
        white-space: nowrap;
        padding-right: 5px;
      }
    }
  }
  .div_modle {
    padding-top: 20px;
    .div_button_modle {
      .div_button_modle_title {
        padding-right: 20px;
        font-weight: 700;
        font-size: 20px;
      }
    }
  }
  .div_title {
    width: 100%;
    // background-color: aquamarine;
    // text-align: center;
    font-size: 30px;
    color: #626263;
    padding-bottom: 10px;
  }
  // 点击的按钮
  .div_button {
    .buttonSingle {
      margin-top: 10px;
    }
  }
}
</style>
