<template>
  <div>
    <el-form
      :model="updateForm"
      :rules="updateFormRules"
      ref="updateFormRed"
      label-width="auto"
      v-loading="showLoading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
       <!-- 名称 -->
       <el-form-item label="阶段名称" prop="name">
        <el-input
          v-model="updateForm.name"
          placeholder="请输入阶段名称"
          maxlength="100"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
      <!-- 成长阶段 -->
      <el-form-item label="阶段值" prop="stage">
        <el-input
         type="number"
          v-model="updateForm.stage"
          placeholder="请输入阶段值"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="植物图片" prop="stageImg">
        <el-upload
            class="avatar-uploader"
            list-type="picture-card"
            :auto-upload="false"
            action="#"
            :show-file-list="false"
            :on-change="imgChange"
          >
            <img class="div_main_img" v-if="updateForm.stageImg" :src="updateForm.stageImg" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
      </el-form-item>
      <el-form-item label="植物变化图片" prop="stageImgchange">
        <el-upload
            class="avatar-uploader"
            list-type="picture-card"
            :auto-upload="false"
            action="#"
            :show-file-list="false"
            :on-change="imgChangetwo"
          >
            <img class="div_main_img" v-if="updateForm.stageImgchange" :src="updateForm.stageImgchange" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
      </el-form-item>
       <!-- 类型 -->
       <el-form-item label="类型" prop="sort">
       <el-select v-model="updateForm.plantsTypeId" placeholder="请选择">
         <el-option  v-for="item in typelist" :key="item.id" :label="item.name" :value="item.id"></el-option>
       </el-select>
      </el-form-item>
       <!-- 状态 -->
       <el-form-item label="状态" prop="status">
        <el-switch v-model="updateForm.status"> </el-switch>
      </el-form-item>
    </el-form>
    <!-- 底部按钮 -->
    <div class="buttomButton">
      <el-button type="primary" @click="tosave">确 定</el-button>
    </div>
  </div>
</template>

<script>

export default {
  name: "Update",
  props: ["updateId", "refaushList"],
  data() {
    return {
      file: {
        imgFile: null,
        imgFiletwo:null,
      },
      typelist:[],
      userlist:[],
      // 编辑内容
      updateForm: {

      },
      // 表单验证
      updateFormRules: {
        name: [
          { required: true, message: "请输入名称", trigger: "blur" },
          {
            min: 1,
            max: 255,
            message: "长度在 1 到 255 个字符",
            trigger: "blur",
          },
        ]
      },
      headerObj: { Authorization: window.sessionStorage.getItem("token") },
      showLoading: false, // 加载中
    };
  },
  created() {
    this.selectTypelist();
    this.selectUserlist();
    this.refaushForm(this.updateId);
  },
  methods: {
    async selectTypelist()
    {
        const { data: res } = await this.$http.post(
          "/plantstype/getListAll",
          null
        );
        if ("200" != res.code) {
          return this.$message.error(res.message);
        }
        this.typelist=res.message;
    },
    async selectUserlist()
    {
        const { data: res } = await this.$http.post(
          "/users/getListAll",
          null
        );
        if ("200" != res.code) {
          return this.$message.error(res.message);
        }
        //console.log(res);
        this.userlist=res.message;
    },
    // 点击提交
    tosave() {
      this.$refs["updateFormRed"].validate((valid) => {
        if (!valid) {
          return;
        }
        this.save();
      });
    },
    // 发起请求
    async save() {
        let fielddata={...this.updateForm};
        if(fielddata.status==true)
        {
          fielddata.status=1;
        }
        else
        {
          fielddata.status=0;
        }
        const { data: res } = await this.$http.post(
        "/stage/update",
        {
          ...fielddata,
        }
      );
      if ("200" != res.code) {
        return this.$message.error(res.message);
      }
      this.$message.success("操作成功");
      this.refaushList();
    },
    // 刷新编辑对象
    async refaushForm(e) {
      // 根据id获取数据
      const { data: res } = await this.$http.get(
        "/stage/getOne?id=" + e
      );
      if (res.code != 200) {
        return this.$message.error(res.message);
      }
      let message = res.message; 
      if(message.status==1) {
        message.status=true;
      }
      else
      {
        message.status=false;
      }
      this.updateForm = message;
    },
    isnull(e) {
      return e == null || e == undefined || e == "";
    },
    // input校验不能中文
    inputCheck(e) {
      if (/.*[\u4e00-\u9fa5]+.*$/.test(e)) {
        this.$message.error("小程序唯一码不能含有中文！");
      }
    },
    imgChange(e) {
      this.$set(this.addForm, "stageImg", e.url);
      this.$set(this.file, "imgFile", e.raw);
    },
    imgChangetwo(e) {
      this.$set(this.addForm, "stageImgchange", e.url);
      this.$set(this.file, "imgFiletwo", e.raw);
    },
  },
};
</script>

<style lang="less" scoped>
.buttomButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
.div_main_img {
  height: 100%;
  width: 100%;
}
</style>
