<template>
  <div class="div_home">
    <!-- 主体样式 -->
    <el-card>
      <div class="div_home_title">
        <el-row :gutter="25">
          <!-- 搜索区域 -->
          <el-col :span="1.5">
            <el-button
              icon="el-icon-arrow-left"
              type="primary"
              @click="showMenuList"
            >
              返回菜单列表
            </el-button>
          </el-col>
          <!-- 搜索添加 -->
          <el-col :span="10">
            <el-input
              placeholder="请输入搜索内容"
              v-model="queryInfo.query"
              clearable
              @clear="getNewList"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="getNewList"
              ></el-button>
            </el-input>
          </el-col>
          <!-- 搜索按钮 -->
          <el-col :span="4">
            <el-button type="primary" @click="addDialogVisible = true">
              添加模板
            </el-button>
          </el-col>
        </el-row>
      </div>
      <!-- 列表 border:边框  stripe隔行变色-->
      <el-table :data="tableList" border stripe>
        <el-table-column type="index"></el-table-column>
        <!-- 索引列 -->
        <el-table-column label="模板id" prop="templateId"></el-table-column>
        <el-table-column label="模板名称" prop="name"></el-table-column>
        <el-table-column label="模板规则" prop="type"></el-table-column>
        <el-table-column label="跳转页面" prop="page"></el-table-column>
        <el-table-column label="创建人" prop="createBy"></el-table-column>
        <el-table-column label="创建时间" prop="createTime"></el-table-column>
        <el-table-column label="状态" prop="status">
          <!-- 作用域插槽 -->
          <template slot-scope="scope">
            <!-- {{scope.row}}每一行封装的数据 -->
            <el-switch
              v-model="scope.row.status"
              @change="stateChange(scope.row)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <!-- 修改 -->
            <el-tooltip
              effect="dark"
              content="编辑信息"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="mini"
                @click="showEditDialog(scope.row)"
              ></el-button>
            </el-tooltip>
            <!-- 消息推送 -->
            <el-tooltip
              effect="dark"
              content="消息推送"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="warning"
                icon="el-icon-chat-dot-square"
                size="mini"
                @click="messageTemplateShow(scope.row)"
              ></el-button>
            </el-tooltip>
            <!-- 删除 -->
            <el-tooltip
              effect="dark"
              content="删除"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="deleteThis(scope.row.id)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- element分页组件 size-change每页最大变化数 current-change当前最大变化 layout功能组件-->
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pageNum"
          :page-sizes="[5, 10, 20, 100]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-card>
    <!-- 新增 -->
    <el-dialog
      title="添加"
      :visible.sync="addDialogVisible"
      width="50%"
      @close="addDialogClose"
    >
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addFormRed"
        label-width="70px"
      >
        <!-- 消息模板id -->
        <el-form-item label="消息模板id" prop="templateId">
          <el-input
            v-model="addForm.templateId"
            placeholder="消息模板id"
            maxlength="100"
            clearable
          ></el-input>
        </el-form-item>
        <!-- 模板名称 -->
        <el-form-item label="模板名称" prop="name">
          <el-input
            v-model="addForm.name"
            clearable
            maxlength="100"
            placeholder="消息模板名称"
          ></el-input>
        </el-form-item>
        <!-- 模板名称 -->
        <el-form-item label="跳转页面" prop="page">
          <el-input
            v-model="addForm.page"
            clearable
            maxlength="255"
            placeholder="请填写小程序跳转的页面,如:/pages/index/index"
          ></el-input>
        </el-form-item>
        <!-- 关键词 -->
        <el-form-item label="关键词" prop="keyList">
          <el-input
            type="textarea"
            :rows="2"
            v-model="addForm.keyList"
            clearable
            placeholder="请输入模板消息关键词，多个关键词用','隔开(注意：不要使用中文的逗号'，')"
          ></el-input>
        </el-form-item>
        <!-- 消息规则 -->
        <el-form-item label="消息规则" prop="type">
          <el-radio v-model="addForm.type" label="0">一次性</el-radio>
          <el-radio v-model="addForm.type" label="1">长期性</el-radio>
        </el-form-item>
        <!-- 状态 -->
        <!-- <el-form-item label="状态" prop="status">
          <el-switch v-model="addForm.status"> </el-switch>
        </el-form-item> -->
      </el-form>
      <!-- 底部按钮 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addMes">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑 -->
    <el-dialog title="编辑信息" :visible.sync="editDialogVisible" width="50%">
      <el-form
        :model="editForm"
        :rules="addFormRules"
        ref="editFormRed"
        label-width="70px"
      >
        <!-- 消息模板id -->
        <el-form-item label="消息模板id" prop="templateId">
          <el-input
            v-model="editForm.templateId"
            placeholder="消息模板id"
            maxlength="100"
            clearable
          ></el-input>
        </el-form-item>
        <!-- 模板名称 -->
        <el-form-item label="模板名称" prop="name">
          <el-input
            v-model="editForm.name"
            clearable
            maxlength="100"
            placeholder="消息模板名称"
          ></el-input>
        </el-form-item>
        <!-- 模板名称 -->
        <el-form-item label="跳转页面" prop="page">
          <el-input
            v-model="editForm.page"
            clearable
            maxlength="255"
            placeholder="请填写小程序跳转的页面,如:/pages/index/index"
          ></el-input>
        </el-form-item>
        <!-- 关键词 -->
        <el-form-item label="关键词" prop="keyList">
          <el-input
            type="textarea"
            :rows="2"
            v-model="editForm.keyList"
            clearable
            placeholder="请输入模板消息关键词，多个关键词用','隔开(注意：不要使用中文的逗号'，')"
          ></el-input>
        </el-form-item>
        <!-- 消息规则 -->
        <el-form-item label="消息规则" prop="type">
          <el-radio v-model="editForm.type" label="0">一次性</el-radio>
          <el-radio v-model="editForm.type" label="1">长期性</el-radio>
        </el-form-item>
        <!-- 状态 -->
        <!-- <el-form-item label="状态" prop="status">
          <el-switch v-model="editForm.status"> </el-switch>
        </el-form-item> -->
      </el-form>
      <!-- 底部按钮 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editMes">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 发送模板消息 -->
    <el-dialog
      title="发送模板消息"
      :visible.sync="messageTemplateVisible"
      width="50%"
      v-loading="loading"
      element-loading-text="发送模板消息中..."
    >
      <el-form
        :model="messageTemplateForm"
        :rules="messageTemplateRules"
        ref="editFormRed"
        label-width="70px"
      >
        <!-- 消息模板id -->
        <el-form-item
          :label="item.name"
          prop="templateId"
          v-for="(item, index) in messageTemplateList"
          :key="index"
        >
          <el-input v-model="item.mes" maxlength="100" clearable></el-input>
        </el-form-item>
      </el-form>
      <!-- 底部按钮 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="messageTemplateVisible = false">取 消</el-button>
        <el-button type="primary" @click="sendMessageTemplate">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 发送模板消息结果 -->
    <el-dialog
      title="模板消息发送结果"
      :visible.sync="messageTemplateResVisible"
      width="50%"
    >
      <div>成功数：{{ sendMessageTemplateRes.success }}</div>
      <div>失败数：{{ sendMessageTemplateRes.err }}</div>
      <el-table
        :data="sendMessageTemplateRes.errList"
        style="width: 100%"
        border
      >
        <el-table-column prop="userId" label="用户id"> </el-table-column>
        <el-table-column prop="mes" label="失败原因"> </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>
<script>
import {
  getMessageTemplateList,
  addMessageTemplate,
  updateStateMessageTemplate,
  delMessageTemplate,
  getUpdateMesById,
  updateMessageTemplate,
} from "@/services/thirdParty/smallRoutine/applet1/messageTemplate.js";
import { getMessageTemplateKeyList } from "@/services/thirdParty/smallRoutine/applet1/messageTemplateKey.js";
import { thirdPartySendTemplateMessage } from "@/services/alipay.js";
export default {
  props: ["showMenuList", "appId", "appletId"],
  created() {
    this.init();
  },
  data() {
    return {
      // 小程序页面传过来的参数(当前页及页码)
      smallRoutineQuery: {},
      // 来自哪个页面
      from: "",
      smallRoutineName: "",
      // 小程序页面展示数据当前code
      code: "",
      // 消息模板发送的模板id
      sendTemplateId: "",
      queryInfo: {
        //查询信息
        query: "",
        pageNum: 1, //当前页
        pageSize: 10, //每页最大数
      },
      tableList: [], // 表格列表
      total: 0, //总记录数
      addDialogVisible: false, //对话框显示状态
      //添加表单信息
      addForm: {
        templateId: "", // id
        name: "", //名称
        page: "/pages/index/index", //跳转页面
        keyList: "", //关键字
        type: "0", //类型
        // status: false, //状态
      },
      // 新增表单验证
      addFormRules: {
        templateId: [
          { required: true, message: "请输入消息模板id", trigger: "blur" },
          {
            min: 1,
            max: 100,
            message: "长度在 1 到 100 个字符",
            trigger: "blur",
          },
        ],
        name: [
          { required: true, message: "请输入消息模板名称", trigger: "blur" },
          {
            min: 1,
            max: 100,
            message: "长度在 1 到 100 个字符",
            trigger: "blur",
          },
        ],
        page: [
          { required: true, message: "请输入跳转页面", trigger: "blur" },
          {
            min: 1,
            max: 255,
            message: "长度在 1 到 255 个字符",
            trigger: "blur",
          },
        ],
      },
      //修改信息
      editForm: {},
      //显示/隐藏修改栏
      editDialogVisible: false,
      // 发送消息模板
      messageTemplateVisible: false,
      messageTemplateForm: {},
      messageTemplateRules: {},
      // 消息模板输入栏列表
      messageTemplateList: [],
      // 加载中
      loading: false,
      // 消息模板发送结果
      messageTemplateResVisible: false,
      sendMessageTemplateRes: {},
    };
  },
  methods: {
    init() {
      this.getTableList();
    },
    getNewList() {
      this.queryInfo.pageNum = 1;
      this.getTableList();
    },
    // 返回小程序列表
    returnSmallRoutine() {
      let from = this.from;
      this.$router.push({
        path: from,
        query: { queryInfo: this.smallRoutineQuery },
      });
    },
    // 获取表格列表
    async getTableList() {
      let that = this;
      let res = await getMessageTemplateList({
        ...this.queryInfo,
        appId: that.appId,
      });
      if (!res.success) {
        return this.$message.error(res.err);
      }
      this.tableList = res.data.records;
      this.total = res.data.total;
    },
    //最大数变更
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getTableList();
    },
    //当前页面变化触发
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage;
      this.getTableList();
    },
    // 修改状态
    async stateChange(e) {
      let res = await updateStateMessageTemplate({
        id: e.id,
        status: e.status,
      });
      if (!res.success) {
        e.status = !e.status;
        return this.$message.error(res.err);
      }
      this.$message.success("操作成功");
    },
    //监听添加
    addDialogClose() {
      this.$refs.addFormRed.resetFields();
    },
    // 添加
    async addMes() {
      let that = this;
      this.$refs.addFormRed.validate(async (valid) => {
        if (!valid) {
          return;
        }
        let res = await addMessageTemplate({
          appId: that.appId,
          ...this.addForm,
        });
        if (!res.success) {
          return this.$message.error(res.err);
        }
        this.$message.success("操作成功");
        // 隐藏添加弹出层
        this.addDialogVisible = false;
        // 刷新表格
        this.getTableList();
      });
    },
    // 删除信息
    async deleteThis(id) {
      const result = await this.$confirm("此操作将永久删除,是否继续?", "提示", {
        confirmButtonText: "确定",
        cancleButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (result != "confirm") {
        //取消删除
        return;
      }
      let res = await delMessageTemplate(id);
      if (!res.success) {
        return this.$message.error(res.err);
      }
      this.$message.success("删除成功");
      this.getTableList(); //刷新表格
    },
    //更改角色信息对话框
    async showEditDialog(e) {
      let res = await getUpdateMesById(e.id);
      if (!res.success) {
        return this.$message.error(res.err);
      }
      this.editForm = res.data;
      this.editDialogVisible = true; //显示对话框
    },
    //确认修改
    editMes() {
      this.$refs.editFormRed.validate(async (valid) => {
        if (!valid) return;
        //发起请求
        let res = await updateMessageTemplate(this.editForm);
        if (!res.success) {
          return this.$message.error(res.err);
        }
        this.$message.success("修改成功");
        // 隐藏添加弹出层
        this.editDialogVisible = false;
        //刷新表格
        this.getTableList();
      });
    },
    // 获取消息模板关键词列表
    async messageTemplateShow(e) {
      let templateTableId = e.id;
      let templateId = e.templateId;
      //发起请求
      let res = await getMessageTemplateKeyList(templateTableId);
      if (!res.success) {
        return this.$message.error(res.err);
      }
      this.messageTemplateList = res.data;
      this.messageTemplateVisible = true;
      this.sendTemplateId = templateId;
    },
    // 发送消息
    async sendMessageTemplate() {
      let templateId = this.sendTemplateId;
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      //发起请求
      let res = await thirdPartySendTemplateMessage({
        id: this.appletId,
        templateId: templateId,
        mesList: this.messageTemplateList,
        appId: this.appId,
      });
      this.$set(this, "messageTemplateVisible", false);
      loading.close();
      if (!res.success) {
        return this.$message.error(res.err);
      }
      this.$message.success("操作成功");
      // this.sendMessageTemplateRes = res.data;
      // this.messageTemplateVisible = false;
      // this.messageTemplateResVisible = true;
      // if (!res.success) {
      //   return this.$message.error(res.err);
      // }
    },
  },
};
</script>
<style lang="less" scoped>
.el-breadcrumb {
  margin-bottom: 15px;
  font-size: 17px;
}
.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

/deep/.el-form-item__label {
  width: 100px !important;
}
/deep/.el-form-item__content {
  margin-left: 100px !important;
}
</style>
