<template>
  <div>
    <el-form
      :model="addForm"
      :rules="addFormRules"
      ref="addFormRed"
      label-width="auto"
      v-loading="showLoading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
        <!-- 选择用户 -->
      <el-form-item label="选择用户" prop="userId" >
       <el-select v-model="addForm.userId" placeholder="请选择用户">
         <el-option  v-for="item in addForm.userlist" :key="item.id" :label="item.username" :value="item.id"></el-option>
       </el-select>
      </el-form-item>
      <!-- 产品名称 -->
      <el-form-item label="产品名称" prop="productName">
        <el-input
         type="text"
          v-model="addForm.productName"
          placeholder="请输入产品名称"
          clearable
        ></el-input>
      </el-form-item>
        <!-- 联系姓名 -->
        <el-form-item label="订单金额" prop="totalAmount">
        <el-input
         type="text"
          v-model="addForm.totalAmount"
          placeholder="请输入订单金额"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="支付方式" prop="paymentMethod">
       <el-select  v-model="addForm.paymentMethod"  placeholder="请选择支付方式">
        <el-option  v-for="item in addForm.paymentMethodList" :key="item.value" :label="item.title" :value="item.value"></el-option>
       </el-select>
      </el-form-item>
      <!-- 下单状态 -->
      <el-form-item label="状态" prop="orderStatus">
        <el-select v-model="addForm.orderStatus" placeholder="请选择状态">
         <el-option  v-for="item in addForm.statuslist" :key="item.id" :label="item.title" :value="item.id"></el-option>
       </el-select>
      </el-form-item>
        <!-- 收件地址 -->
        <el-form-item label="收件地址" prop="shippingAddressId">
        <el-select v-model="addForm.shippingAddressId" placeholder="请选择收件地址">
         <el-option  v-for="item in addForm.addresslist" :key="item.id" :label="item.contactName" :value="item.id"></el-option>
       </el-select>
      </el-form-item>
    </el-form>
    <!-- 底部按钮 -->
    <div class="buttomButton">
      <el-button type="primary" @click="toAdd">确 定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Add",
  props: ["closeThis", "refaushList"],
  data() {
    return {
      addForm: {
        userId: "",
        productName:"",
        totalAmount:"0",
        orderStatus:"",
        shippingAddressId:"",
        paymentMethod:"",
        userlist:[],
        addresslist:[],
        statuslist:[
          {id:0,title:"新订单"},
          {id:1,title:"待处理"},
          {id:2,title:"处理中"},
          {id:3,title:"已发货"},
          {id:4,title:"已送达"},
          {id:5,title:"已取消"},
          {id:6,title:"退回"}
        ],
        paymentMethodList:[
          {value:"alipay",title:"支付宝"},
          {value:"wechat",title:"微信"}
        ]
      },
      // 表单验证
      addFormRules: {
        userId: [
          { required: true, message: "请选择用户", trigger: "blur"}          
        ],
        productName:[
          { required: true, message: "请填写产品名称", trigger: "blur"}
        ],
        totalAmount:[
          { required: true, message: "请填写订单金额", trigger: "blur"}
        ],       
        paymentMethod:[
          { required: true, message: "请填选择支付方式", trigger: "blur"}
        ],
        orderStatus:[
          { required: true, message: "请填选择状态", trigger: "blur"}
        ],
        shippingAddressId:[
        { required: true, message: "请填选择收货地址", trigger: "blur"}
        ]
      },
      showLoading: false, // 加载中
    };
  },
  created(){
      this.selectuserlist();
      this.selectusershippingaddresslist();
  },
  methods: {
    async selectuserlist()
    {
        const { data: res } = await this.$http.post(
          "/users/getListAll",
          null
        );
        if ("200" != res.code) {
          return this.$message.error(res.message);
        }
        //console.log(res);
        this.addForm.userlist=res.message;
    },
    async selectusershippingaddresslist()
    {
        const { data: res } = await this.$http.post(
          "/usershippingaddresses/getList",
          {
            productName: "",
            sortBy:"",
            isAsc:false,
            pageNo: 1, //当前页
            pageSize: 20, //每页最大数
            smallCodeList: [], //查询的小程序唯一标识列表
          }
        );
        this.addForm.addresslist=res.list;
        console.log("addresslist:"+JSON.stringify(this.addForm.addresslist))
    },
    // 清空表单
    resetForm() {
      // 清空表单内容
      this.$refs["addFormRed"].resetFields();
    },
    // 点击提交
    toAdd() {
      this.$refs["addFormRed"].validate((valid) => {
        if (!valid) {
          return;
        }
        this.add();
      });
    },
    // 新增
    async add() {
      let fielddata={...this.addForm};
      const { data: res } = await this.$http.post(
        "/orders/add",
        {
          ...fielddata,
        }
      );
      if ("200" != res.code) {
        return this.$message.error(res.message);
      }
      this.resetForm();
      this.$message.success("操作成功");
      this.refaushList();
    },
    // input校验不能中文
    inputCheck(e) {
      if (/.*[\u4e00-\u9fa5]+.*$/.test(e)) {
        this.$message.error("小程序唯一码不能含有中文！");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.buttomButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
