<template>
  <div class="div_main">
    <div style="margin: 20px"></div>
    <el-form
      class="elForm"
      ref="formRef"
      :model="form"
      label-width="auto"
      :rules="formRules"
    >
      <el-form-item label="小程序名称" prop="name">
        <el-input
          v-model="form.name"
          maxlength="50"
          show-word-limit
          placeholder="请输入小程序名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="小程序appId" prop="appId">
        <el-input
          v-model="form.appId"
          maxlength="50"
          show-word-limit
          placeholder="请输入小程序appId"
        ></el-input>
      </el-form-item>
      <el-form-item label="授权token" prop="appAuthToken">
        <el-input
          v-model="form.appAuthToken"
          maxlength="100"
          show-word-limit
          placeholder="请输入授权token"
        ></el-input>
      </el-form-item>
      <el-form-item label="aes密钥" prop="aesKey">
        <el-input
          v-model="form.aesKey"
          maxlength="100"
          show-word-limit
          placeholder="请输入aes密钥"
        ></el-input>
      </el-form-item>
      <el-form-item label="商家Id" prop="userId">
        <el-input
          v-model="form.userId"
          maxlength="100"
          show-word-limit
          placeholder="请输入商家Id"
        ></el-input>
      </el-form-item>
      <el-form-item label="模板" prop="templateId">
        <el-select v-model="form.templateId" placeholder="请选择小程序模板">
          <el-option
            v-for="item in templateList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <!-- 备注 -->
      <el-form-item label="备注" prop="remark">
        <el-input
          v-model="form.remark"
          placeholder="请输入备注"
          clearable
          maxlength="150"
          show-word-limit
          type="textarea"
          :rows="2"
        ></el-input>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-switch v-model="form.status"></el-switch>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('formRef')">
          新建
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { smallroutineAdd } from "@/services/thirdParty/smallRoutine/add";
export default {
  props: ["refreshData", "templateList", "type"],
  name: "applet1Add",
  data() {
    return {
      form: {
        name: "",
        appId: "",
        appAuthToken: "",
        templateId: "",
        status: true,
      },
      // 表格提交验证
      formRules: {
        name: [
          { required: true, message: "请输入小程序名称", trigger: "blur" },
        ],
        appId: [{ required: true, message: "请输入appId", trigger: "blur" }],
        appAuthToken: [
          { required: true, message: "请输入授权token", trigger: "blur" },
        ],
        templateId: [
          { required: true, message: "请选择关联的模板", trigger: "change" },
        ],
      },
    };
  },
  created() {},
  methods: {
    // 表单提交
    submitForm(formName) {
      // 表单提交信息校验
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitFormMes();
        } else {
          return false;
        }
      });
    },
    async submitFormMes() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let res = await smallroutineAdd(this.form);
      loading.close();
      if (!res.success) {
        this.$message.error(res.err);
        return;
      }
      this.$message.success("操作成功");
      this.resetForm("formRef");
      // 刷新页面信息
      this.refreshData();
    },
    // 清空表单
    resetForm(formName) {
      // 清除form表单内容
      this.$refs[formName].resetFields();
    },
    isNull(e) {
      return e == null || e == undefined || e == "";
    },
  },
};
</script>

<style lang="less" scoped></style>
