<template>
  <div>
    <el-form
      :model="updateForm"
      :rules="updateFormRules"
      ref="updateFormRed"
      label-width="auto"
      v-loading="showLoading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
        <!-- 选择用户 -->
      <el-form-item label="选择用户" prop="userId">
       <el-select v-model="updateForm.userId" placeholder="请选择用户">
         <el-option  v-for="item in userlist" :key="item.id" :label="item.username" :value="item.id"></el-option>
       </el-select>
      </el-form-item>
       <!-- 签到日期 -->
       <el-form-item label="签到日期" prop="signInDate">
        <el-input
         type="date"
          v-model="updateForm.signInDate"
          placeholder="请输入签到日期"
          value-format="yyyy-MM-dd"
          clearable
        ></el-input>
      </el-form-item>
        <!-- 签到时间 -->
        <el-form-item label="签到时间" prop="signInTime">
        <el-input
         type="time"
          v-model="updateForm.signInTime"
          placeholder="请输入签到时间"
          clearable
        ></el-input>
      </el-form-item>
      <!-- 是否支付 -->
      <el-form-item label="是否支付" prop="ispay">
        <el-switch v-model="updateForm.ispay"> </el-switch>
      </el-form-item>
        <!-- 领取金额 -->
        <el-form-item label="领取金额" prop="price">
        <el-input
         type="text"
          v-model="updateForm.price"
          placeholder="请输入领取金额"
          clearable
        ></el-input>
      </el-form-item>
        <!-- 领取时间 -->
        <el-form-item label="领取时间" prop="payTime">
        <el-input
         type="date"
          v-model="updateForm.payTime"
          placeholder="请输入领取时间"
          value-format="yyyy-MM-dd"
          clearable
        ></el-input>
      </el-form-item>
    </el-form>
    <!-- 底部按钮 -->
    <div class="buttomButton">
      <el-button type="primary" @click="tosave">确 定</el-button>
    </div>
  </div>
</template>

<script>

export default {
  name: "Update",
  props: ["updateId", "refaushList"],
  data() {
    return {
      userlist:[],     
      // 编辑内容
      updateForm: {

      },
      // 表单验证
      updateFormRules: {
        userId: [
          { required: true, message: "请选择用户", trigger: "blur" },
        ],
        signInDate:[
          { required: true, message: "请填写日期", trigger: "blur"}
        ],
        signInTime:[
          { required: true, message: "请填写时间", trigger: "blur"}
        ],
      },
      headerObj: { Authorization: window.sessionStorage.getItem("token") },
      showLoading: false, // 加载中
    };
  },
  created() {
    this.selectuserlist();
    this.refaushForm(this.updateId);
  },
  methods: {
    async selectuserlist()
    {
        const { data: res } = await this.$http.post(
          "/users/getListAll",
          null
        );
        if ("200" != res.code) {
          return this.$message.error(res.message);
        }
        this.userlist=res.message;
    },
    // 点击提交
    tosave() {
      this.$refs["updateFormRed"].validate((valid) => {
        if (!valid) {
          return;
        }
        this.save();
      });
    },
    // 发起请求
    async save() {
        let fielddata={...this.updateForm};
        if(fielddata.ispay==true)
        {
          fielddata.ispay=1;
        }
        else
        {
          fielddata.ispay=0;
        }
        const { data: res } = await this.$http.post(
        "/usersignins/update",
        {
          ...fielddata,
        }
      );
      if ("200" != res.code) {
        return this.$message.error(res.message);
      }
      this.$message.success("操作成功");
      this.refaushList();
    },
    // 刷新编辑对象
    async refaushForm(e) {
      // 根据id获取数据
      const { data: res } = await this.$http.get(
        "/usersignins/getOne?id=" + e
      );
      if (res.code != 200) {
        return this.$message.error(res.message);
      }
      let message = res.message; 
      if(message.ispay==1) {
        message.ispay=true;
      }
      else
      {
        message.ispay=false;
      }
      var signInDate=this.formatDate(message.signInDate);
      if(message.payTime!=undefined && message.payTime!="")
      {
        message.payTime=this.formatDate(message.payTime);
      }
      console.log("日期"+signInDate);
      message.signInDate=signInDate;
      this.updateForm = message;
    },
    formatDate(cellValue) {
      const date = new Date(cellValue);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      // 将月份和日期补齐两位数
      month = month < 10 ? '0' + month : month;
      day = day < 10 ? '0' + day : day;
      
      return year + '-' + month + '-' + day;
    },
    formatDatetime(cellValue) {
      const date = new Date(cellValue);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let hours = date.getHours();
      let minu=date.getMinutes();
      let sec=date.getSeconds();
      // 将月份和日期补齐两位数
      month = month < 10 ? '0' + month : month;
      day = day < 10 ? '0' + day : day;
      hours = hours < 10 ? '0' + hours :hours;
      minu = minu < 10 ? '0' + minu :minu;
      sec = sec < 10 ? '0' + sec :sec;

      return year + '-' + month + '-' + day+' '+hours+':'+minu+':'+sec;
    },
    isnull(e) {
      return e == null || e == undefined || e == "";
    },
    // input校验不能中文
    inputCheck(e) {
      if (/.*[\u4e00-\u9fa5]+.*$/.test(e)) {
        this.$message.error("小程序唯一码不能含有中文！");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.buttomButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
