<template>
  <div>
    <el-form
      :model="addForm"
      :rules="addFormRules"
      ref="addFormRed"
      label-width="auto"
      v-loading="showLoading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
        <!-- 选择用户 -->
        <el-form-item label="选择用户" prop="sort">
       <el-select v-model="addForm.userId" placeholder="请选择用户">
         <el-option  v-for="item in addForm.userlist" :key="item.id" :label="item.username" :value="item.id"></el-option>
       </el-select>
      </el-form-item>
      <!-- 签到日期 -->
      <el-form-item label="签到日期" prop="signInDate">
        <el-input
         type="date"
          v-model="addForm.signInDate"
          value-format="yyyy-MM-dd"
          placeholder="请输入签到日期"
          clearable
        ></el-input>
      </el-form-item>
        <!-- 签到时间 -->
        <el-form-item label="签到时间" prop="signInTime">
        <el-input
         type="time"
          v-model="addForm.signInTime"
          placeholder="请输入签到时间"
          clearable
        ></el-input>
      </el-form-item>
      <!-- 是否支付 -->
      <el-form-item label="是否支付" prop="ispay">
        <el-switch v-model="addForm.ispay"> </el-switch>
      </el-form-item>
        <!-- 领取金额 -->
        <el-form-item label="领取金额" prop="price">
        <el-input
         type="text"
          v-model="addForm.price"
          placeholder="请输入领取金额"
          clearable
        ></el-input>
      </el-form-item>
        <!-- 领取时间 -->
        <el-form-item label="领取时间" prop="payTime">
        <el-input
         type="date"
          v-model="addForm.payTime"
          value-format="yyyy-MM-dd"
          placeholder="请输入领取时间"
          clearable
        ></el-input>
      </el-form-item>
    </el-form>
    <!-- 底部按钮 -->
    <div class="buttomButton">
      <el-button type="primary" @click="toAdd">确 定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Add",
  props: ["closeThis", "refaushList"],
  data() {
    return {
      addForm: {
        userId: "",
        signInDate: "",
        signInTime: "",
        ispay: false,
        price: '0.01',
        payTime:'',
        userlist:[],
      },
      // 表单验证
      addFormRules: {
        userId: [
          { required: true, message: "请选择用户", trigger: "blur" }          
        ],
        signInDate:[
          { required: true, message: "请填写日期", trigger: "blur"}
        ],
        signInTime:[
          { required: true, message: "请填写时间", trigger: "blur"}
        ],
      },
      showLoading: false, // 加载中
    };
  },
  created(){
      this.selectuserlist();
  },
  methods: {
    async selectuserlist()
    {
        const { data: res } = await this.$http.post(
          "/users/getListAll",
          null
        );
        if ("200" != res.code) {
          return this.$message.error(res.message);
        }
        //console.log(res);
        this.addForm.userlist=res.message;
    },
    // 清空表单
    resetForm() {
      // 清空表单内容
      this.$refs["addFormRed"].resetFields();
    },
    // 点击提交
    toAdd() {
      this.$refs["addFormRed"].validate((valid) => {
        if (!valid) {
          return;
        }
        this.add();
      });
    },
    // 新增
    async add() {
      let fielddata={...this.addForm};
      if(fielddata.ispay==true)
      {
        fielddata.ispay=1;
      }
      else
      {
        fielddata.ispay=0;
      }
      const { data: res } = await this.$http.post(
        "/usersignins/add",
        {
          ...fielddata,
        }
      );
      if ("200" != res.code) {
        return this.$message.error(res.message);
      }
      this.resetForm();
      this.$message.success("操作成功");
      this.refaushList();
    },
    // input校验不能中文
    inputCheck(e) {
      if (/.*[\u4e00-\u9fa5]+.*$/.test(e)) {
        this.$message.error("小程序唯一码不能含有中文！");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.buttomButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
