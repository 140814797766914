<template>
  <div>
    <el-form
      :model="addForm"
      :rules="addFormRules"
      ref="addFormRed"
      label-width="auto"
      v-loading="showLoading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
        <!-- 选择植物 -->
        <el-form-item label="选择植物" prop="sort">
       <el-select v-model="addForm.plantId" placeholder="请选择植物">
         <el-option  v-for="item in addForm.plantslist" :key="item.id" :label="item.name" :value="item.id"></el-option>
       </el-select>
      </el-form-item>
      <!-- 成长阶段 -->
      <el-form-item label="成长阶段值" prop="stage">
        <el-input
         type="number"
          v-model="addForm.growthStageChange"
          placeholder="请输入成长阶段值"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="comment">
        <el-input
          v-model="addForm.comment"
          placeholder="请输入备注"
          maxlength="255"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>    
    </el-form>
    <!-- 底部按钮 -->
    <div class="buttomButton">
      <el-button type="primary" @click="toAdd">确 定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Add",
  props: ["closeThis", "refaushList"],
  data() {
    return {
      addForm: {
        plantId: "",
        delId: 0,
        growthStageChange:1000,
        comment:"",
        plantslist:[],
      },
      // 表单验证
      addFormRules: {
        name: [
          { required: true, message: "请输入名称", trigger: "blur" },
          {
            min: 1,
            max: 255,
            message: "长度在 1 到 255 个字符",
            trigger: "blur",
          },
        ]
      },
      showLoading: false, // 加载中
    };
  },
  created(){
      this.selectplantslist();
  },
  methods: {
    async selectplantslist()
    {
        const { data: res } = await this.$http.post(
          "/plants/getListAll",
          null
        );
        if ("200" != res.code) {
          return this.$message.error(res.message);
        }
        //console.log(res);
        this.addForm.plantslist=res.message;
    },
    // 清空表单
    resetForm() {
      // 清空表单内容
      this.$refs["addFormRed"].resetFields();
    },
    // 点击提交
    toAdd() {
      this.$refs["addFormRed"].validate((valid) => {
        if (!valid) {
          return;
        }
        this.add();
      });
    },
    // 新增
    async add() {
      let fielddata={...this.addForm};
      if(fielddata.status==true)
      {
        fielddata.status=1;
      }
      else
      {
        fielddata.status=0;
      }
      const { data: res } = await this.$http.post(
        "/growthrecords/add",
        {
          ...fielddata,
        }
      );
      if ("200" != res.code) {
        return this.$message.error(res.message);
      }
      this.resetForm();
      this.$message.success("操作成功");
      this.refaushList();
    },
    // input校验不能中文
    inputCheck(e) {
      if (/.*[\u4e00-\u9fa5]+.*$/.test(e)) {
        this.$message.error("小程序唯一码不能含有中文！");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.buttomButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
