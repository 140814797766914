<template>
  <div class="div_home">
    <el-card>
      <div class="div_home_title">
        <el-row :gutter="25">
          <!-- 搜索区域 -->
          <!-- 搜索添加 -->
          <div class="head_input">
            <el-button
              class="headButtonSingle"
              type="primary"
              @click="toList()"
              icon="el-icon-arrow-left"
            >
              返回
            </el-button>
          </div>
        </el-row>
      </div>
      <!-- 输入模块 -->
      <div class="div_input div_modle">
        <div class="div_title">批量更新</div>
        <el-form :model="postParameter" ref="formMesRef" label-width="auto">
          <el-form-item label="模板" prop="templateId">
            <el-select
              filterable
              v-model="postParameter.templateId"
              placeholder="请选择更新的模板"
            >
              <el-option
                v-for="item in templateList"
                :key="item.id"
                :label="item.name + '(' + item.appId + ')'"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <el-button
              class="buttonSingle"
              type="primary"
              @click="submit('formMesRef')"
            >
              提交
            </el-button>
          </el-form-item>
          <el-form-item label="">
            <el-link type="primary" :underline="false">
              注：该更新仅更新后台列表展示的版本号与状态，不进行版本提交操作
            </el-link>
          </el-form-item>
          <el-divider content-position="left">最近一次操作结果</el-divider>
          <template v-if="laseMes.dateStart">
            <el-form-item label="修改数">
              {{ laseMes.sucCount }}
            </el-form-item>
            <el-form-item label="失败数">
              {{ laseMes.errCount }}
            </el-form-item>
            <el-form-item label="操作时间：">
              {{ laseMes.dateStart }}
            </el-form-item>
            <el-form-item label="完成时间">
              {{ laseMes.dateEnd }}
            </el-form-item>
            <el-form-item
              v-if="!$publicJs.isNull(laseMes.errMes)"
              label="异常中止"
            >
              {{ laseMes.errMes }}
            </el-form-item>
          </template>
          <el-form-item v-else label=""> 暂无记录 </el-form-item>
        </el-form>
      </div>
      <!-- 结果展示 -->
      <div class="div_result div_modle">
        <div class="div_title">执行结果</div>
        <div class="div_result_mes">
          <div class="div_result_mes_success" v-show="resultMes.success">
            {{ resultMes.mes }}
          </div>
          <div class="div_result_mes_err" v-show="resultMes.err">
            {{ resultMes.mes }}
          </div>
          <el-link
            v-show="!$publicJs.isNull(resultMes.href)"
            type="primary"
            @click="toCard()"
          >
            查看链接
          </el-link>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import {
  getMassUpdateVersionLaseMes,
  massUpdateVersion,
} from "@/services/massUpdate";
export default {
  name: "massUpdateVersion",
  props: ["templateList"],
  data() {
    return {
      postParameter: {},
      laseMes: {},
      resultMes: {
        success: false,
        err: false,
        // 结果内容
        mes: "",
        // 需要跳转的链接地址
        href: "",
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      let res = await this.$publicJs.request(this, getMassUpdateVersionLaseMes);
      if (!res.success) {
        return;
      }
      this.$set(this, "laseMes", res.data);
    },
    // 模板版本号更新
    templateVersionChange(e) {
      this.$set(this.postParameter, "appVersion", e);
    },
    toList() {
      this.$emit("showList");
    },
    // 提交
    submit(formName) {
      // 表单提交信息校验
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitFormMes();
        } else {
          return false;
        }
      });
    },
    async submitFormMes() {
      let res = await this.$publicJs.request(
        this,
        massUpdateVersion,
        this.postParameter,
        true
      );
      if (res.success) {
        this.$emit("setFlushTable", true);
        return this.setResultSuccessMes(
          "提交成功，稍后会自动更新表格（请勿频繁操作）"
        );
      } else {
        return this.setResultErrMes(res.err);
      }
    },
    setResultErrMes(e) {
      this.$set(this.resultMes, "success", false);
      this.$set(this.resultMes, "err", true);
      this.$set(this.resultMes, "mes", e);
    },
    setResultSuccessMes(e) {
      this.$set(this.resultMes, "success", true);
      this.$set(this.resultMes, "err", false);
      this.$set(this.resultMes, "mes", e);
    },
    initResultMes() {
      this.$set(this.resultMes, "success", false);
      this.$set(this.resultMes, "err", false);
      this.$set(this.resultMes, "mes", "");
      this.$set(this.resultMes, "href", "");
    },
    // 打开结果里的链接
    toCard() {
      let resultMes = this.resultMes;
      let href = resultMes.href;
      if (this.$publicJs.isNull(href)) {
        this.$message.warning("打开失败,跳转链接为空");
      }
      window.open(href);
    },
  },
};
</script>

<style lang="less" scoped>
.div_home {
  // height: 90%;
  position: relative;
  .commodityImg {
    width: 100px;
    height: 100px;
  }
  .head_input {
    display: flex;
    .div_input {
      display: flex;
      align-items: center;
      // flex: 1;
      // max-width: 350px;
      padding-right: 5px;
      .div_input_text {
        white-space: nowrap;
        padding-right: 5px;
      }
    }
  }
  .div_modle {
    padding-top: 20px;
    .div_button_modle {
      .div_button_modle_title {
        padding-right: 20px;
        font-weight: 700;
        font-size: 20px;
      }
    }
  }
  .div_title {
    width: 100%;
    // background-color: aquamarine;
    // text-align: center;
    font-size: 30px;
    color: #626263;
    padding-bottom: 10px;
  }
  // 点击的按钮
  .div_button {
    .buttonSingle {
      margin-top: 10px;
    }
  }
  // 输入模块
  .div_input {
    .div_input_single {
      width: 60%;
    }
  }

  // 结果展示
  .div_result {
    // position: absolute;
    // bottom: 20px;
    width: 90%;
    .div_result_mes {
      border: 2px solid #ccc;
      border-radius: 10px;
      width: 100%;
      height: 200px;
      padding: 10px;
      overflow: auto;
      font-size: 20px;
      .div_result_mes_success {
        color: green;
      }
      .div_result_mes_err {
        color: red;
      }
    }
  }
  // 文件上传
  .avatar-uploader-icon,
  .imgAppLogo {
    height: 100%;
    width: 100%;
  }
  .avatar-uploader-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed #d9d9d9;
    font-size: 25px;
  }
  .successText {
    color: green;
  }
  .errorText {
    color: red;
  }
}
</style>
