<template>
  <div class="div_main">
    <el-card>
      <el-divider content-position="left">遮罩层管理</el-divider>
      <el-form
        :model="formMes"
        :rules="rules"
        ref="updateFormRed"
        label-width="auto"
      >
        <el-form-item label="图片" prop="img">
          <el-upload
            class="avatar-uploader"
            list-type="picture-card"
            :auto-upload="false"
            action="#"
            :show-file-list="false"
            :on-change="uploadFileChange"
          >
            <img class="div_main_img" v-if="formMes.img" :src="formMes.img" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <!-- 展示时间（秒） -->
        <el-form-item label="展示时间（秒）" prop="displayTime">
          <el-input
            v-model="formMes.displayTime"
            placeholder="请输入展示时间（秒）,0表示不关闭"
            maxlength="10"
            show-word-limit
            @input="numberCheck3"
          ></el-input>
        </el-form-item>
        <!-- 跳转h5 -->
        <el-form-item label="跳转地址" prop="toUrl">
          <el-input
            type="textarea"
            v-model="formMes.toUrl"
            placeholder="请输入跳转路径,需要在路径前加上http或https"
            :rows="3"
            maxlength="250"
            show-word-limit
            clearable
          ></el-input>
        </el-form-item>
        <!-- 生活号 -->
        <el-form-item label="生活号" prop="toLifeNo">
          <el-input
            v-model="formMes.toLifeNo"
            placeholder="请输入关注生活号id"
            maxlength="100"
            show-word-limit
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <!-- 底部按钮 -->
      <div class="buttomButton">
        <el-button type="primary" @click="tosave">确 定</el-button>
      </div>
      <el-divider content-position="left">其他</el-divider>
      <el-button type="primary" @click="showBatchManagement">
        小程序批量修改
      </el-button>
    </el-card>
    <el-dialog title="小程序批量修改" :visible.sync="batchManagementVisible">
      <batchManagement />
    </el-dialog>
  </div>
</template>
<script>
import { getMes, updateNoFile, updateHasFile } from "@/services/mask.js";
import batchManagement from "./batchManagement/index.vue";
export default {
  name: "smallroutineConfiguration",
  components: {
    batchManagement,
  },
  props: ["showSingleId", "visible"],
  data() {
    return {
      formMes: {
        img: "",
        fileRaw: null,
        status: false,
      },
      batchManagementVisible: false,
      clientList: [
        { label: "h5", value: 1 },
        { label: "生活号", value: 2 },
      ],
      // 表单验证
      rules: {
        toType: [
          { required: true, message: "请选择关联事件", trigger: "blur" },
        ],
        toUrl: [
          { required: true, message: "请输入跳转的h5链接", trigger: "blur" },
        ],
      },
    };
  },
  watch: {
    visible(e) {
      if (e) {
        this.init();
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    showBatchManagement() {
      this.$set(this, "batchManagementVisible", true);
    },
    init() {
      this.getMes();
    },
    // 文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用
    uploadFileChange(e) {
      this.$set(this.formMes, "img", e.url);
      this.$set(this.formMes, "fileRaw", e.raw);
    },
    async getMes() {
      const res = await this.$publicJs.request(this, getMes);
      if (!res.success) {
        return this.$message.error(res.err);
      }
      this.$set(this, "formMes", res.data);
      this.$set(this.formMes, "img", res.data.img);
    },
    async tosave() {
      this.$refs.updateFormRed.validate(async (valid) => {
        if (!valid) return;
        const formMes = this.formMes;
        let fileRaw = formMes.fileRaw;
        let res;
        if (this.$publicJs.isNull(fileRaw)) {
          res = await this.$publicJs.request(this, updateNoFile, formMes);
        } else {
          const formData = new FormData();
          const formDatakeys = Object.keys(formMes);
          for (let i = 0; i < formDatakeys.length; i++) {
            const key = formDatakeys[i];
            formData.append(key, formMes[key]);
          }
          formData.append("file", fileRaw);
          res = await this.$publicJs.request(this, updateHasFile, formData);
        }
        if (!res.success) {
          return this.$message.error(res.err);
        }
        this.$message.success(res.data);
        this.$set(this.formMes, "fileRaw", null);
      });
    },
    numberCheck3() {
      let displayTime = this.$publicJs.numberCheck3(this.formMes.displayTime);
      console.log(displayTime);
      this.$set(this.formMes, "displayTime", displayTime);
    },
  },
};
</script>

<style lang="less" scoped>
.div_main {
  .div_main_img {
    height: 100%;
    width: 100%;
  }
}
</style>
