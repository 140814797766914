import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
// 添加全局样式
import './assets/css/global.css'
// iconfont
import './assets/css/iconfont.css'
// vuex
import store from './store'

import { httpUrl } from './config'

// 后台路径
// ———— 本地测试 ————
// var httpUrl = 'http://localhost:9000/'
// ———— 54服务器 ————
// var httpUrl = 'https://www.qmw-data.com/demo211213/'
// ———— 43服务器 ————
// var httpUrl = 'https://www.qmw-data.top/demo211213/'
// ———— 250服务器 ————
// var httpUrl = 'https://www.s10.top/test1/'


Vue.prototype.$httpUrl = httpUrl;

// loding最大等待时间
Vue.prototype.$lodingMaxTime = 10000;

// 公共js
import publicJs from "@/services/publicJs.js"

Vue.prototype.$publicJs = publicJs;

// excel
import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)

// 引入axios
import axios from 'axios'
Vue.prototype.$http = axios
Vue.prototype.$baseURL = httpUrl
axios.defaults.baseURL = httpUrl
// 请求头
axios.interceptors.request.use(config=>{
  config.headers.Authorization = window.sessionStorage.getItem('token')
  return config
})

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
  store
}).$mount('#app')


// 路由发生变化修改页面title
router.beforeEach((to, from, next) => {
  if(to.meta.title){
    document.title = to.meta.title
  }
  next()
})