<template>
  <div>
    <el-form
      :model="updateForm"
      :rules="updateFormRules"
      ref="updateFormRed"
      label-width="auto"
    >
      <el-form-item label="小程序名称" prop="name">
        <el-input
          v-model="updateForm.name"
          maxlength="50"
          show-word-limit
          placeholder="请输入小程序名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="小程序appId" prop="appId">
        <el-input
          v-model="updateForm.appId"
          maxlength="50"
          show-word-limit
          placeholder="请输入小程序appId"
        ></el-input>
      </el-form-item>
      <el-form-item label="授权token" prop="appAuthToken">
        <el-input
          v-model="updateForm.appAuthToken"
          maxlength="100"
          show-word-limit
          placeholder="请输入授权token"
        ></el-input>
      </el-form-item>
      <el-form-item label="aes密钥" prop="aesKey">
        <el-input
          v-model="updateForm.aesKey"
          maxlength="100"
          show-word-limit
          placeholder="请输入aes密钥"
        ></el-input>
      </el-form-item>
      <el-form-item label="商家Id" prop="userId">
        <el-input
          v-model="updateForm.userId"
          maxlength="100"
          show-word-limit
          placeholder="请输入商家Id"
        ></el-input>
      </el-form-item>
      <el-form-item label="模板" prop="templateId">
        <el-select
          v-model="updateForm.templateId"
          placeholder="请选择小程序模板"
        >
          <el-option
            v-for="item in templateList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <!-- 备注 -->
      <el-form-item label="备注" prop="remark">
        <el-input
          v-model="updateForm.remark"
          placeholder="请输入备注"
          clearable
          maxlength="150"
          show-word-limit
          type="textarea"
          :rows="2"
        ></el-input>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-switch v-model="updateForm.status"></el-switch>
      </el-form-item>
    </el-form>
    <!-- 底部按钮 -->
    <div class="buttomButton">
      <el-button type="primary" @click="tosave">确 定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "update",
  props: ["updateId", "refreshData", "visible", "templateList"],
  data() {
    return {
      // 编辑内容
      updateForm: {},
      // 表单验证
      updateFormRules: {
        name: [
          { required: true, message: "请输入小程序名称", trigger: "blur" },
        ],
        appId: [{ required: true, message: "请输入appId", trigger: "blur" }],
        appAuthToken: [
          { required: true, message: "请输入授权token", trigger: "blur" },
        ],
        templateId: [
          { required: true, message: "请选择关联的模板", trigger: "change" },
        ],
      },
    };
  },
  watch: {
    visible(e) {
      if (e) {
        this.refaushForm(this.updateId);
      }
    },
  },
  created() {
    this.refaushForm(this.updateId);
  },
  methods: {
    // 点击提交
    tosave() {
      this.$refs["updateFormRed"].validate((valid) => {
        if (!valid) {
          return;
        }
        this.save();
      });
    },
    // 发起请求
    async save() {
      const { data: res } = await this.$http.put(
        "/thirdPartySmallRoutine/update",
        {
          ...this.updateForm,
        }
      );
      if ("200" != res.code) {
        return this.$message.error(res.message);
      }
      this.$message.success("操作成功");
      this.refreshData();
    },
    // 刷新编辑对象
    async refaushForm(e) {
      // 根据id获取数据
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const { data: res } = await this.$http.get(
        "/thirdPartySmallRoutine/getUpdateMesById?id=" + e
      );
      loading.close();
      let message = res.message;
      if (res.code != 200) {
        return this.$message.error(message);
      }
      this.updateForm = message;
    },
    isnull(e) {
      return e == null || e == undefined || e == "";
    },
    // input校验不能中文
    inputCheck(e) {
      if (/.*[\u4e00-\u9fa5]+.*$/.test(e)) {
        this.$message.error("小程序唯一码不能含有中文！");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.buttomButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
