// 接口请求地址
import axios from 'axios'
const path = 'h5CopyConfiguration/';

/**
 * 获取信息
 */
export async function getMes() {
  const { data: res } = await axios.get(
    path + 'getMes'
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}

/**
 * 编辑
 */
export async function updateMes(data={}) {
  const { data: res } = await axios.put(
    path + 'updateMes',
    data
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}

