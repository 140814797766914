import { render, staticRenderFns } from "./userconfigedit.vue?vue&type=template&id=b60c8214&scoped=true&"
import script from "./userconfigedit.vue?vue&type=script&lang=js&"
export * from "./userconfigedit.vue?vue&type=script&lang=js&"
import style0 from "./userconfigedit.vue?vue&type=style&index=0&id=b60c8214&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b60c8214",
  null
  
)

export default component.exports