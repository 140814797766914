// 接口请求地址
import axios from "axios";
const path = "other/";

/**
 * 获取信息
 */
export async function getCountPageView() {
  const { data: res } = await axios.get(path + "getCountPageView");
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: res.message,
    };
  }
  return {
    success: true,
    data: message,
  };
}
