<template>
  <div class="div_home">
    <el-card>
      <div class="div_home_title">
        <el-row :gutter="25">
          <!-- 搜索区域 -->
          <!-- 搜索添加 -->
          <div class="head_input">
            <el-button
              class="headButtonSingle"
              type="primary"
              @click="toList()"
              icon="el-icon-arrow-left"
            >
              返回
            </el-button>
          </div>
        </el-row>
      </div>
      <!-- 输入模块 -->
      <div class="div_input div_modle">
        <div class="div_title">批量更新</div>
        <el-form :model="postParameter" ref="formMesRef" label-width="auto">
          <el-form-item label="模板" prop="templateId">
            <el-select
              filterable
              v-model="postParameter.templateId"
              placeholder="请选择更新的模板"
            >
              <el-option
                v-for="item in templateList"
                :key="item.id"
                :label="item.name + '(' + item.appId + ')'"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <template v-if="showFormMes">
            <el-form-item label="模板版本号" prop="templateVersion">
              <el-select
                filterable
                @change="templateVersionChange"
                v-model="postParameter.templateVersion"
                placeholder="请选择模板版本号"
              >
                <el-option
                  v-for="item in templateMes.appVersionInfos"
                  :key="item.app_version"
                  :label="
                    item.app_version + '(' + item.version_status_text + ')'
                  "
                  :value="item.app_version"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <!-- 商家小程序版本号(手动输入) -->
            <el-form-item label="商家小程序版本号" prop="appVersion">
              <el-input
                class="div_input_single"
                v-model="postParameter.appVersion"
                placeholder="请输入商家小程序版本号,例:0.0.1"
                maxlength="100"
                show-word-limit
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="小程序描述" prop="appDesc">
              <el-input
                type="textarea"
                class="div_input_single"
                v-model="postParameter.appDesc"
                placeholder="请输入版本描述"
                :rows="3"
                maxlength="200"
                show-word-limit
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="版本描述" prop="versionDesc">
              <el-input
                type="textarea"
                class="div_input_single"
                v-model="postParameter.versionDesc"
                placeholder="请输入版本描述"
                :rows="3"
                maxlength="500"
                show-word-limit
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="服务区域类型" prop="regionType">
              <el-select
                filterable
                v-model="postParameter.regionType"
                clearable
                placeholder="请选择服务区域类型"
              >
                <el-option
                  v-for="item in regionTypeList"
                  :key="item.label"
                  :label="item.label"
                  :value="item.value"
                  :disabled="item.disabled"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="营业执照有效期" prop="licenseValidDate">
              <el-date-picker
                v-model="postParameter.licenseValidDate"
                type="date"
                placeholder="选择营业执照有效期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="客服电话" prop="servicePhone">
              <el-input
                class="div_input_single"
                v-model="postParameter.servicePhone"
                placeholder="请输入客服电话"
                maxlength="30"
                show-word-limit
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="客服邮箱">
              <el-input
                class="div_input_single"
                v-model="postParameter.serviceEmail"
                placeholder="请输入客服邮箱"
                maxlength="128"
                show-word-limit
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="应用简介" prop="appSlogan">
              <el-input
                class="div_input_single"
                v-model="postParameter.appSlogan"
                placeholder="请输入小程序应用简介"
                maxlength="32"
                show-word-limit
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="logo图标" prop="appLogo">
              <el-upload
                class="avatar-uploader"
                list-type="picture-card"
                :auto-upload="false"
                action="#"
                :on-change="uploadFileChange"
                :show-file-list="false"
              >
                <img
                  class="imgAppLogo"
                  v-if="postParameter.appLogo"
                  :src="postParameter.appLogo"
                />
                <i v-else class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>
            <category
              ref="categoryRef"
              :code="categoryMes.code"
              :type="categoryMes.type"
              :chooseMes="categoryMes.chooseMes"
              :applicationsId="categoryMes.applicationsId"
            />
            <el-form-item label="">
              <el-button
                class="buttonSingle"
                type="primary"
                @click="submit('formMesRef')"
              >
                提交
              </el-button>
            </el-form-item>
          </template>
        </el-form>
      </div>
      <!-- 结果展示 -->
      <div class="div_result div_modle">
        <div class="div_title">执行结果</div>
        <div class="div_result_mes">
          <div class="div_result_mes_success" v-show="resultMes.success">
            {{ resultMes.mes }}
          </div>
          <div class="div_result_mes_err" v-show="resultMes.err">
            {{ resultMes.mes }}
          </div>
          <el-link
            v-show="!isNull(resultMes.href)"
            type="primary"
            @click="toCard()"
          >
            查看链接
          </el-link>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { massUpdate } from "@/services/massUpdate";
// import category from "../thirdParty/category/category.vue";
import category from "@/components/thirdParty/category/category.vue";
export default {
  name: "examine",
  props: ["templateList"],
  components: {
    category,
  },
  data() {
    return {
      headerObj: { Authorization: window.sessionStorage.getItem("token") },
      postParameter: {
        // ———— 必填 ————
        // 小程序类型
        smallRoutineType: "",
        // appId
        appId: "",
        // 小程序模板 APPID
        templateId: "",
        // 商家appId
        merchantAppId: "",
        // 商家授权token
        appAuthToken: "",
        // 小程序模板版本号。版本号必须满足 x.y.z, 且均为数字。不传默认使用最新在架（已上架）模板版本。
        templateVersion: "",
        // 商家小程序版本号。版本号必须满足 x.y.z, 且均为数字。要求版本号比商户之前最新的版本号高。
        appVersion: "",
        // ———— 非必填 ————
        // 自定义参数，用于配置商家小程序的个性化内容，具体使用方法参见示例代码。
        ext: "",
        // 小程序投放的端参数，默认支付宝端
        bundleId: "",
        // ———— 添加开发者或体验者接口必填 ————
        logonId: "",
        role: "",
        // ———— 提交审核 ————
        // 小程序应用名称,长度限制 3~20 个字符
        appName: "",
        // 小程序应用英文名称,长度限制 3~20 个字符
        appEnglishName: "",
        // 小程序版本描述，30-500个字符
        versionDesc: "",
        // 小程序服务区域类型
        regionType: "CHINA",
        // 营业执照有效期，格式为 yyyy-MM-dd，9999-12-31表示长期有效。
        licenseValidDate: "9999-12-31",
        // 小程序客服电话，长度限制5~30个字符
        servicePhone: "13110655990",
        // 小程序应用简介，一句话描述小程序功能，长度限制 10~32个字符。
        appSlogan: "",
        // 小程序logo图标
        appLogo: "",
        // 小程序应用描述，长度限制 20~200 个字。
        appDesc: "",
      },
      // 模板版本列表
      templateMes: {
        // 模板名称
        name: "",
        appVersionInfos: [],
        // 小程序名称
        smallRoutineName: "",
      },
      resultMes: {
        success: false,
        err: false,
        // 结果内容
        mes: "",
        // 需要跳转的链接地址
        href: "",
      },
      // 服务区域类型
      regionTypeList: [
        {
          label: "全球",
          value: "GLOBAL",
          disabled: true,
        },
        {
          label: "中国",
          value: "CHINA",
          disabled: false,
        },
        {
          label: "指定区域",
          value: "LOCATION",
          disabled: true,
        },
      ],
      // 类目
      categoryMes: {
        code: "",
        type: "",
        chooseMes: "",
        applicationsId: "",
      },
      // 缓存数据
      cache: {
        // id:默认填充内容
        examineDefaultMes: [],
        // id: 模板数据
        isvCode: [],
        // id:版本号信息
        templateVersion: [],
      },
      // 是否展示表单输入内容
      showFormMes: false,
    };
  },
  watch: {
    "postParameter.templateId"() {
      this.templateIdChange();
    },
  },
  methods: {
    // 模板版本号更新
    templateVersionChange(e) {
      this.$set(this.postParameter, "appVersion", e);
    },
    // 模板id变更
    async templateIdChange() {
      this.$set(this, "showFormMes", false);
      let id = this.postParameter.templateId;
      await this.getExamineDefaultMes(id);
      await this.initPostParameter(id);
      await this.getTemplateVersion(id);
      this.$set(this, "showFormMes", true);
    },

    // 根据模板id获取默认填充内容
    async getExamineDefaultMes(e) {
      let message = this.cache.examineDefaultMes[e];
      if (this.$publicJs.isNull(message)) {
        const { data: res } = await this.$http.get(
          "/thirdPartyTemplate/getExamineDefaultMes?id=" + e
        );
        message = res.message;
        if (res.code != "200") {
          return this.$message.error(
            "根据模板id获取默认填充内容失败:" + message
          );
        }
        this.$set(this.cache.examineDefaultMes, [e], message);
      }
      let appDesc = message.appDesc;
      let appSlogan = message.appSlogan;
      let versionDesc = message.versionDesc;
      if (this.$publicJs.isNull(appDesc)) {
        appDesc = "";
      }
      if (this.$publicJs.isNull(appSlogan)) {
        appSlogan = "";
      }
      if (this.$publicJs.isNull(versionDesc)) {
        versionDesc = "";
      }
      this.$set(this.postParameter, "appDesc", appDesc);
      this.$set(this.postParameter, "appSlogan", appSlogan);
      this.$set(this.postParameter, "versionDesc", versionDesc);
    },
    async initPostParameter(e) {
      let mes = this.cache.isvCode[e];
      if (this.$publicJs.isNull(mes)) {
        mes = await this.getIsvCodeByTemplateId(e);
        this.$set(this.cache.isvCode, [e], mes);
      }
      this.applicationsId = mes.applicationsId;
      this.$set(this.categoryMes, "applicationsId", mes.applicationsId);
      this.$set(this.categoryMes, "chooseMes", mes.category);
    },
    // 根据模板id获取模板数据
    async getIsvCodeByTemplateId(id) {
      const { data: res } = await this.$http.get(
        "/thirdPartyTemplate/getMesById?id=" + id
      );
      let message = res.message;
      if (res.code != "200") {
        return this.$message.error("版本列表查询失败:" + message);
      }
      return message;
    },
    toList() {
      this.$emit("showList");
    },
    // 获取模板版本号
    async getTemplateVersion(e) {
      let message = this.cache.templateVersion[e];
      if (this.$publicJs.isNull(message)) {
        const { data: res } = await this.$http.get(
          "/thirdPartyTemplate/listQuery?id=" + e
        );
        message = res.message;
        if (res.code != "200") {
          return this.$message.error("获取模板版本号失败:" + message);
        }
        this.$set(this.cache.templateVersion, [e], message);
      }
      this.templateMes.name = message.name;
      this.templateMes.appVersionInfos = message.appVersionInfos;
      if (message.versionList.length == 0) {
        this.$message.warning("当前模板还未上传版本，请先上传版本");
      }
    },
    // 提交
    submit(formName) {
      // 表单提交信息校验
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitFormMes();
        } else {
          return false;
        }
      });
    },
    async submitFormMes() {
      let postParameter = this.postParameter;
      let categoryRef = this.$refs.categoryRef;
      if (!this.isNull(categoryRef)) {
        let category = categoryRef.getChooseCategoryMes();
        postParameter.miniCategoryIds = category;
      }
      const formData = new FormData();
      const formDataKeys = Object.keys(postParameter);
      for (let i = 0; i < formDataKeys.length; i++) {
        const key = formDataKeys[i];
        formData.append(key, postParameter[key]);
      }
      let res = await this.$publicJs.request(this, massUpdate, formData, true);
      if (res.success) {
        this.$emit("setFlushTable", true);
        return this.setResultSuccessMes(
          "提交成功，请稍后到历史记录处查看结果（请勿频繁操作）"
        );
      } else {
        return this.setResultErrMes(res.err);
      }
    },
    isNull(e) {
      return e == null || e == undefined || e == "";
    },
    setResultErrMes(e) {
      this.$set(this.resultMes, "success", false);
      this.$set(this.resultMes, "err", true);
      this.$set(this.resultMes, "mes", e);
    },
    setResultSuccessMes(e) {
      this.$set(this.resultMes, "success", true);
      this.$set(this.resultMes, "err", false);
      this.$set(this.resultMes, "mes", e);
    },
    initResultMes() {
      this.$set(this.resultMes, "success", false);
      this.$set(this.resultMes, "err", false);
      this.$set(this.resultMes, "mes", "");
      this.$set(this.resultMes, "href", "");
    },
    // 打开结果里的链接
    toCard() {
      let resultMes = this.resultMes;
      let href = resultMes.href;
      if (this.isNull(href)) {
        this.$message.warning("打开失败,跳转链接为空");
      }
      window.open(href);
    },
    // 文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用
    uploadFileChange(file) {
      this.$set(this.postParameter, "fileAppLogo", file.raw);
      this.$set(this.postParameter, "appLogo", URL.createObjectURL(file.raw));
    },
  },
};
</script>

<style lang="less" scoped>
.div_home {
  // height: 90%;
  position: relative;
  .commodityImg {
    width: 100px;
    height: 100px;
  }
  .head_input {
    display: flex;
    .div_input {
      display: flex;
      align-items: center;
      // flex: 1;
      // max-width: 350px;
      padding-right: 5px;
      .div_input_text {
        white-space: nowrap;
        padding-right: 5px;
      }
    }
  }
  .div_modle {
    padding-top: 20px;
    .div_button_modle {
      .div_button_modle_title {
        padding-right: 20px;
        font-weight: 700;
        font-size: 20px;
      }
    }
  }
  .div_title {
    width: 100%;
    // background-color: aquamarine;
    // text-align: center;
    font-size: 30px;
    color: #626263;
    padding-bottom: 10px;
  }
  // 点击的按钮
  .div_button {
    .buttonSingle {
      margin-top: 10px;
    }
  }
  // 输入模块
  .div_input {
    .div_input_single {
      width: 60%;
    }
  }

  // 结果展示
  .div_result {
    // position: absolute;
    // bottom: 20px;
    width: 90%;
    .div_result_mes {
      border: 2px solid #ccc;
      border-radius: 10px;
      width: 100%;
      height: 200px;
      padding: 10px;
      overflow: auto;
      font-size: 20px;
      .div_result_mes_success {
        color: green;
      }
      .div_result_mes_err {
        color: red;
      }
    }
  }
  // 文件上传
  .avatar-uploader-icon,
  .imgAppLogo {
    height: 100%;
    width: 100%;
  }
  .avatar-uploader-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed #d9d9d9;
    font-size: 25px;
  }
  .successText {
    color: green;
  }
  .errorText {
    color: red;
  }
}
</style>
