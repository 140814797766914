<template>
  <div class="div_home">
    <el-card>
      <el-form
        :model="formMes"
        ref="formRed"
        label-width="auto"
        :rules="formRules"
      >
        <el-divider content-position="left">h5复制页面管理</el-divider>
        <el-form-item label="按钮名称" prop="buttonText">
          <el-input
            v-model="formMes.buttonText"
            placeholder="请输入按钮名称"
            clearable
            maxlength="10"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="复制成功提示" prop="successTitle">
          <el-input
            v-model="formMes.successTitle"
            placeholder="请输入复制成功提示"
            clearable
            maxlength="100"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="复制内容" prop="copyMes">
          <el-input
            type="textarea"
            v-model="formMes.copyMes"
            placeholder="请输入用户复制内容"
            :rows="3"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="save">保 存</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { getMes, updateMes } from "@/services/other/h5CopyConfiguration.js";
export default {
  name: "h5CopyConfiguration",
  data() {
    return {
      formMes: {},
      formRules: {},
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const res = await getMes();
      loading.close();
      if (!res.success) {
        this.$message.error(res.err);
      }
      this.$set(this, "formMes", res.data);
    },
    // 修改
    async save() {
      this.$refs.formRed.validate(async (valid) => {
        if (!valid) return;
        //发起请求
        const loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        const res = await updateMes(this.formMes);
        loading.close();
        if (!res.success) {
          return this.$message.error(res.err);
        }
        this.$message.success("修改成功");
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
