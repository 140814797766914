<template>
  <div class="div_home">
    <!-- 主体样式 -->
    <el-card>
      <!-- 列表 border:边框  stripe隔行变色-->
      <el-table
        :data="tableList"
        border
        stripe
        row-key="id"
        :tree-props="{ children: 'list', hasChildren: true }"
      >
        <el-table-column
          label="时间"
          prop="date"
          align="center"
        ></el-table-column>
        <el-table-column
          label="appId"
          prop="appId"
          align="center"
        ></el-table-column>
        <el-table-column
          label="总访问数"
          prop="count"
          align="center"
        ></el-table-column>
      </el-table>
    </el-card>
  </div>
</template>
<script>
import { getCountPageView } from "@/services/other/other.js";
export default {
  data() {
    return {
      tableList: [], // 表格列表
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getList();
    },
    // 获取表格列表
    async getList() {
      const res = await this.$publicJs.request(this, getCountPageView);
      if (!res.success) {
        return;
      }
      console.log(res);
      this.tableList = res.data;
    },
  },
};
</script>
<style lang="less" scoped>
.div_home_title {
  display: flex;
  align-items: center;
  .div_input_text {
    white-space: nowrap;
  }
  .div_input {
    margin-right: 10px;
    width: 250px;
  }
}
.el-breadcrumb {
  margin-bottom: 15px;
  font-size: 17px;
}
.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}
/deep/.el-form-item__label {
  width: 100px !important;
}
/deep/.el-form-item__content {
  margin-left: 100px !important;
}
</style>
