// 接口请求地址
const path = 'advertisementInformation/';
import axios from 'axios'

/**
 * 修改每日任务状态
 */
 export async function updateDailyTask(data={}) {
    const {data: res} = await axios.put(
        path + 'updateDailyTask',data
    );
    let message = res.message;
    if (res.code != 200) {
        return {
            success: false,
            err: res.message
        };
    }
    return {
        success: true,
        data: message,
    };
}

