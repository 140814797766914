<template>
  <div class="div_home">
    <el-card>
      <el-form
        class="viewForm"
        :model="formMes"
        :rules="formMesRules"
        ref="formRed"
        label-width="auto"
      >
        <el-divider content-position="left">客服信息弹窗</el-divider>
        <!-- key -->
        <el-form-item label="标题" prop="title">
          <el-input
            v-model="formMes.title"
            placeholder="请输入标题"
            maxlength="10"
            show-word-limit
            clearable
          ></el-input>
        </el-form-item>
        <!-- secret -->
        <el-form-item label="客服电话" prop="phone">
          <el-input
            v-model="formMes.phone"
            placeholder="请输入客服电话"
            maxlength="11"
            show-word-limit
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="save">保 存</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import {
  getMes,
  updateMes,
} from "@/services/redEnvelopeForNewcomers/unifiedConfiguration.js";
export default {
  data() {
    return {
      formMes: {
        title: "",
        phone: "",
      },
      formMesRules: {},
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      let res = await this.$publicJs.request(this, getMes, null);
      if (!res.success) {
        return;
      }
      this.$set(this, "formMes", res.data);
    },
    save() {
      this.$refs.formRed.validate(async (valid) => {
        if (!valid) return;
        let res = await this.$publicJs.request(
          this,
          updateMes,
          this.formMes,
          true
        );
        if (!res.success) {
          this.$message.error("修改失败");
        }
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
