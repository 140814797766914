<template>
  <div class="div_home">
    <el-card>
      <div class="div_home_title">
        <el-row :gutter="25">
          <!-- 搜索区域 -->
          <!-- 搜索添加 -->
          <div class="head_input">
            <el-button
              class="headButtonSingle"
              type="primary"
              @click="toList()"
              icon="el-icon-arrow-left"
            >
              返回
            </el-button>
          </div>
        </el-row>
      </div>
      <!-- 输入模块 -->
      <div class="div_input div_modle">
        <div class="div_title">结果查询</div>
        <el-form :model="postParameter" ref="formMesRef" label-width="auto">
          <el-form-item label="模板" prop="templateId">
            <el-select
              filterable
              v-model="postParameter.templateId"
              placeholder="请选择更新的模板"
            >
              <el-option
                v-for="item in templateList"
                :key="item.id"
                :label="item.name + '(' + item.appId + ')'"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <template v-if="showFormMes">
            <el-form-item label="模板版本号" prop="templateVersion">
              <el-select
                filterable
                v-model="postParameter.templateVersion"
                placeholder="请选择模板版本号"
              >
                <el-option
                  v-for="item in templateMes.appVersionInfos"
                  :key="item.app_version"
                  :label="
                    item.app_version + '(' + item.version_status_text + ')'
                  "
                  :value="item.app_version"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <!-- 商家小程序版本号(手动输入) -->
            <el-form-item label="商家小程序版本号" prop="appVersion">
              <el-input
                class="div_input_single"
                v-model="postParameter.appVersion"
                placeholder="请输入商家小程序版本号,例:0.0.1"
                maxlength="100"
                show-word-limit
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="小程序描述" prop="appDesc">
              <el-input
                type="textarea"
                class="div_input_single"
                v-model="postParameter.appDesc"
                placeholder="请输入版本描述"
                :rows="3"
                maxlength="200"
                show-word-limit
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="版本描述" prop="versionDesc">
              <el-input
                type="textarea"
                class="div_input_single"
                v-model="postParameter.versionDesc"
                placeholder="请输入版本描述"
                :rows="3"
                maxlength="500"
                show-word-limit
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="服务区域类型" prop="regionType">
              <el-select
                filterable
                v-model="postParameter.regionType"
                clearable
                placeholder="请选择服务区域类型"
              >
                <el-option
                  v-for="item in regionTypeList"
                  :key="item.label"
                  :label="item.label"
                  :value="item.value"
                  :disabled="item.disabled"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="营业执照有效期" prop="licenseValidDate">
              <el-date-picker
                v-model="postParameter.licenseValidDate"
                type="date"
                placeholder="选择营业执照有效期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="客服电话" prop="servicePhone">
              <el-input
                class="div_input_single"
                v-model="postParameter.servicePhone"
                placeholder="请输入客服电话"
                maxlength="30"
                show-word-limit
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="客服邮箱">
              <el-input
                class="div_input_single"
                v-model="postParameter.serviceEmail"
                placeholder="请输入客服邮箱"
                maxlength="128"
                show-word-limit
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="应用简介" prop="appSlogan">
              <el-input
                class="div_input_single"
                v-model="postParameter.appSlogan"
                placeholder="请输入小程序应用简介"
                maxlength="32"
                show-word-limit
                clearable
              ></el-input>
            </el-form-item>
            <category
              ref="categoryRef"
              :chooseMes="postParameter.miniCategoryIds"
              :applicationsId="postParameter.applicationsId"
            />
          </template>
        </el-form>
      </div>
      <!-- 结果展示 -->
      <div class="div_result div_modle">
        <div class="div_title">执行结果</div>
        <div class="div_result_mes">
          <div class="div_result_mes_success" v-show="resultMes.success">
            {{ resultMes.mes }}
          </div>
          <div class="div_result_mes_err" v-show="resultMes.err">
            {{ resultMes.mes }}
          </div>
          <el-link
            v-show="!isNull(resultMes.href)"
            type="primary"
            @click="toCard()"
          >
            查看链接
          </el-link>
        </div>
      </div>
      <div class="div_result div_modle">
        <div class="div_title">列表详情</div>

        <el-table :data="postParameter.list" border stripe>
          <el-table-column type="index" width="80" fixed></el-table-column>
          <el-table-column
            label="appId"
            prop="appid"
            width="200"
            fixed
          ></el-table-column>
          <el-table-column label="小程序名称" prop="name"></el-table-column>
          <el-table-column label="创建版本号是否成功" prop="uploadSuccess">
            <template slot-scope="scope">
              <div v-if="scope.row.uploadSuccess" class="successText">成功</div>
              <div v-else class="errorText">失败</div>
            </template>
          </el-table-column>
          <el-table-column
            label="创建版本号失败原因"
            prop="uploadErrMes"
          ></el-table-column>
          <el-table-column label="提交审核是否成功" prop="auditApplySuccess">
            <template slot-scope="scope">
              <div v-if="scope.row.auditApplySuccess" class="successText">
                成功
              </div>
              <div v-else class="errorText">失败</div>
            </template></el-table-column
          >
          <el-table-column
            label="提交审核失败原因"
            prop="auditApplyErrMes"
          ></el-table-column>
        </el-table>
      </div>
    </el-card>
  </div>
</template>

<script>
import { getDetailById } from "@/services/massUpdate";
// import category from "../thirdParty/category/category.vue";
import category from "@/components/thirdParty/category/category.vue";
export default {
  name: "examine",
  props: ["templateList", "id"],
  components: {
    category,
  },
  data() {
    return {
      postParameter: {},
      // 模板版本列表
      templateMes: {
        // 模板名称
        name: "",
        appVersionInfos: [],
        // 小程序名称
        smallRoutineName: "",
      },
      resultMes: {
        success: false,
        err: false,
        // 结果内容
        mes: "",
        // 需要跳转的链接地址
        href: "",
      },
      // 服务区域类型
      regionTypeList: [
        {
          label: "全球",
          value: "GLOBAL",
          disabled: true,
        },
        {
          label: "中国",
          value: "CHINA",
          disabled: false,
        },
        {
          label: "指定区域",
          value: "LOCATION",
          disabled: true,
        },
      ],
      // 类目
      categoryMes: {
        code: "",
        type: "",
        chooseMes: "",
        applicationsId: "",
      },
      // 缓存数据
      cache: {
        // id:默认填充内容
        examineDefaultMes: [],
        // id: 模板数据
        isvCode: [],
        // id:版本号信息
        templateVersion: [],
      },
      showFormMes: false,
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      const res = await this.$publicJs.request(this, getDetailById, this.id);
      if (!res.success) {
        let that = this;
        setTimeout(function () {
          that.toList();
        }, 1000);
        return;
      }
      let data = res.data;
      this.$set(this, "postParameter", data);
      this.$set(this, "showFormMes", true);
      let resText =
        "成功数：" + data.success + "；失败数：" + data.error + "；";
      this.setResultSuccessMes(resText);
    },
    toList() {
      this.$emit("showList");
    },
    isNull(e) {
      return e == null || e == undefined || e == "";
    },
    setResultSuccessMes(e) {
      this.$set(this.resultMes, "success", true);
      this.$set(this.resultMes, "err", false);
      this.$set(this.resultMes, "mes", e);
    },
    // 打开结果里的链接
    toCard() {
      let resultMes = this.resultMes;
      let href = resultMes.href;
      if (this.isNull(href)) {
        this.$message.warning("打开失败,跳转链接为空");
      }
      window.open(href);
    },
    // 文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用
    uploadFileChange(file) {
      this.$set(this.postParameter, "fileAppLogo", file.raw);
      this.$set(this.postParameter, "appLogo", URL.createObjectURL(file.raw));
    },
  },
};
</script>

<style lang="less" scoped>
.div_home {
  // height: 90%;
  position: relative;
  .commodityImg {
    width: 100px;
    height: 100px;
  }
  .head_input {
    display: flex;
    .div_input {
      display: flex;
      align-items: center;
      // flex: 1;
      // max-width: 350px;
      padding-right: 5px;
      .div_input_text {
        white-space: nowrap;
        padding-right: 5px;
      }
    }
  }
  .div_modle {
    padding-top: 20px;
    .div_button_modle {
      .div_button_modle_title {
        padding-right: 20px;
        font-weight: 700;
        font-size: 20px;
      }
    }
  }
  .div_title {
    width: 100%;
    // background-color: aquamarine;
    // text-align: center;
    font-size: 30px;
    color: #626263;
    padding-bottom: 10px;
  }
  // 点击的按钮
  .div_button {
    .buttonSingle {
      margin-top: 10px;
    }
  }
  // 输入模块
  .div_input {
    .div_input_single {
      width: 60%;
    }
  }

  // 结果展示
  .div_result {
    // position: absolute;
    // bottom: 20px;
    width: 90%;
    .div_result_mes {
      border: 2px solid #ccc;
      border-radius: 10px;
      width: 100%;
      height: 200px;
      padding: 10px;
      overflow: auto;
      font-size: 20px;
      .div_result_mes_success {
        color: green;
      }
      .div_result_mes_err {
        color: red;
      }
    }
  }
  // 文件上传
  .avatar-uploader-icon,
  .imgAppLogo {
    height: 100%;
    width: 100%;
  }
  .avatar-uploader-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed #d9d9d9;
    font-size: 25px;
  }
  .successText {
    color: green;
  }
  .errorText {
    color: red;
  }
}
</style>
