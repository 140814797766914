// 公共方法
export default {
  /**
   * 字符串是否为空
   * @param {Object} e
   */
  isNull(e) {
    // return (e == null || e == undefined || e == '' || e == 'undefined') && e!=0 && e!='0';
    // return (
    //   (e == null || e == undefined || e == "" || e == "undefined") && e != "0"
    // );
    return (
      e == null ||
      e == undefined ||
      (typeof e == "string" && e == "") ||
      e == "undefined"
    );
  },
  /**
   * 发起请求
   * @param {*} this 默认传入this
   * @param {*} func 函数
   * @param {*} data 参数
   * @param {*} successReminder 成功提醒
   */
  async request(that, func, data, successReminder) {
    const loading = that.$loading({
      lock: true,
      text: "Loading",
      spinner: "el-icon-loading",
    });
    // 如果超过指定时间无响应，则关闭加载中
    const setTimeoutId = setTimeout(function () {
      that.$message.error("连接服务器超时");
      loading.close();
    }, that.$lodingMaxTime);
    const res = await func(data);
    if (!res.success) {
      that.$message.error(res.err);
    }
    clearTimeout(setTimeoutId);
    loading.close();
    if (successReminder && res.success) {
      that.$message.success(res.data);
    }
    return res;
  },
  // 整数校验,是否是整数
  numberCheck(e) {
    return !isNaN(e * 1);
  },
  numberCheck2(e) {
    if (!this.numberCheck(e)) {
      return 0;
    } else {
      return e;
    }
  },
  // 顺序排序校验
  numberCheck3(e) {
    e = this.numberCheck2(e);
    if (e == 0) {
      return e;
    }
    return e.split(".")[0] * 1;
  },

  /**
   * 将formMes与file列表合并到formData中
   */
  formMesUpdateFormDate(formMes, file) {
    const formData = new FormData();
    if (!this.isNull(formMes)) {
      const formDatakeys = Object.keys(formMes);
      for (let i = 0; i < formDatakeys.length; i++) {
        const key = formDatakeys[i];
        let mes = formMes[key];
        if (this.isNull(mes)) {
          mes = "";
        }
        formData.append(key, mes);
      }
    }
    if (!this.isNull(file)) {
      const filekeys = Object.keys(file);
      for (let i = 0; i < filekeys.length; i++) {
        const key = filekeys[i];
        let mes = file[key];
        if (this.isNull(mes)) {
          continue;
        }
        formData.append(key, mes);
      }
    }
    return formData;
  },
};
