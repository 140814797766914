<template>
    <div>
      <el-form
        :model="updateForm"
        :rules="updateFormRules"
        ref="updateFormRed"
        label-width="auto"
        v-loading="showLoading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <!-- 获得浇水能量 -->
        <el-form-item label="获得浇水能量" prop="obtainWater">
            <el-input
           type="number"
            v-model="updateForm.obtainWater"
            placeholder="请输入能量数值"
            clearable
          ></el-input>
        </el-form-item>
         <!-- 限制获得浇水次数 -->
         <el-form-item label="限制获得浇水次数" prop="obtainWaterTimes">
            <el-input
           type="number"
            v-model="updateForm.obtainWaterTimes"
            placeholder="请输入限制获得浇水次数"
            clearable
          ></el-input>
        </el-form-item>
         <!-- 获得施肥能量 -->
         <el-form-item label="获得施肥能量" prop="obtainFertilizing">
            <el-input
           type="number"
            v-model="updateForm.obtainFertilizing"
            placeholder="请输入能量数值"
            clearable
          ></el-input>
        </el-form-item>
         <!-- 限制获得施肥次数 -->
         <el-form-item label="限制获得施肥次数" prop="obtainFertilizingTimes">
            <el-input
           type="number"
            v-model="updateForm.obtainFertilizingTimes"
            placeholder="请输入限制获得施肥次数"
            clearable
          ></el-input>
        </el-form-item>
        <!-- 消耗浇水能量数值 -->
        <el-form-item label="消耗浇水能量数值" prop="consumeWater">
          <el-input
           type="number"
            v-model="updateForm.consumeWater"
            placeholder="请输入消耗浇水能量数值"
            clearable
          ></el-input>
        </el-form-item>
         <!-- 限制消耗浇水次数 -->
         <el-form-item label="限制消耗浇水次数" prop="consumeWaterTimes">
          <el-input
           type="number"
            v-model="updateForm.consumeWaterTimes"
            placeholder="请输入限制消耗浇水次数"
            clearable
          ></el-input>
        </el-form-item>
        <!-- 消耗施肥能量数值 -->
        <el-form-item label="消耗施肥能量数值" prop="consumeFertilizing">
          <el-input
           type="number"
            v-model="updateForm.consumeFertilizing"
            placeholder="请输入消耗施肥能量数值"
            clearable
          ></el-input>
        </el-form-item> 
        <!-- 限制消耗施肥次数 -->
        <el-form-item label="限制消耗施肥次数" prop="consumeFertilizingTimes">
          <el-input
           type="number"
            v-model="updateForm.consumeFertilizingTimes"
            placeholder="请输入限制消耗施肥次数"
            clearable
          ></el-input>
        </el-form-item>
        <!-- 注冊用户默认能量 -->
        <el-form-item label="注冊用户默认能量" prop="energynum">
          <el-input
           type="number"
            v-model="updateForm.energynum"
            placeholder="请输入注冊用户默认能量"
            clearable
          ></el-input>
        </el-form-item>
         <!-- 超出最大值弹出广告 -->
         <el-form-item label="超出最大值弹出广告" prop="maxnum">
          <el-input
           type="number"
            v-model="updateForm.maxnum"
            placeholder="请输入超出最大值弹出广告"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="播放获得奖励状态" prop="playbool">
        <el-switch v-model="updateForm.playbool"> </el-switch>
      </el-form-item>
      </el-form>
      <!-- 底部按钮 -->
      <div class="buttomButton">
        <el-button type="primary" @click="tosave">确 定</el-button>
      </div>
    </div>
  </template>
  
  <script>
  
  export default {
    name: "Update",
    data() {
      return {
        userlist:[],
        typelist:[
            {value:0,title:"浇水"},
            {value:1,title:"施肥"},
          ],
        // 编辑内容
        updateForm: {
  
        },
        // 表单验证
        updateFormRules: {
          obtainWater: [
            { required: true, message: "请输入获得浇水能量", trigger: "blur" },
          ],
          obtainWaterTimes: [
            { required: true, message: "请输入限制获得浇水次数", trigger: "blur" },
          ],
          obtainFertilizing: [
            { required: true, message: "请输入获得施肥能量", trigger: "blur" },
          ],
          obtainFertilizingTimes: [
            { required: true, message: "请输入限制获得施肥次数", trigger: "blur" },
          ],
          consumeWater: [
            { required: true, message: "请输入消耗浇水能量", trigger: "blur" },
          ],
          consumeWaterTimes: [
            { required: true, message: "请输入限制消耗浇水次数", trigger: "blur" },
          ],
          consumeFertilizing: [
            { required: true, message: "请输入消耗施肥能量", trigger: "blur" },
          ],
          consumeFertilizingTimes: [
            { required: true, message: "请输入限制消耗施肥次数", trigger: "blur" },
          ],
          energynum:[
          { required: true, message: "请输入注冊用户默认能量", trigger: "blur" },
          ] ,
          maxnum:[
          { required: true, message: "请输入超出最大值弹出广告", trigger: "blur" },
          ]
        },
        headerObj: { Authorization: window.sessionStorage.getItem("token") },
        showLoading: false, // 加载中
      };
    },
    created() {
      this.refaushForm(1);
    },
    methods: {
      // 点击提交
      tosave() {
        this.$refs["updateFormRed"].validate((valid) => {
          if (!valid) {
            return;
          }
          this.save();
        });
      },
      // 发起请求
      async save() {
          let fielddata={...this.updateForm}; 
          if(fielddata.playbool==true)
          {
            fielddata.playbool=1;
          }
          else
          {
            fielddata.playbool=0;
          }         
          const { data: res } = await this.$http.post(
          "/userconfig/update",
          {
            ...fielddata,
          }
        );
        if ("200" != res.code) {
          return this.$message.error(res.message);
        }
        this.$message.success("操作成功");
      },
      // 刷新编辑对象
      async refaushForm(e) {
        // 根据id获取数据
        const { data: res } = await this.$http.get(
          "/userconfig/getOne?id=" + e
        );
        if (res.code != 200) {
          return this.$message.error(res.message);
        }
        let message = res.message; 
      if(message.playbool==1) {
        message.playbool=true;
      }
      else
      {
        message.playbool=false;
      }
        this.updateForm = message;
      },
      isnull(e) {
        return e == null || e == undefined || e == "";
      },
      // input校验不能中文
      inputCheck(e) {
        if (/.*[\u4e00-\u9fa5]+.*$/.test(e)) {
          this.$message.error("小程序唯一码不能含有中文！");
        }
      },
    },
  };
  </script>
  
  <style lang="less" scoped>
  .buttomButton {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  </style>
  