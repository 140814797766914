<template>
  <div class="div_main">
    <el-form
      :model="updateForm"
      ref="updateFormRed"
      label-width="auto"
      v-loading="showLoading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <el-divider content-position="left">首页自动跳转</el-divider>
      <!-- 跳转h5 -->
      <el-form-item label="跳转h5" prop="jumpToH5">
        <el-input
          v-model="updateForm.jumpToH5"
          placeholder="请输入跳转h5"
          clearable
        ></el-input>
      </el-form-item>
      <!-- 是否开启 -->
      <el-form-item label="跳转h5" prop="jumpToH5Status">
        <el-switch v-model="updateForm.jumpToH5Status"> </el-switch>
      </el-form-item>
      <el-divider content-position="left">其他功能</el-divider>
      <!-- 周边类型 -->
      <el-form-item label="周边类型" prop="poiType">
        <el-select
          v-model="updateForm.poiType[0]"
          filterable
          clearable
          placeholder="请选择"
        >
          <el-option
            v-for="item in poiList"
            :key="item.key"
            :label="item.value"
            :value="item.key"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="updateForm.poiType[1]"
          filterable
          clearable
          placeholder="请选择"
        >
          <el-option
            v-for="item in poiList"
            :key="item.key"
            :label="item.value"
            :value="item.key"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="updateForm.poiType[2]"
          filterable
          clearable
          placeholder="请选择"
        >
          <el-option
            v-for="item in poiList"
            :key="item.key"
            :label="item.value"
            :value="item.key"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <!-- 灯火广告 -->
      <el-form-item label="灯火广告" prop="lightAdStatus">
        <el-switch v-model="updateForm.lightAdStatus"> </el-switch>
      </el-form-item>
      <!-- 轮播图 -->
      <el-form-item label="轮播图" prop="rotationMap">
        <template>
          <el-switch v-model="updateForm.rotationMap"> </el-switch>
        </template>
      </el-form-item>
      <!-- 菜单列表 -->
      <el-form-item label="菜单列表" prop="menuList">
        <template>
          <el-switch v-model="updateForm.menuList"> </el-switch>
        </template>
      </el-form-item>
      <!-- 图文列表 -->
      <el-form-item label="图文列表" prop="imageText">
        <template>
          <el-switch v-model="updateForm.imageText"> </el-switch>
        </template>
      </el-form-item>
      <!-- 用户授权 -->
      <el-form-item label="用户授权" prop="authorization">
        <template>
          <el-switch v-model="updateForm.authorization"> </el-switch>
        </template>
      </el-form-item>
      <!-- 模版消息 -->
      <el-form-item label="模版消息" prop="message">
        <template>
          <el-switch v-model="updateForm.message"> </el-switch>
        </template>
      </el-form-item>
      <!-- 商家券 -->
      <el-form-item label="商家券" prop="coupon">
        <template>
          <el-switch v-model="updateForm.coupon"> </el-switch>
        </template>
      </el-form-item>
      <!-- 状态 -->
      <!-- <el-form-item label="状态" prop="message">
        <el-switch v-model="updateForm.status"> </el-switch>
      </el-form-item> -->
    </el-form>
    <!-- 底部按钮 -->
    <div class="buttomButton">
      <el-button type="primary" @click="tosave">确 定</el-button>
    </div>
  </div>
</template>
<script>
import {
  getConfiguration,
  updateConfiguration,
} from "@/services/thirdParty/smallRoutine/applet1/configuration.js";
export default {
  props: ["showSingleId", "poiList"],
  name: "smallroutineConfiguration",
  data() {
    return {
      updateForm: {
        poiType: [],
      },
      showLoading: false,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getMes();
    },
    async getMes() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let res = await getConfiguration(this.showSingleId);
      loading.close();
      if (!res.success) {
        return this.$message.error(res.err);
      }
      this.updateForm = res.data;
    },
    tosave() {
      this.$refs["updateFormRed"].validate((valid) => {
        if (!valid) {
          return;
        }
        let updateForm = this.updateForm;
        if (updateForm.jumpToH5Status && this.isnull(updateForm.jumpToH5)) {
          return this.$message.error(
            "首页自动跳转开关开启时跳转的h5路径不能为空"
          );
        }
        this.save();
      });
    },
    async save() {
      let res = await updateConfiguration(this.updateForm);
      if (!res.success) {
        return this.$message.error(res.err);
      }
      this.$message.success("操作成功");
    },
    isnull(e) {
      return e == null || e == undefined || e == "";
    },
  },
};
</script>

<style lang="less" scoped></style>
