<template>
  <div class="div_home">
    <el-menu
      :default-active="activeIndex"
      class="el-menu-demo home_menu"
      mode="horizontal"
      background-color="#373d41"
      text-color="#ffffff"
      active-text-color="#ffd04b"
      @select="handleSelect"
    >
      <el-menu-item index="1">客户管理</el-menu-item>
      <el-menu-item index="2">广告信息</el-menu-item>
      <!-- <el-menu-item index="3">广告浏览日志</el-menu-item> -->
      <!-- <el-menu-item index="4">广告任务</el-menu-item> -->
    </el-menu>
    <customer
      v-show="activeIndex == '1'"
      :refreshCustomerList="getCustomerList"
    />
    <information v-show="activeIndex == '2'" :customerList="customerList" />
    <!-- <informationGetLog v-show="activeIndex == '3'" /> -->
  </div>
</template>

<script>
import customer from "./customer/customer.vue";
import information from "./information/information.vue";
// import informationGetLog from "./informationGetLog/informationGetLog.vue";
export default {
  components: { customer, information },
  data() {
    return {
      // 默认展示模块
      activeIndex: "1",
      // 小程序列表
      smallList: [],
      // 唯一标识列表
      smallCodeList: [],
      // 客户列表
      customerList: [],
    };
  },
  created() {
    this.getCustomerList();
  },
  methods: {
    // 顶部菜单变化
    handleSelect(e) {
      this.activeIndex = e + "";
    },
    // 获取客户列表
    async getCustomerList() {
      const { data: res } = await this.$http.get(
        "/advertisementCustomer/getCustomerKeyNameList"
      );
      if ("200" != res.code) {
        return this.$message.error(res.message);
      }
      this.customerList = res.message;
    },
  },
};
</script>

<style lang="less" scoped>
.div_home {
  height: 99%;
  .home_menu {
    position: fixed;
    top: 0;
  }
  .commodityImg {
    width: 100px;
    height: 100px;
  }
  .div_input {
    display: flex;
    align-items: center;
    .div_input_text {
      white-space: nowrap;
      padding-right: 3px;
    }
  }
}
</style>
