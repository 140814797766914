// 该文件用于创建Vuex中最为核心的store
import Vue from 'vue'
// 引入vuex
import Vuex from 'vuex'
// vue使用vuex
Vue.use(Vuex)

// 准备actions—用于响应组件中的动作
const actions = {}
// 准备state—用于存储数据
const state = {
    themeColor : "#0001"
}
// 准备mutations——用于操作数据(state)
const mutations = {
    changeThemeColor(state,val){
        state.themeColor = val
    }
}
// 准备getters——用于将state中的数据进行加工(不一定要使用)
const getters ={
    themeColor(state){
        return state.themeColor
    }
}

// 创建并暴露stroe
export default new Vuex.Store({
	actions,
	mutations,
	state,
	getters
})