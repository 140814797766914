<template>
  <div>
    <el-form
      :model="addForm"
      :rules="addFormRules"
      ref="addFormRed"
      label-width="auto"
      v-loading="showLoading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <!-- 名称 -->
      <el-form-item label="名称" prop="name">
        <el-input
          v-model="addForm.name"
          placeholder="请输入名称"
          maxlength="100"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
       <!-- 排序 -->
       <el-form-item label="排序" prop="sort">
        <el-input
          v-model="addForm.sort"
          type="number"
          placeholder="请输入排序"
        ></el-input>
      </el-form-item>
      <!-- 状态 -->
      <el-form-item label="状态" prop="status">
        <el-switch v-model="addForm.status"> </el-switch>
      </el-form-item>
    </el-form>
    <!-- 底部按钮 -->
    <div class="buttomButton">
      <el-button type="primary" @click="toAdd">确 定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Add",
  props: ["closeThis", "refaushList"],
  data() {
    const checkNum = (rule, value, cb) => {
      const reg = /^\d+$/;
      if (reg.test(value)) {
        return cb()
      }
      cb(new Error('请输入数字类型'))
    };
    return {
      addForm: {
        name: "",
        sort:1,
        delId: 0,
        status: true,
      },
      dellist:[
        {id:0,title:"否"},
        {id:1,title:"是"}
      ],
      // 表单验证
      addFormRules: {
        name: [
          { required: true, message: "请输入名称", trigger: "blur" },
          {
            min: 1,
            max: 255,
            message: "长度在 1 到 255 个字符",
            trigger: "blur",
          },
        ],
        sort: [
          { required: true, validator: checkNum, trigger: "blur" }
        ],
      },
      showLoading: false, // 加载中
    };
  },
  methods: {
    // 清空表单
    resetForm() {
      // 清空表单内容
      this.$refs["addFormRed"].resetFields();
    },
    // 点击提交
    toAdd() {
      this.$refs["addFormRed"].validate((valid) => {
        if (!valid) {
          return;
        }
        if(this.addForm.status)
        {
            this.addForm.status=1;
        }
        else
        {
            this.addForm.status=0;
        }
        this.add();
      });
    },
    // 新增
    async add() {
      let fielddata={...this.addForm};
      if(fielddata.status==true)
      {
        fielddata.status=1;
      }
      else
      {
        fielddata.status=0;
      }   
      const { data: res } = await this.$http.post(
        "/plantstype/add",
        {
          ...fielddata,
        }
      );
      if ("200" != res.code) {
        return this.$message.error(res.message);
      }
      this.resetForm();
      this.$message.success("操作成功");
      this.refaushList();
    },
    // input校验不能中文
    inputCheck(e) {
      if (/.*[\u4e00-\u9fa5]+.*$/.test(e)) {
        this.$message.error("小程序唯一码不能含有中文！");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.buttomButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
