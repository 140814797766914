<template>
  <div>
    <el-form
      :model="addForm"
      :rules="addFormRules"
      ref="addFormRed"
      label-width="auto"
      v-loading="showLoading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <!-- 用户名 -->
      <el-form-item label="用户名" prop="username">
        <el-input
          v-model="addForm.username"
          placeholder="请输入用户名"
          maxlength="100"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
      <!-- 密码 -->
      <el-form-item label="密码" prop="password">
        <el-input
          v-model="addForm.password"
          clearable
          show-password
          type="password"
          maxlength="64"
          placeholder="请输入密码"
        ></el-input>
      </el-form-item>
        <!-- 确认密码 -->
        <el-form-item label="确认密码" prop="confirmpassword">
        <el-input
          v-model="addForm.confirmpassword"
          clearable
          show-password
          type="password"
          maxlength="64"
          placeholder="请输入确认密码"
        ></el-input>
      </el-form-item>
      <el-form-item label="人头像" prop="headImg">
        <el-upload
            class="avatar-uploader"
            list-type="picture-card"
            :auto-upload="false"
            action="#"
            :show-file-list="false"
            :on-change="imgChange"
          >
            <img class="div_main_img" v-if="addForm.headImg" :src="addForm.headImg" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
      </el-form-item>
       <!-- 手机号 -->
       <el-form-item label="性别" prop="sex">
        <el-radio-group v-model="addForm.sex" class="ml-2">
          <el-radio label="男" >男</el-radio>
          <el-radio label="女" >女</el-radio>
        </el-radio-group>
      </el-form-item>
       <!-- 手机号 -->
       <el-form-item label="手机号" prop="mobile">
        <el-input
          v-model="addForm.mobile"
          clearable
          maxlength="64"
          placeholder="请输入手机号"
        ></el-input>
      </el-form-item>
        <!-- 邮箱 -->
        <el-form-item label="邮箱" prop="email">
        <el-input
          v-model="addForm.email"
          clearable
          maxlength="100"
          placeholder="请输入邮箱"
        ></el-input>
      </el-form-item>
       <!-- 第三方ID -->
       <el-form-item label="第三方ID" prop="openId">
        <el-input
          v-model="addForm.openId"
          clearable
          maxlength="100"
          placeholder="请输入第三方ID"
        ></el-input>
      </el-form-item>
      <el-form-item label="是否删除" prop="del">
        <el-switch v-model="addForm.del"> </el-switch>
      </el-form-item>
      <!-- 状态 -->
      <el-form-item label="状态" prop="status">
        <el-switch v-model="addForm.status"> </el-switch>
      </el-form-item>
    </el-form>
    <!-- 底部按钮 -->
    <div class="buttomButton">
      <el-button type="primary" @click="toAdd">确 定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "usersAdd",
  props: ["closeThis", "refaushList"],
  data() {
    let validatePass = (rule, value, callback) => {
    let reg = new RegExp(/[A-Za-z].*[0-9]|[0-9].*[A-Za-z]/);
    if (value === "") {
      callback(new Error("请输入密码"));
    } else if (value.length < 8 || !reg.test(value)) {
      callback(new Error("请输入含数字和字母的8-16位密码"));
    } else {
      if (this.addForm.confirmpassword !== "") {
        this.$refs.addFormRed.validateField("confirmpassword");
      }
      callback();
    }
  };
  let validatePass2 = (rule, value, callback) => {
    if (value === "") {
      callback(new Error("请再次输入密码"));
    } else if (value !== this.addForm.password) {
      callback(new Error("两次输入密码不一致!"));
    } else {
      callback();
    }
  };
    return {
      file: {
        imgFile: null
      },
      addForm: {
        username: "",
        password: "",
        confirmpassword: "",
        headImg:"",
        sex:"男",
        mobile: "",
        email: "",
        openId: "",
        del: false,
        delId: 0,
        status: true,
        
      },
      dellist:[
        {id:0,title:"否"},
        {id:1,title:"是"}
      ],
      sexlist:[
        {id:0,title:"女"},
        {id:1,title:"男"}
      ],
      // 表单验证
      addFormRules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            min: 1,
            max: 64,
            message: "长度在 1 到 64 个字符",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, validator: validatePass, trigger: "blur" }
        ],
        confirmpassword: [
          { required: true, validator: validatePass2, trigger: "blur" }
        ],
        mobile:[
        { required: true, message: "请输入手机号", trigger: "blur" }
        ],
      },
      showLoading: false, // 加载中
    };
  },
  methods: {
    // 清空表单
    resetForm() {
      // 清空表单内容
      this.$refs["addFormRed"].resetFields();
    },
    // 点击提交
    toAdd() {
      this.$refs["addFormRed"].validate((valid) => {
        if (!valid) {
          return;
        }
        if(this.addForm.status)
        {
            this.addForm.status=1;
        }
        else
        {
            this.addForm.status=0;
        }
        this.add();
      });
    },
    // 新增
    async add() {
      let fielddata={...this.addForm};
      if(fielddata.status==true)
      {
        fielddata.status=1;
      }
      else
      {
        fielddata.status=0;
      }
      if(fielddata.del==true)
      {
        fielddata.del=1;
      }
      else
      {
        fielddata.del=0;
      }
      const { data: res } = await this.$http.post(
        "/users/add",
        {
          ...fielddata,
        }
      );
      if ("200" != res.code) {
        return this.$message.error(res.message);
      }
      this.resetForm();
      this.$message.success("操作成功");
      this.refaushList();
    },
    imgChange(e) {
      this.$set(this.addForm, "headImg", e.url);
      this.$set(this.file, "imgFile", e.raw);
    },
    // input校验不能中文
    inputCheck(e) {
      if (/.*[\u4e00-\u9fa5]+.*$/.test(e)) {
        this.$message.error("小程序唯一码不能含有中文！");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.buttomButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
