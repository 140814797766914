// 接口请求地址
const path = "basicInformation/";
import axios from "axios";

/**
 * 获取信息
 */
export async function getMes() {
  const { data: res } = await axios.get(path + "getMes");
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: message,
    };
  }
  return {
    success: true,
    data: message,
  };
}

/**
 * 编辑
 */
export async function updateMes(data = {}) {
  const { data: res } = await axios.post(path + "updateMes", data);
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: message,
    };
  }
  return {
    success: true,
    data: message,
  };
}
