<template>
  <div>
    <list
      v-show="showType == 1"
      @showMassUpdate="showMassUpdate"
      @setFlushTable="setFlushTable"
      @showMassUpdateLog="showMassUpdateLog"
      @toShowMassUpdateVersion="toShowMassUpdateVersion"
      :templateList="templateList"
      :showType="showType"
      :flushTable="flushTable"
    />
    <massUpdate
      v-if="showType == 2"
      @showList="showList"
      @setFlushTable="setFlushTable"
      :templateList="templateList"
    />
    <massUpdateLog
      v-if="showType == 3"
      @showList="showList"
      :id="seeId"
      :templateList="templateList"
    />
    <massUpdateVersion
      v-if="showType == 4"
      @showList="showList"
      :templateList="templateList"
    />
  </div>
</template>

<script>
import list from "./list.vue";
import massUpdate from "./massUpdate.vue";
import massUpdateLog from "./massUpdateLog.vue";
import massUpdateVersion from "./massUpdateVersion.vue";
import { getNameAndIdListTemplate } from "@/services/thirdParty/getNameAndIdListTemplate";
export default {
  components: {
    list,
    massUpdate,
    massUpdateLog,
    massUpdateVersion,
  },
  data() {
    return {
      // 展示内容：1列表，2批量申请表单
      showType: 1,
      // 模板列表
      templateList: [],
      flushTable: false,
      seeId: "",
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.toGetNameAndIdListTemplate();
    },
    // 获取模板列表
    async toGetNameAndIdListTemplate() {
      const res = await this.$publicJs.request(this, getNameAndIdListTemplate);
      if (!res.success) {
        return;
      }
      this.$set(this, "templateList", res.data);
    },
    showList() {
      this.$set(this, "showType", 1);
    },
    showMassUpdate() {
      this.$set(this, "showType", 2);
    },
    showMassUpdateLog(e) {
      this.$set(this, "showType", 3);
      this.$set(this, "seeId", e);
    },
    toShowMassUpdateVersion() {
      this.$set(this, "showType", 4);
    },
    setFlushTable(e) {
      this.$set(this, "flushTable", e);
    },
  },
};
</script>

<style lang="less" scoped></style>
