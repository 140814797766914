<template>
  <div>
    <el-form
      :model="addForm"
      :rules="addFormRules"
      ref="addFormRed"
      label-width="auto"
    >
      <el-divider content-position="left">活动基础信息</el-divider>
      <!-- 活动名称 -->
      <el-form-item label="活动名称" prop="activityName">
        <el-input
          v-model="addForm.activityBaseInfo.activityName"
          placeholder="请输入活动名称(不对用户进行展示，仅供商家在后台管理活动使用。)"
          maxlength="100"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
      <!-- 码模式 -->
      <el-form-item label="码模式" prop="codeMode">
        <el-select
          v-model="addForm.activityBaseInfo.codeMode"
          placeholder="请选择"
        >
          <el-option label="商户上传自定义code" value="MERCHANT_UPLOAD">
          </el-option>
          <el-option label="发奖时指定券码发奖" value="MERCHANT_API">
          </el-option>
        </el-select>
      </el-form-item>
      <el-divider content-position="left">券发放模式信息</el-divider>
      <el-form-item label="发行券的数量" prop="quantity">
        <el-input
          v-model="addForm.voucherSendModeInfo.voucherSendRuleInfo.quantity"
          placeholder="code_mode=MERCHANT_UPLOAD模式下数量必须为0。 其他模式下该数值必须是大于0的整数"
          maxlength="10"
          show-word-limit
          :disabled="addForm.activityBaseInfo.codeMode == 'MERCHANT_UPLOAD'"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="每人领取限制" prop="quantityLimitPerUser">
        <el-input
          v-model="
            addForm.voucherSendModeInfo.voucherSendRuleInfo.quantityLimitPerUser
          "
          placeholder="1.默认按照支付宝账号进行领取限制。 2.不填写或填入0表示没有领取限制。"
          maxlength="1"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="自然人领取限制" prop="naturalPersonLimit">
        <el-switch
          v-model="
            addForm.voucherSendModeInfo.voucherSendRuleInfo.naturalPersonLimit
          "
        >
        </el-switch>
      </el-form-item>
      <el-form-item label="电话号码领取限制" prop="phoneNumberLimit">
        <el-switch
          v-model="
            addForm.voucherSendModeInfo.voucherSendRuleInfo.phoneNumberLimit
          "
        >
        </el-switch>
      </el-form-item>
      <el-form-item label="券发放开始时间" prop="publishStartTime">
        <el-date-picker
          v-model="
            addForm.voucherSendModeInfo.voucherSendRuleInfo.publishStartTime
          "
          type="datetime"
          placeholder="选择日期时间"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="券发放结束时间" prop="publishEndTime">
        <el-date-picker
          v-model="
            addForm.voucherSendModeInfo.voucherSendRuleInfo.publishEndTime
          "
          type="datetime"
          placeholder="选择日期时间"
        >
        </el-date-picker>
      </el-form-item>
      <el-divider content-position="left">券优惠抵扣信息</el-divider>
      <el-form-item label="券类型" prop="voucherType">
        <el-select
          v-model="addForm.voucherDeductInfo.voucherType"
          placeholder="请选择"
        >
          <el-option
            v-for="item in voucherType"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            :disabled="item.disabled"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="面额" prop="amount">
        <el-input
          v-model="addForm.voucherDeductInfo.fixVoucherInfo.amount"
          placeholder="取值范围：[0.1,3000]限制：1.币种为人民币，单位为元。2.小数点以后最多保留两位。"
          maxlength="12"
          show-word-limit
          clearable
          @input="priceFormatTotalPrice"
        ></el-input>
      </el-form-item>
      <el-form-item label="门槛金额" prop="floorAmount">
        <el-input
          v-model="addForm.voucherDeductInfo.fixVoucherInfo.floorAmount"
          placeholder="门槛金额。取值范围：[0.1,50000]注意事项:1.该字段不填写，认为无门槛。2.币种为人民币，单位为元。3.小数点以后最多保留两位。"
          maxlength="12"
          show-word-limit
          clearable
          @input="priceFormatTotalPrice"
        ></el-input>
      </el-form-item>
      <el-divider content-position="left">券核销限制</el-divider>
      <el-form-item label="券可使用的开始时间" prop="validBeginTime">
        <el-date-picker
          v-model="
            addForm.voucherUseRuleInfo.voucherUseTimeInfo.absolutePeriodInfo
              .validBeginTime
          "
          type="datetime"
          placeholder="选择日期时间"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="券可使用的结束时间" prop="validEndTime">
        <el-date-picker
          v-model="
            addForm.voucherUseRuleInfo.voucherUseTimeInfo.absolutePeriodInfo
              .validEndTime
          "
          type="datetime"
          placeholder="选择日期时间"
        >
        </el-date-picker>
      </el-form-item>
      <el-divider content-position="left">券展示信息</el-divider>
      <el-form-item label="商户品牌名称" prop="brandName">
        <el-input
          v-model="addForm.voucherDisplayPatternInfo.brandName"
          placeholder="请输入商户品牌名称"
          maxlength="12"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="券详细使用说明	" prop="voucherDescription">
        <el-input
          v-model="addForm.voucherDisplayPatternInfo.voucherDescription"
          placeholder="请输入券详细使用说明"
          maxlength="1000"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
      <!-- 小程序 -->
      <el-form-item label="小程序" prop="appIds">
        <el-transfer filterable v-model="appIds" :data="allAppIdAndNameList">
        </el-transfer>
      </el-form-item>
    </el-form>
    <!-- 底部按钮 -->
    <div class="buttomButton">
      <el-button type="primary" @click="toAdd" :disabled="disabled">
        确 定
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "bottomCommodityMenuOneAdd",
  props: ["refaushList", "allAppIdAndNameList"],
  data() {
    return {
      appIds: [],
      addForm: {
        merchantAccessMode: "AGENCY_MODE",
        // 活动基础信息
        activityBaseInfo: {
          activityName: "",
          codeMode: "MERCHANT_UPLOAD",
        },
        // 券发放方式
        voucherSendModeInfo: {
          // 券发放模式
          voucherSendMode: "DIRECT_SEND_MODE",
          // 券发放规则
          voucherSendRuleInfo: {
            // code_mode=MERCHANT_UPLOAD模式下数量必须为0。 其他模式下该数值必须是大于0的整数。
            quantity: 0,
            // 每人领取限制:1.默认按照支付宝账号进行领取限制。 2.不填写或填入0表示没有领取限制。
            quantityLimitPerUser: "",
            // 是否开启自然人领取限制， 自然人表示按照身份证纬度进行领取限制。
            naturalPersonLimit: false,
            // 是否开启电话号码领取限制
            phoneNumberLimit: false,
            // 券发放开始时间
            publishStartTime: "",
            // 券发放结束时间
            publishEndTime: "",
          },
        },
        // 券优惠抵扣信息
        voucherDeductInfo: {
          /**
           * 券类型:
            满减券： FIX_VOUCHER
            折扣券： DISCOUNT_VOUCHER
            特价券： SPECIAL_VOUCHER
           */
          voucherType: "FIX_VOUCHER",
          // 满减券详情
          fixVoucherInfo: {
            amount: "",
            floorAmount: "",
          },
          // // 折扣券详情
          // discountVoucherInfo: {
          //   // 折扣率,比如：6.5折填入6.5。取值范围：[0.1,9.9]
          //   discount: "6.5",
          //   ceilingAmount: "1.00",
          // },
          // // 特价券详情
          // specialVoucherInfo: {
          //   // 特价，即：原价-特价=优惠金额。
          //   specialAmount: "5.00",
          // },
        },
        // 券可用范围
        voucherAvailableScopeInfo: {
          // 券可用地理位置
          voucherAvailableGeographyScopeInfo: {
            availableGeographyScopeType: "CITY_CODE",
            availableGeographyCityInfo: {
              allCity: true,
            },
          },
          // 券可用商品
          voucherAvailableGoodsInfo: {},
        },
        // 券核销限制
        voucherUseRuleInfo: {
          // 券核销时间
          voucherUseTimeInfo: {
            periodType: "ABSOLUTE",
            // 券绝对时间可用
            absolutePeriodInfo: {
              validBeginTime: "",
              validEndTime: "",
            },
          },
        },
        // 券引导详情
        voucherCustomerGuideInfo: {
          // 券核销详情
          voucherUseGuideInfo: {
            // 使用引导模式
            useGuideMode: ["MINI_APP"],
            miniAppUseGuideInfo: {
              miniAppUrl: "alipays://platformapi/startapp?appId=",
            },
          },
        },
        // 券展示信息
        voucherDisplayPatternInfo: {
          // 商户品牌名称
          brandName: "",
          // 商家 logo
          brandLogo: "",
          // 券详细使用说明
          voucherDescription: "",
          customerServiceMobile: "13110655990",
        },
      },
      // 表单验证
      addFormRules: {},
      voucherType: [
        {
          label: "满减券",
          value: "FIX_VOUCHER",
          disabled: false,
        },
        {
          label: "折扣券",
          value: "DISCOUNT_VOUCHER",
          disabled: true,
        },
        {
          label: "特价券",
          value: "SPECIAL_VOUCHER",
          disabled: true,
        },
      ],
      disabled: false,
    };
  },
  methods: {
    // 清空表单
    resetForm() {
      // 清空表单内容
      this.$refs["addFormRed"].resetFields();
    },
    // 点击提交
    toAdd() {
      this.$refs["addFormRed"].validate((valid) => {
        if (!valid) {
          return;
        }
        this.add();
      });
    },
    // 新增
    async add() {
      this.disabled = true;
      const { data: res } = await this.$http.post("/aliMerchantCoupon/add", {
        appIds: this.appIds,
        data: this.addForm,
      });
      this.disabled = false;
      if ("200" != res.code) {
        return this.$message.error(res.message);
      }
      this.resetForm();
      this.$message.success("操作成功");
      this.refaushList();
    },
    // 金额正则表达式
    priceFormatTotalPrice(e) {
      this.addForm.totalPrice =
        this.addForm.totalPrice.match(/^\d*(\.?\d{0,2})/g)[0] || null;
      if (isNaN(this.addForm.totalPrice)) {
        this.addForm.totalPrice = "";
      }
      // 在不是“0.”开头的字符进行修改：“01”=>1
      let price = e.toString();
      if (
        price.charAt(0) == "0" &&
        price.charAt(1) != "." &&
        this.addForm.totalPrice.length >= 2
      ) {
        this.addForm.totalPrice = this.addForm.totalPrice.replace(/0/, "");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.buttomButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
