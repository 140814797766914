<template>
  <div class="detailsMes">
    <el-divider content-position="left">服务信息</el-divider>
    <div class="detailsMes_single" v-if="!$publicJs.isNull(mes.status)">
      <div class="detailsMes_single_label">申请单状态</div>
      <div class="detailsMes_single_value">
        <span v-if="mes.status == 'AGREE'">同意</span>
        <span v-else-if="mes.status == 'REJECT'">拒绝</span>
        <span v-else-if="mes.status == 'EDIT'">编辑</span>
        <span v-else-if="mes.status == 'AUDIT'">审核</span>
        <span v-else-if="mes.status == 'PART_AGREE'">部分同意</span>
        <span v-else>其他:{{ mes.status }}</span>
      </div>
    </div>
    <div class="detailsMes_single" v-if="!$publicJs.isNull(mes.describe)">
      <div class="detailsMes_single_label">服务描述</div>
      <div class="detailsMes_single_value">
        {{ mes.describe }}
      </div>
    </div>
    <el-divider content-position="left">基础工单详情信息</el-divider>
    <template v-if="!$publicJs.isNull(mes.detail_base_items)">
      <div
        class="detailsMes_single"
        v-if="!$publicJs.isNull(mes.detail_base_items.can_search)"
      >
        <div class="detailsMes_single_label">搜索可见性</div>
        <div class="detailsMes_single_value">
          {{ mes.detail_base_items.can_search }}
        </div>
      </div>
      <div
        class="detailsMes_single"
        v-if="!$publicJs.isNull(mes.detail_base_items.desc)"
      >
        <div class="detailsMes_single_label">描述信息</div>
        <div class="detailsMes_single_value">
          {{ mes.detail_base_items.desc }}
        </div>
      </div>
      <div
        class="detailsMes_single"
        v-if="!$publicJs.isNull(mes.detail_base_items.key_word)"
      >
        <div class="detailsMes_single_label">关键词</div>
        <div class="detailsMes_single_value">
          {{ mes.detail_base_items.key_word }}
        </div>
      </div>
      <div
        class="detailsMes_single"
        v-if="!$publicJs.isNull(mes.detail_base_items.region)"
      >
        <div class="detailsMes_single_label">基础工单详情</div>
        <div class="detailsMes_single_value">
          {{ mes.detail_base_items.region }}
        </div>
      </div>
      <div
        class="detailsMes_single"
        v-if="!$publicJs.isNull(mes.detail_base_items.serv_can_search)"
      >
        <div class="detailsMes_single_label">搜索可见性</div>
        <div class="detailsMes_single_value">
          {{ mes.detail_base_items.serv_can_search }}
        </div>
      </div>
      <div
        class="detailsMes_single"
        v-if="!$publicJs.isNull(mes.detail_base_items.serv_search_keywords)"
      >
        <div class="detailsMes_single_label">搜索关键词</div>
        <div class="detailsMes_single_value">
          {{ mes.detail_base_items.serv_search_keywords }}
        </div>
      </div>
      <div
        class="detailsMes_single"
        v-if="!$publicJs.isNull(mes.detail_base_items.template_id)"
      >
        <div class="detailsMes_single_label">模板id</div>
        <div class="detailsMes_single_value">
          {{ mes.detail_base_items.template_id }}
        </div>
      </div>
    </template>
    <el-divider content-position="left">小程序信息</el-divider>
    <div class="detailsMes_single" v-if="!$publicJs.isNull(mes.app_icon)">
      <div class="detailsMes_single_label">小程序图标</div>
      <div class="detailsMes_single_value">
        <img class="detailsMes_single_value_img" :src="mes.app_icon" alt="" />
      </div>
    </div>
    <div class="detailsMes_single" v-if="!$publicJs.isNull(mes.app_name)">
      <div class="detailsMes_single_label">小程序名称</div>
      <div class="detailsMes_single_value">
        {{ mes.app_name }}
      </div>
    </div>
    <div class="detailsMes_single" v-if="!$publicJs.isNull(mes.appid)">
      <div class="detailsMes_single_label">小程序appid</div>
      <div class="detailsMes_single_value">
        {{ mes.appid }}
      </div>
    </div>
    <div class="detailsMes_single" v-if="!$publicJs.isNull(mes.app_status)">
      <div class="detailsMes_single_label">小程序状态</div>
      <div class="detailsMes_single_value">
        {{ mes.app_status }}
      </div>
    </div>
    <div class="detailsMes_single" v-if="!$publicJs.isNull(mes.app_category)">
      <div class="detailsMes_single_label">小程序类目名</div>
      <div class="detailsMes_single_value">
        {{ mes.app_category }}
      </div>
    </div>
    <el-divider content-position="left">其他</el-divider>
    <div class="detailsMes_single" v-if="!$publicJs.isNull(mes.access_type)">
      <div class="detailsMes_single_label">申请单类型</div>
      <div class="detailsMes_single_value">
        <span v-if="mes.access_type == 'BASE'">基础信息</span>
        <span v-else-if="mes.access_type == 'BRAND_BOX'">品牌直达</span>
        <span v-else-if="mes.access_type == 'SERVICE_BOX'">服务直达</span>
        <span v-else>未知类型：{{ mes.access_type }}</span>
      </div>
    </div>
    <div class="detailsMes_single" v-if="!$publicJs.isNull(mes.order_id)">
      <div class="detailsMes_single_label">申请单id</div>
      <div class="detailsMes_single_value">
        {{ mes.order_id }}
      </div>
    </div>
    <div class="detailsMes_single" v-if="!$publicJs.isNull(mes.service_code)">
      <div class="detailsMes_single_label">服务code</div>
      <div class="detailsMes_single_value">
        {{ mes.service_code }}
      </div>
    </div>
    <div class="detailsMes_single" v-if="!$publicJs.isNull(mes.service_name)">
      <div class="detailsMes_single_label">服务名称</div>
      <div class="detailsMes_single_value">
        {{ mes.service_name }}
      </div>
    </div>
    <div
      class="detailsMes_single"
      v-if="!$publicJs.isNull(mes.sub_service_code)"
    >
      <div class="detailsMes_single_label">二级服务code</div>
      <div class="detailsMes_single_value">
        {{ mes.sub_service_code }}
      </div>
    </div>
    <div class="detailsMes_single" v-if="!$publicJs.isNull(mes.template_id)">
      <div class="detailsMes_single_label">模板id</div>
      <div class="detailsMes_single_value">
        {{ mes.template_id }}
      </div>
    </div>
    <div class="detailsMes_single" v-if="!$publicJs.isNull(mes.template_name)">
      <div class="detailsMes_single_label">模板名称</div>
      <div class="detailsMes_single_value">
        {{ mes.template_name }}
      </div>
    </div>
    <div class="detailsMes_single" v-if="!$publicJs.isNull(mes.gmtmodified)">
      <div class="detailsMes_single_label">最后修改时间</div>
      <div class="detailsMes_single_value">
        {{ mes.gmtmodified }}
      </div>
    </div>
    <div
      class="detailsMes_single"
      v-if="!$publicJs.isNull(mes.keyword_gmt_modified)"
    >
      <div class="detailsMes_single_label">关键词修改时间</div>
      <div class="detailsMes_single_value">
        {{ mes.keyword_gmt_modified }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["mes"],
  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="less" scoped>
.detailsMes {
  .detailsMes_single {
    display: flex;
    justify-content: space-between;
    --labelWidth: 100px;
    padding-bottom: 10px;
    .detailsMes_single_label {
      width: var(--labelWidth);
      text-align: right;
      padding-right: 10px;
      color: rgb(163, 162, 162);
    }
    .detailsMes_single_value {
      flex: 1;
      .detailsMes_single_value_img {
        height: 100px;
        width: 100px;
      }
    }
  }
}
</style>
