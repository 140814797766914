// 接口请求地址
import axios from 'axios'
const path = 'thirdPartySmallRoutineConfiguration/';

/**
 * 获取配置
 * @param {*} params
 */
export async function getConfiguration(smallRoutineId) {
  const { data: res } = await axios.get(
    path + 'getConfigurationActivity',
    {
      params: { 
        smallRoutineId: smallRoutineId
       },
    }
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: "获取配置失败:" + res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}

/**
 * 修改配置
 * @param {*} params
 */
 export async function updateConfiguration(data={}) {
  const { data: res } = await axios.put(
    path + 'updateConfigurationActivity',
    data
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}

/**
 * 批量修改配置
 * @param {*} params
 */
 export async function updateConfigurationActivityList(data={}) {
  const { data: res } = await axios.put(
    path + 'updateConfigurationActivityList',
    data
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}
