<template>
  <div>
    <el-form
      :model="addForm"
      :rules="addFormRules"
      ref="addFormRed"
      label-width="auto"
      v-loading="showLoading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <!-- 名称 -->
      <el-form-item label="小程序appId" prop="appId">
        <el-input
          v-model="addForm.appId"
          placeholder="请输入小程序appId"
          maxlength="100"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
      <!-- appId -->
      <el-form-item label="广告spaceCode" prop="adId">
        <el-input
          v-model="addForm.adId"
          clearable
          maxlength="100"
          placeholder="请输入广告spaceCode"
        ></el-input>
      </el-form-item>
      <el-form-item label="类型" prop="typeId">
        <el-select
                v-model="addForm.typeId"
                clearable
                placeholder="请选择"
              >
              <el-option v-for="item in typeIdlist" :key="item.id" :label="item.title" :value="item.id"></el-option>    
              </el-select>
      </el-form-item>
      <!-- 状态 -->
      <el-form-item label="状态" prop="status">
        <el-switch v-model="addForm.status"> </el-switch>
      </el-form-item>
    </el-form>
    <!-- 底部按钮 -->
    <div class="buttomButton">
      <el-button type="primary" @click="toAdd">确 定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "applicationsAdd",
  props: ["closeThis", "refaushList"],
  data() {
    return {
      addForm: {
        adId: "",
        appId: "",
        typeId: 0,
        status: true,
        
      },
      typeIdlist:[
        {id:0,title:"图文广告"},
        {id:1,title:"全屏广告"},
        {id:2,title:"插屏广告"},
        {id:3,title:"信息流广告"},
        {id:4,title:"任务广告"}
      ],
      // 表单验证
      addFormRules: {
        adId: [
          { required: true, message: "请输入广告spaceCode", trigger: "blur" },
          {
            min: 1,
            max: 64,
            message: "长度在 1 到 64 个字符",
            trigger: "blur",
          },
        ],
        appId: [
          { required: true, message: "请输入小程序appId", trigger: "blur" },
        ]
      },
      showLoading: false, // 加载中
    };
  },
  methods: {
    // 清空表单
    resetForm() {
      // 清空表单内容
      this.$refs["addFormRed"].resetFields();
    },
    // 点击提交
    toAdd() {
      this.$refs["addFormRed"].validate((valid) => {
        if (!valid) {
          return;
        }
        if(this.addForm.status)
        {
            this.addForm.status=1;
        }
        else
        {
            this.addForm.status=0;
        }
        this.add();
      });
    },
    // 新增
    async add() {
      const { data: res } = await this.$http.post(
        "/thirdPartyAppAd/add",
        {
          ...this.addForm,
        }
      );
      if ("200" != res.code) {
        return this.$message.error(res.message);
      }
      this.resetForm();
      this.$message.success("操作成功");
      this.refaushList();
    },
    // input校验不能中文
    inputCheck(e) {
      if (/.*[\u4e00-\u9fa5]+.*$/.test(e)) {
        this.$message.error("小程序唯一码不能含有中文！");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.buttomButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
