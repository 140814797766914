<template>
  <div>
    <el-form
      :model="updateForm"
      :rules="updateFormRules"
      ref="updateFormRed"
      label-width="auto"
      v-loading="showLoading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <!-- 小程序appId -->
      <el-form-item label="小程序appId" prop="appId">
        <el-input
          v-model="updateForm.appId"
          placeholder="请输入小程序appId"
          maxlength="100"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
      <!-- 广告spaceCode -->
      <el-form-item label="广告spaceCode" prop="adId">
        <el-input
          v-model="updateForm.adId" 
          maxlength="64"
          placeholder="请输入广告spaceCode"
        ></el-input>
      </el-form-item>
      <el-form-item label="类型" prop="typeId">
        <el-select
                v-model="updateForm.typeId"
                value-key="id"             
                placeholder="请选择"
              >
                <el-option v-for="item in typeIdlist" :key="item.id" :label="item.title" :value="item.id"></el-option>             
              </el-select>
      </el-form-item>
      <!-- 状态 -->
      <el-form-item label="状态" prop="status">
        <el-switch v-model="updateForm.status"> </el-switch>
      </el-form-item>
    </el-form>
    <!-- 底部按钮 -->
    <div class="buttomButton">
      <el-button type="primary" @click="tosave">确 定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "applicationsUpdate",
  props: ["updateId", "refaushList"],
  data() {
    return {
      typeIdlist:[
      {id:0,title:"图文广告"},
        {id:1,title:"全屏广告"},
        {id:2,title:"插屏广告"},
        {id:3,title:"信息流广告"},
        {id:4,title:"任务广告"}
      ],
      // 编辑内容
      updateForm: {},
      // 表单验证
      updateFormRules: {
        appId: [
          { required: true, message: "请输入小程序appId", trigger: "blur" },
          {
            min: 1,
            max: 64,
            message: "长度在 1 到 64 个字符",
            trigger: "blur",
          },
        ],
        adId: [
          { required: true, message: "请输入广告spaceCode", trigger: "blur" },
        ],
      },
      headerObj: { Authorization: window.sessionStorage.getItem("token") },
      showLoading: false, // 加载中
    };
  },
  created() {
    this.refaushForm(this.updateId);
  },
  methods: {
    // 点击提交
    tosave() {
      this.$refs["updateFormRed"].validate((valid) => {
        if (!valid) {
          return;
        }
        this.save();
      });
    },
    // 发起请求
    async save() {
        let fielddata={...this.updateForm};
        if(fielddata.status==true)
        {
          fielddata.status=1;
        }
        else
        {
          fielddata.status=0;
        }
        const { data: res } = await this.$http.put(
        "/thirdPartyAppAd/update",
        {
          ...fielddata,
        }
      );
      if ("200" != res.code) {
        return this.$message.error(res.message);
      }
      this.$message.success("操作成功");
      this.refaushList();
    },
    // 刷新编辑对象
    async refaushForm(e) {
      // 根据id获取数据
      const { data: res } = await this.$http.get(
        "/thirdPartyAppAd/getTbAdById?Id=" + e
      );
      if (res.code != 200) {
        return this.$message.error(res.message);
      }
      let message = res.message; 
      if(message.status==1) {
        message.status=true;
      }
      else
      {
        message.status=false;
      }
      this.updateForm = message;
    },
    isnull(e) {
      return e == null || e == undefined || e == "";
    },
    // input校验不能中文
    inputCheck(e) {
      if (/.*[\u4e00-\u9fa5]+.*$/.test(e)) {
        this.$message.error("小程序唯一码不能含有中文！");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.buttomButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
