<template>
  <div class="div_main">
    <el-form
      :model="updateForm"
      ref="updateFormRed"
      :rules="rules"
      label-width="auto"
      v-loading="showLoading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <el-divider content-position="left">首页自动跳转</el-divider>
      <el-form-item label="跳转h5" prop="jumpToH5Status">
        <el-switch v-model="updateForm.jumpToH5Status"> </el-switch>
      </el-form-item>
      <template v-if="updateForm.jumpToH5Status">
        <el-form-item label="h5链接" prop="jumpToH5">
          <el-input
            v-model="updateForm.jumpToH5"
            placeholder="请输入跳转的h5链接"
            clearable
          ></el-input>
        </el-form-item>
      </template>
      <!-- 是否开启 -->
      <el-form-item label="跳转小程序" prop="jumpToSmallRoutine">
        <el-switch v-model="updateForm.jumpToSmallRoutine"> </el-switch>
      </el-form-item>
      <template v-if="updateForm.jumpToSmallRoutine">
        <el-form-item label="跳转小程序" prop="jumpToSmallRoutineAppId">
          <el-input
            v-model="updateForm.jumpToSmallRoutineAppId"
            placeholder="请输入跳转小程序appId"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="跳转小程序页面" prop="jumpToSmallRoutinePath">
          <el-input
            v-model="updateForm.jumpToSmallRoutinePath"
            placeholder="请输入跳转小程序页面路径"
            clearable
          ></el-input>
        </el-form-item>
      </template>
      <el-divider content-position="left">其他功能</el-divider>
      <!-- 消息模板 -->
      <el-form-item label="消息模板" prop="message">
        <el-switch v-model="updateForm.message"></el-switch>
      </el-form-item>
      <el-form-item label="遮罩层" prop="showMask">
        <el-switch v-model="updateForm.showMask"></el-switch>
      </el-form-item>
      <el-form-item label="灯火广告" prop="lightAdStatus">
        <el-switch v-model="updateForm.lightAdStatus"></el-switch>
      </el-form-item>
      <el-form-item label="任务列表" prop="taskStatus">
        <el-switch v-model="updateForm.taskStatus"></el-switch>
      </el-form-item>
      <el-form-item label="商品展示" prop="goodsStatus">
        <el-switch v-model="updateForm.goodsStatus"></el-switch>
      </el-form-item>
      <el-form-item label="推广图展示" prop="promotionalPosterStatus">
        <el-switch v-model="updateForm.promotionalPosterStatus"></el-switch>
      </el-form-item>
      <el-form-item label="模板7展示默认按钮" prop="model7ShowDefault">
        <el-switch v-model="updateForm.model7ShowDefault"></el-switch>
      </el-form-item>
      <el-form-item label="首页模板" prop="activityModelType">
        <el-select
          v-model="updateForm.activityModelType"
          placeholder="请选择模板类型"
        >
          <el-option
            v-for="item in activityModelTypeList"
            :key="item.value"
            :label="item.label"
            :value="item.value + ''"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <!-- 底部按钮 -->
    <div class="buttomButton">
      <el-button type="primary" @click="tosave">确 定</el-button>
    </div>
  </div>
</template>
<script>
import {
  getConfiguration,
  updateConfiguration,
} from "@/services/thirdParty/smallRoutine/activity/configuration.js";
export default {
  props: ["showSingleId", "poiList", "showSingleMes"],
  name: "smallroutineConfiguration",
  watch: {
    "updateForm.jumpToH5Status"(e) {
      if (e) {
        this.$set(this.updateForm, "jumpToSmallRoutine", false);
      }
    },
    "updateForm.jumpToSmallRoutine"(e) {
      if (e) {
        let that = this;
        setTimeout(function () {
          that.$set(that.updateForm, "jumpToH5Status", false);
        }, 10);
      }
    },
  },
  data() {
    return {
      updateForm: {
        poiType: [],
      },
      showLoading: false,
      activityModelTypeList: [
        {
          label: "口令红包",
          value: "0",
        },
        {
          label: "模板1(摇一摇)",
          value: "1",
        },
        {
          label: "模板2(恭喜红包已到账)",
          value: "2",
        },
        {
          label: "模板3(新人红包)",
          value: "3",
        },
        {
          label: "模板4(摇一摇红包2)",
          value: "4",
        },
        {
          label: "模板5(抽奖)",
          value: "5",
        },
        {
          label: "模板6(租机模板)",
          value: "6",
        },
        {
          label: "模板7(宫格模板))",
          value: "7",
        },
        {
          label: "模板8(农场植物模板))",
          value: "8",
        },
      ],
      rules: {
        activityModelType: [
          { required: true, message: "请选择模板类型", trigger: "change" },
        ],
        jumpToH5: [
          { required: true, message: "请输入跳转的h5链接", trigger: "blur" },
        ],
        jumpToSmallRoutineAppId: [
          { required: true, message: "请输入跳转小程序appId", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    console.log(this.showSingleMes);
    this.init();
  },
  methods: {
    init() {
      this.getMes();
    },
    async getMes() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let res = await getConfiguration(this.showSingleId);
      loading.close();
      if (!res.success) {
        return this.$message.error(res.err);
      }
      this.updateForm = res.data;
    },
    tosave() {
      this.$refs["updateFormRed"].validate((valid) => {
        if (!valid) {
          return;
        }
        let updateForm = this.updateForm;
        if (updateForm.jumpToH5Status && this.isnull(updateForm.jumpToH5)) {
          return this.$message.error(
            "首页自动跳转H5开关开启时跳转的h5路径不能为空"
          );
        }
        if (
          updateForm.jumpToSmallRoutine &&
          this.isnull(updateForm.jumpToSmallRoutineAppId)
        ) {
          return this.$message.error(
            "首页自动跳转小程序开关开启时跳转的小程序appId不能为空"
          );
        }
        if (updateForm.jumpToSmallRoutine && updateForm.jumpToH5Status) {
          return this.$message.error("不能同时开启h5与小程序跳转");
        }
        this.save();
      });
    },
    async save() {
      let res = await updateConfiguration(this.updateForm);
      if (!res.success) {
        return this.$message.error(res.err);
      }
      this.$message.success("操作成功");
    },
    isnull(e) {
      return e == null || e == undefined || e == "";
    },
  },
};
</script>

<style lang="less" scoped></style>
