<template>
  <div>
    <el-form
      :model="updateForm"
      :rules="updateFormRules"
      ref="updateFormRed"
      label-width="auto"
      v-loading="showLoading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
     <!-- 用户名 -->
     <el-form-item label="用户名" prop="username">
        <el-input
          v-model="updateForm.username"
          placeholder="请输入用户名"
          maxlength="100"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
       <!-- 手机号 -->
       <el-form-item label="手机号" prop="mobile">
        <el-input
          v-model="updateForm.mobile"
          clearable
          maxlength="100"
          placeholder="请输入手机号"
        ></el-input>
      </el-form-item>
      <el-form-item label="人头像" prop="headImg">
        <el-upload
            class="avatar-uploader"
            list-type="picture-card"
            :auto-upload="false"
            action="#"
            :show-file-list="false"
            :on-change="imgChange"
          >
            <img class="div_main_img" v-if="updateForm.headImg" :src="updateForm.headImg" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
      </el-form-item>
      <!-- 性别 -->
      <el-form-item label="性别" prop="sex">
        <el-radio-group v-model="updateForm.sex" class="ml-2">
          <el-radio label="男" >男</el-radio>
          <el-radio label="女" >女</el-radio>
        </el-radio-group>
      </el-form-item>
        <!-- 邮箱 -->
        <el-form-item label="邮箱" prop="email">
        <el-input
          v-model="updateForm.email"
          clearable
          maxlength="100"
          placeholder="请输入邮箱"
        ></el-input>
      </el-form-item>
       <!-- 第三方ID -->
       <el-form-item label="第三方ID" prop="openId">
        <el-input
          v-model="updateForm.openId"
          clearable
          maxlength="100"
          placeholder="请输入第三方ID"
        ></el-input>
      </el-form-item>
      <el-form-item label="是否删除" prop="del">
        <el-switch v-model="updateForm.del"> </el-switch>
      </el-form-item>
      <!-- 状态 -->
      <el-form-item label="状态" prop="status">
        <el-switch v-model="updateForm.status"> </el-switch>
      </el-form-item>
    </el-form>
    <!-- 底部按钮 -->
    <div class="buttomButton">
      <el-button type="primary" @click="tosave">确 定</el-button>
    </div>
  </div>
</template>

<script>

export default {
  name: "usersUpdate",
  props: ["updateId", "refaushList"],
  data() {
    const checkEmail = (rule, value, cb) => {
      const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/
      if (regEmail.test(value)) {
        return cb()
      }
      cb(new Error('请输入正确的邮箱'))
    };
    const checkMobile = (rule, value, cb) => {
      const regMobile = /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/
      if (regMobile.test(value)) {
        return cb()
      }
      cb(new Error('请输入正确的手机号'))
    };
    return {
      file: {
        imgFile: null
      },
      // 编辑内容
      updateForm: {},
      // 表单验证
      updateFormRules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            min: 1,
            max: 64,
            message: "长度在 1 到 64 个字符",
            trigger: "blur",
          },
        ],
        mobile:[
        { required: true, validator: checkMobile, trigger: "blur" }
        ],
        email:[
        { required: true, validator: checkEmail, trigger: "blur" }
        ],
      },
      headerObj: { Authorization: window.sessionStorage.getItem("token") },
      showLoading: false, // 加载中
    };
  },
  created() {
    this.refaushForm(this.updateId);
  },
  methods: {
    // 点击提交
    tosave() {
      this.$refs["updateFormRed"].validate((valid) => {
        if (!valid) {
          return;
        }
        this.save();
      });
    },
    // 发起请求
    async save() {
        let fielddata={...this.updateForm};
        if(fielddata.status==true)
        {
          fielddata.status=1;
        }
        else
        {
          fielddata.status=0;
        }
        const { data: res } = await this.$http.post(
        "/users/update",
        {
          ...fielddata,
        }
      );
      if ("200" != res.code) {
        return this.$message.error(res.message);
      }
      this.$message.success("操作成功");
      this.refaushList();
    },
    // 刷新编辑对象
    async refaushForm(e) {
      // 根据id获取数据
      const { data: res } = await this.$http.get(
        "/users/getOne?id=" + e
      );
      if (res.code != 200) {
        return this.$message.error(res.message);
      }
      let message = res.message; 
      if(message.status==1) {
        message.status=true;
      }
      else
      {
        message.status=false;
      }
      this.updateForm = message;
    },
    isnull(e) {
      return e == null || e == undefined || e == "";
    },
    imgChange(e) {
      this.$set(this.updateForm, "headImg", e.url);
      this.$set(this.file, "imgFile", e.raw);
    },
    // input校验不能中文
    inputCheck(e) {
      if (/.*[\u4e00-\u9fa5]+.*$/.test(e)) {
        this.$message.error("小程序唯一码不能含有中文！");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.buttomButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
