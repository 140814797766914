<template>
  <div class="div_main">
    <el-card>
      <el-form :model="formMes" :rules="rules" ref="formRef" label-width="auto">
        <el-form-item label="客服电话" prop="servicePhone">
          <el-input
            clearable
            v-model="formMes.servicePhone"
            placeholder="请输入客服电话"
            maxlength="11"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="save">保 存</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { getMes, updateMes } from "@/services/basicInformation.js";
export default {
  data() {
    return {
      formMes: {
        servicePhone: "",
      },
      // 表单验证
      rules: {
        servicePhone: [
          { required: true, message: "请输入客服电话", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    // 初始化
    async init() {
      const res = await this.$publicJs.request(this, getMes);
      if (!res.success) {
        return;
      }
      this.$set(this, "formMes", res.data);
    },
    save() {
      this.$refs.formRef.validate(async (valid) => {
        if (!valid) return;
        let formMes = this.formMes;
        if (!this.chechPhone(formMes.servicePhone)) {
          return this.$message.warning("客服联系电话格式有误");
        }
        let res = await this.$publicJs.request(this, updateMes, formMes, true);
        if (!res.success) {
          return;
        }
      });
    },
    // 手机号校验
    chechPhone(phone) {
      var regExp = new RegExp("^1[3578]\\d{9}$");
      return regExp.test(phone);
    },
  },
};
</script>

<style lang="less" scoped>
.div_main_img {
  height: 100%;
  width: 100%;
}
</style>
