<template>
  <div>
    <!-- 菜单列表 -->
    <menuList
      v-show="showType == 1"
      :showList="showList"
      :showSingleMes="showSingleMes"
      :showServiceRel="showServiceRel"
      :showMessageTemplate="showMessageTemplate"
      @showMessageGaoDeTemplate="showMessageGaoDeTemplate"
    />
    <!-- 消息模板 -->
    <messageTemplate
      v-if="showType == 3"
      :appId="showSingleMes.appId"
      :appletId="showSingleMes.id"
      :showMenuList="showMenuList"
    />
    <!-- 高德临时服务 -->
    <gaoDeTemplate
      v-if="showType == 4"
      :appId="appId"
      :showMenuList="showMenuList"
    />
  </div>
</template>

<script>
import menuList from "./menuList.vue";
import messageTemplate from "@/components/thirdParty/smallRoutine/applet1/childrenPage/messageTemplate.vue";
import gaoDeTemplate from "../applet1/childrenPage/gaoDeTemplate/gaoDeTemplate.vue";
export default {
  name: "index",
  props: ["showList", "showSingleMes"],
  components: {
    menuList,
    messageTemplate,
    gaoDeTemplate,
  },
  data() {
    return {
      showType: 1,
      appId: "",
    };
  },
  methods: {
    showMenuList() {
      this.showType = 1;
    },
    showServiceRel() {
      this.showType = 2;
    },
    showMessageTemplate() {
      this.showType = 3;
    },
    showMessageGaoDeTemplate(e) {
      if (e == 1) {
        // 单控
        this.$set(this, "appId", this.showSingleMes.appId);
      } else {
        // 默认
        this.$set(this, "appId", "model7Default");
      }
      this.$set(this, "showType", 4);
    },
  },
};
</script>

<style lang="scss" scoped></style>
