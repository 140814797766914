<template>
  <div>
    <el-form
      :model="updateForm"
      :rules="updateFormRules"
      ref="updateFormRed"
      label-width="auto"
      v-loading="showLoading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
        <!-- 选择用户 -->
      <el-form-item label="选择用户" prop="userId">
       <el-select v-model="updateForm.userId" placeholder="请选择用户">
         <el-option  v-for="item in userlist" :key="item.id" :label="item.username" :value="item.id"></el-option>
       </el-select>
      </el-form-item>
        <!-- 产品名称 -->
        <el-form-item label="产品名称" prop="productName">
        <el-input
         type="text"
          v-model="updateForm.productName"
          placeholder="请输入产品名称"
          clearable
        ></el-input>
      </el-form-item>
        <!-- 联系姓名 -->
        <el-form-item label="订单金额" prop="totalAmount">
        <el-input
         type="text"
          v-model="updateForm.totalAmount"
          placeholder="请输入订单金额"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="支付方式" prop="paymentMethod">
       <el-select  v-model="updateForm.paymentMethod"  placeholder="请选择支付方式">
        <el-option  v-for="item in paymentMethodList" :key="item.value" :label="item.title" :value="item.value"></el-option>
       </el-select>
      </el-form-item>
      <!-- 下单状态 -->
      <el-form-item label="状态" prop="orderStatus">
        <el-select v-model="updateForm.orderStatus" placeholder="请选择状态">
         <el-option  v-for="item in statuslist" :key="item.id" :label="item.title" :value="item.id"></el-option>
       </el-select>
      </el-form-item>
        <!-- 收件地址 -->
        <el-form-item label="收件地址" prop="shippingAddressId">
        <el-select v-model="updateForm.shippingAddressId" placeholder="请选择收件地址">
         <el-option  v-for="item in addresslist" :key="item.id" :label="item.contactName" :value="item.id"></el-option>
       </el-select>
       </el-form-item>
    </el-form>
    <!-- 底部按钮 -->
    <div class="buttomButton">
      <el-button type="primary" @click="tosave">确 定</el-button>
    </div>
  </div>
</template>

<script>

export default {
  name: "Update",
  props: ["updateId", "refaushList"],
  data() {
    return {
      userlist:[], 
      statuslist:[
      {id:0,title:"新订单"},
          {id:1,title:"待处理"},
          {id:2,title:"处理中"},
          {id:3,title:"已发货"},
          {id:4,title:"已送达"},
          {id:5,title:"已取消"},
          {id:6,title:"退回"}
        ],
        paymentMethodList:[
          {value:"alipay",title:"支付宝"},
          {value:"wechat",title:"微信"}
        ],  
        addresslist:[],  
      // 编辑内容
      updateForm: {

      },
      // 表单验证
      updateFormRules: {
        userId: [
          { required: true, message: "请选择用户", trigger: "blur"}          
        ],
        productName:[
          { required: true, message: "请填写产品名称", trigger: "blur"}
        ],
        totalAmount:[
          { required: true, message: "请填写订单金额", trigger: "blur"}
        ],       
        paymentMethod:[
          { required: true, message: "请填选择支付方式", trigger: "blur"}
        ],
        orderStatus:[
          { required: true, message: "请填选择状态", trigger: "blur"}
        ],
        shippingAddressId:[
        { required: true, message: "请填选择收货地址", trigger: "blur"}
        ]
      },
      headerObj: { Authorization: window.sessionStorage.getItem("token") },
      showLoading: false, // 加载中
    };
  },
  created() {
    this.selectuserlist();
    this.selectusershippingaddresslist();
    this.refaushForm(this.updateId);
  },
  methods: {
    async selectuserlist()
    {
        const { data: res } = await this.$http.post(
          "/users/getListAll",
          null
        );
        if ("200" != res.code) {
          return this.$message.error(res.message);
        }
        this.userlist=res.message;
    },
    async selectusershippingaddresslist()
    {
        const { data: res } = await this.$http.post(
          "/usershippingaddresses/getList",
          {
            productName: "",
            sortBy:"",
            isAsc:false,
            pageNo: 1, //当前页
            pageSize: 20, //每页最大数
            smallCodeList: [], //查询的小程序唯一标识列表
          }
        );
        this.addresslist=res.list;
    },
    // 点击提交
    tosave() {
      this.$refs["updateFormRed"].validate((valid) => {
        if (!valid) {
          return;
        }
        this.save();
      });
    },
    // 发起请求
    async save() {
        let fielddata={...this.updateForm};        
        const { data: res } = await this.$http.post(
        "/orders/update",
        {
          ...fielddata,
        }
      );
      if ("200" != res.code) {
        return this.$message.error(res.message);
      }
      this.$message.success("操作成功");
      this.refaushList();
    },
    // 刷新编辑对象
    async refaushForm(e) {
      // 根据id获取数据
      const { data: res } = await this.$http.get(
        "/orders/getOne?id=" + e
      );
      if (res.code != 200) {
        return this.$message.error(res.message);
      }
      let message = res.message; 
      this.updateForm = message;
    },
    formatDate(cellValue) {
      const date = new Date(cellValue);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      // 将月份和日期补齐两位数
      month = month < 10 ? '0' + month : month;
      day = day < 10 ? '0' + day : day;
      
      return year + '-' + month + '-' + day;
    },
    formatDatetime(cellValue) {
      const date = new Date(cellValue);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let hours = date.getHours();
      let minu=date.getMinutes();
      let sec=date.getSeconds();
      // 将月份和日期补齐两位数
      month = month < 10 ? '0' + month : month;
      day = day < 10 ? '0' + day : day;
      hours = hours < 10 ? '0' + hours :hours;
      minu = minu < 10 ? '0' + minu :minu;
      sec = sec < 10 ? '0' + sec :sec;

      return year + '-' + month + '-' + day+' '+hours+':'+minu+':'+sec;
    },
    isnull(e) {
      return e == null || e == undefined || e == "";
    },
    // input校验不能中文
    inputCheck(e) {
      if (/.*[\u4e00-\u9fa5]+.*$/.test(e)) {
        this.$message.error("小程序唯一码不能含有中文！");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.buttomButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
