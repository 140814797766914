<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "app",
  computed: {
    themeColor() {
      return this.$store.state.themeColor;
    },
  },
  watch: {
    themeColor(val) {
      document.getElementById("app").style.setProperty("--themeColor", val);
    },
  },
  created() {
    // 首页标题
    let httpUrl = this.$httpUrl;
    if (httpUrl.indexOf("www.qmw-data.top") != -1) {
      document.title = "钱满屋金融";
    } else if (httpUrl.indexOf("www.qmw-data.com") != -1) {
      document.title = "钱满屋小程序";
    }
  },
};
</script>

<style lang="less">
#app {
  --themeColor: #fff;
  // // 文字不可选
  // -webkit-touch-callout: none;
  // -webkit-user-select: none;
  // -khtml-user-select: none;
  // -moz-user-select: none;
  // -ms-user-select: none;
  // user-select: none;
}
// 弹出框标题居中
.el-dialog__header {
  text-align: center;
}
// 内容超出两行隐藏
.div_line_2 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
