<template>
  <div>
    <el-form
      :model="updateForm"
      :rules="updateFormRules"
      ref="updateFormRed"
      label-width="auto"
      v-loading="showLoading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <el-form-item label="模板名称" prop="name">
        <el-input
          v-model="updateForm.name"
          maxlength="50"
          show-word-limit
          placeholder="请输入模板名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="模板appId" prop="appId">
        <el-input
          v-model="updateForm.appId"
          maxlength="50"
          show-word-limit
          placeholder="请输入模板appId"
        ></el-input>
      </el-form-item>
      <el-form-item label="授权token" prop="appAuthToken">
        <el-input
          v-model="updateForm.appAuthToken"
          maxlength="50"
          show-word-limit
          placeholder="请输入授权token"
        ></el-input>
      </el-form-item>
      <el-form-item label="第三方应用" prop="applicationsId">
        <el-select
          v-model="updateForm.applicationsId"
          placeholder="请选择关联的第三方应用"
        >
          <el-option
            v-for="item in applications"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="模板类型" prop="appletType">
        <el-select
          v-model="updateForm.appletType"
          placeholder="请选择小程序类型"
        >
          <el-option
            v-for="item in smallRoutineTypes"
            :key="item.id"
            :label="item.name"
            :value="item.code"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <category
        v-if="!isNull(updateForm.id)"
        ref="categoryRef"
        :applicationsId="updateForm.applicationsId"
        :chooseMes="updateForm.category"
        :formMes="updateForm"
        :id="id"
      />
      <el-form-item label="线下授权链接" prop="offlineLink">
        <el-input
          type="textarea"
          class="div_input_single"
          v-model="updateForm.offlineLink"
          placeholder="请输入线下授权链接(用于生成推广链接)复制到{标识符}前即可，要保留‘=’号"
          :rows="3"
          maxlength="500"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
      <!-- 备注 -->
      <el-form-item label="备注" prop="remark">
        <el-input
          v-model="updateForm.remark"
          placeholder="请输入备注"
          clearable
          maxlength="150"
          show-word-limit
          type="textarea"
          :rows="2"
        ></el-input>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-switch v-model="updateForm.status"></el-switch>
      </el-form-item>
      <el-divider content-position="left">
        模板关联的小程序提交审核时的默认填充内容
      </el-divider>
      <el-form-item label="应用简介" prop="appSlogan">
        <el-input
          class="div_input_single"
          v-model="updateForm.appSlogan"
          placeholder="请输入小程序应用简介"
          maxlength="32"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="小程序描述" prop="appDesc">
        <el-input
          type="textarea"
          class="div_input_single"
          v-model="updateForm.appDesc"
          placeholder="请输入版本描述"
          :rows="3"
          maxlength="200"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="版本描述" prop="versionDesc">
        <el-input
          type="textarea"
          class="div_input_single"
          v-model="updateForm.versionDesc"
          placeholder="请输入版本描述"
          :rows="3"
          maxlength="500"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
    </el-form>
    <!-- 底部按钮 -->
    <div class="buttomButton">
      <el-button type="primary" @click="tosave">确 定</el-button>
    </div>
  </div>
</template>

<script>
import category from "../category/category.vue";
export default {
  name: "update",
  components: {
    category,
  },
  props: ["id", "refreshData", "applications", "visible", "smallRoutineTypes"],
  data() {
    return {
      // 编辑内容
      updateForm: {
        category: "",
      },
      // 表单验证
      updateFormRules: {
        name: [{ required: true, message: "请输入模板名称", trigger: "blur" }],
        appId: [
          { required: true, message: "请输入模板appId", trigger: "blur" },
        ],
        appAuthToken: [
          { required: true, message: "请输入授权token", trigger: "blur" },
        ],
        applicationsId: [
          {
            required: true,
            message: "请选择关联的第三方应用",
            trigger: "change",
          },
        ],
        appletType: [
          {
            required: true,
            message: "请选择模板类型",
            trigger: "change",
          },
        ],
      },
      headerObj: { Authorization: window.sessionStorage.getItem("token") },
      showLoading: false, // 加载中
    };
  },
  watch: {
    visible(e) {
      if (e) {
        this.refaushForm(this.id);
      }
    },
  },
  created() {
    this.refaushForm(this.id);
  },
  methods: {
    // 点击提交
    tosave() {
      this.$refs["updateFormRed"].validate((valid) => {
        if (!valid) {
          return;
        }
        this.save();
      });
    },
    // 发起请求
    async save() {
      let form = this.updateForm;
      let offlineLink = form.offlineLink;
      if (!this.isNull(offlineLink)) {
        let split = offlineLink.split("&ticket=");
        if ((split.length1 = 2 && split[1] != "")) {
          return this.$message.warning(
            "线下授权格式校验失败,请以“&ticket=”结尾"
          );
        }
      }
      let category = this.$refs.categoryRef.getChooseCategoryMes();
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const { data: res } = await this.$http.put("/thirdPartyTemplate/update", {
        ...form,
        category: category,
      });
      loading.close();
      if ("200" != res.code) {
        return this.$message.error(res.message);
      }
      this.$message.success("操作成功");
      this.refreshData();
    },
    // 刷新编辑对象
    async refaushForm(e) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      // 根据id获取数据
      const { data: res } = await this.$http.get(
        "/thirdPartyTemplate/getUpdateMesById?id=" + e
      );
      loading.close();
      let message = res.message;
      if (res.code != 200) {
        return this.$message.error(message);
      }
      this.updateForm = message;
    },
    isNull(e) {
      return e == null || e == undefined || e == "";
    },
    // input校验不能中文
    inputCheck(e) {
      if (/.*[\u4e00-\u9fa5]+.*$/.test(e)) {
        this.$message.error("小程序唯一码不能含有中文！");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.buttomButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
