<template>
  <!-- 引入布局 -->
  <el-container>
    <el-header>
      <div class="home_header">
        <img class="home_img" src="../assets/logo.png" alt />
        <span class="home_title">管理平台</span>
      </div>
      <div>
        欢迎：{{ userName }}
        <el-button type="info" @click="loginOut">安全退出</el-button>
      </div>
    </el-header>
    <el-container class="home_main">
      <el-aside width="220px">
        <el-menu
          class="el-menu-vertical-demo"
          background-color="#545c64"
          text-color="#fff"
          active-text-color="#ffd04b"
          unique-opened
          :collapse="isCollapase"
          :collapse-transition="false"
          :router="true"
          :default-active="activePath"
        >
          <!-- 一级菜单 -->
          <el-submenu
            :index="item.id + ''"
            v-for="item in menuList"
            :key="item.id"
          >
            <template slot="title" @click="saveNavstate(item.path)">
              <i :class="item.icon ? 'iconfont ' + item.icon : ''"></i>
              <span class="menu_one">{{ item.name }}</span>
            </template>
            <!-- 二级菜单 -->
            <template v-if="item.childrenList.length > 0">
              <label
                v-for="item1 in item.childrenList"
                :key="'menu2_' + item1.id"
              >
                <!-- 没有下级二级菜单 -->
                <template v-if="item1.childrenList.length == 0">
                  <el-menu-item
                    :index="item1.path"
                    @click="saveNavstate(item1.path)"
                  >
                    <span>{{ item1.name }}</span>
                  </el-menu-item>
                </template>
                <!-- 有下级二级菜单 -->
                <el-submenu v-else :index="item1.id + ''">
                  <template slot="title">
                    {{ item1.name }}
                  </template>
                  <el-menu-item
                    v-for="item2 in item1.childrenList"
                    :key="'menu3_' + item2.id"
                    :index="item2.path"
                    @click="saveNavstate(item2.path)"
                  >
                    {{ item2.name }}
                  </el-menu-item>
                </el-submenu>
              </label>
            </template>
          </el-submenu>
        </el-menu>
      </el-aside>
      <el-main> <router-view></router-view></el-main>
    </el-container>
  </el-container>
</template>
<script>
export default {
  data() {
    return {
      menuList: [],
      isCollapase: false,
      activePath: "/welcome", //默认访问页面
      userName: "",
    };
  },
  //onLoad事件
  created() {
    this.userName = window.sessionStorage.getItem("userName");
    this.getMenuList();
    this.activePath = window.sessionStorage.getItem("activePath"); //取出session的path
  },
  methods: {
    loginOut() {
      window.sessionStorage.clear(); //清除session
      this.$router.push({ path: "/login" }); //返回登录页
    },
    //获取导航菜单方法
    async getMenuList() {
      const { data: res } = await this.$http.get("menu/getMenuTree");
      if (res.code != "200") {
        return this.$message.error(res.message);
      }
      if (0 == res.message.length) {
        this.$message.warning("当前账号暂无授权菜单");
      } else {
        this.menuList = res.message;
      }
    },
    //控制菜单伸缩
    toggleCollapase() {
      this.isCollapase = !this.isCollapase;
    },
    //保存菜单点击的路径
    saveNavstate(e) {
      window.sessionStorage.setItem("activePath", e); //保存最后一次点击的菜单路径
      this.activePath = e; //主题内容跳转页面
    },
  },
};
</script>
<style lang="less" scoped>
// 顶部样式
.el-header,
.el-footer {
  background-color: #373d41;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 0;
  color: #fff;
  font-size: 20px;
  line-height: 60px;
  .home_header {
    display: flex;
    align-items: center;
    padding: 15px;
    .home_img {
      width: 55px;
      height: 55px;
      border-radius: 0;
    }
    .home_title {
      margin-left: 15px;
    }
  }
}
// 主体内容
.home_main {
  height: calc(100vh - 60px);
  // 左侧菜单
  .el-menu {
    border-right: none !important;
  }
  .el-aside {
    background-color: #333744;
    // color: #333;
    // text-align: center;
    line-height: 200px;
  }
  // 右侧内容
  .el-main {
    background-color: #e9eef3;
    color: #333;
    // text-align: center;
  }
  // 弹出框标题居中
  .el-dialog__header {
    text-align: center;
  }
  .is-active .menu_one {
    color: rgb(255, 208, 75);
  }
  .menu_one {
    font-size: 17px;
  }
}
</style>
