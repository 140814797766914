// 接口请求地址
const path = "massUpdate/";
import axios from "axios";

/**
 * 批量更新
 */
export async function massUpdate(data = {}) {
  const { data: res } = await axios.post(path + "massUpdate", data);
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: "列表查询失败:" + res.message,
    };
  }
  return {
    success: true,
    data: message,
  };
}

/**
 * 列表查询
 * @param {*} params
 */
export async function pageSelect(data = {}) {
  const { data: res } = await axios.get(path + "pageSelect", {
    params: data,
  });
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: "列表查询失败:" + res.message,
    };
  }
  return {
    success: true,
    data: message,
  };
}

/**
 * 删除记录
 * @param {*} params
 */
export async function del(id) {
  const { data: res } = await axios.delete(path + "del", {
    params: {
      id: id,
    },
  });
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: "列表查询失败:" + res.message,
    };
  }
  return {
    success: true,
    data: message,
  };
}

/**
 * 查看详情
 * @param {*} params
 */
export async function getDetailById(id) {
  const { data: res } = await axios.get(path + "getDetailById", {
    params: {
      id: id,
    },
  });
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: "获取详情失败:" + res.message,
    };
  }
  return {
    success: true,
    data: message,
  };
}

/**
 * 查看最后一次批量更新小程序版本号结果
 * @param {*} params
 */
export async function getMassUpdateVersionLaseMes() {
  const { data: res } = await axios.get(path + "getMassUpdateVersionLaseMes");
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: "查看最后一次批量更新小程序版本号结果失败:" + res.message,
    };
  }
  return {
    success: true,
    data: message,
  };
}

/**
 * 批量更新小程序版本号
 * @param {*} params
 */
export async function massUpdateVersion(data = {}) {
  const { data: res } = await axios.post(path + "massUpdateVersion", data);
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: "批量更新小程序版本号失败:" + res.message,
    };
  }
  return {
    success: true,
    data: message,
  };
}
