<template>
  <div>
    <el-form
      :model="updateForm"
      ref="updateFormRed"
      :rules="rules"
      label-width="auto"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <el-divider content-position="left">首页自动跳转</el-divider>
      <el-form-item label="跳转h5" prop="jumpToH5Status">
        <el-switch v-model="updateForm.jumpToH5Status"> </el-switch>
      </el-form-item>
      <template v-if="updateForm.jumpToH5Status">
        <el-form-item label="h5链接" prop="jumpToH5">
          <el-input
            v-model="updateForm.jumpToH5"
            placeholder="请输入跳转的h5链接"
            clearable
          ></el-input>
        </el-form-item>
      </template>
      <!-- 是否开启 -->
      <el-form-item label="跳转小程序" prop="jumpToSmallRoutine">
        <el-switch v-model="updateForm.jumpToSmallRoutine"> </el-switch>
      </el-form-item>
      <template v-if="updateForm.jumpToSmallRoutine">
        <el-form-item label="跳转小程序" prop="jumpToSmallRoutineAppId">
          <el-input
            v-model="updateForm.jumpToSmallRoutineAppId"
            placeholder="请输入跳转小程序appId"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="跳转小程序页面" prop="jumpToSmallRoutinePath">
          <el-input
            v-model="updateForm.jumpToSmallRoutinePath"
            placeholder="请输入跳转小程序页面路径"
            clearable
          ></el-input>
        </el-form-item>
      </template>
      <el-divider content-position="left">其他功能</el-divider>
      <el-form-item label="遮罩层" prop="showMask">
        <el-switch v-model="updateForm.showMask"></el-switch>
      </el-form-item>
      <el-divider content-position="left">选择小程序</el-divider>
      <el-transfer
        style="text-align: left; display: inline-block"
        v-model="smallRoutineList.chooseList"
        filterable
        :left-default-checked="[2, 3]"
        :right-default-checked="[1]"
        :titles="['未选中', '已选中']"
        :button-texts="['取消', '添加']"
        :format="{
          noChecked: '${total}',
          hasChecked: '${checked}/${total}',
        }"
        @change="handleChange"
        :data="smallRoutineList.list"
      >
      </el-transfer>
    </el-form>
    <!-- 底部按钮 -->
    <div class="buttomButton">
      <el-button type="primary" @click="tosave">确 定</el-button>
    </div>
  </div>
</template>

<script>
import { listAllActivitySmallRoutine } from "@/services/thirdParty/smallRoutine/smallRoutine.js";
import { updateConfigurationActivityList } from "@/services/thirdParty/smallRoutine/activity/configuration.js";
export default {
  name: "batchManagement",
  data() {
    return {
      smallRoutineList: {
        list: [],
        chooseList: [],
      },
      chooseSmallRoutineVisible: false,
      updateForm: {},
      rules: {
        activityModelType: [
          { required: true, message: "请选择模板类型", trigger: "change" },
        ],
        jumpToH5: [
          { required: true, message: "请输入跳转的h5链接", trigger: "blur" },
        ],
        jumpToSmallRoutineAppId: [
          { required: true, message: "请输入跳转小程序appId", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.init();
  },

  methods: {
    async init() {
      const res = await this.$publicJs.request(
        this,
        listAllActivitySmallRoutine
      );
      if (!res.success) {
        return;
      }
      this.$set(this.smallRoutineList, "list", res.data);
      console.log(res);
    },
    async tosave() {
      let updateForm = this.updateForm;
      let smallRoutineIdList = this.smallRoutineList.chooseList;
      const res = await this.$publicJs.request(
        this,
        updateConfigurationActivityList,
        {
          ...updateForm,
          smallRoutineIdList: smallRoutineIdList,
        }
      );
      if (!res.success) {
        return;
      }
      this.$message.success("操作成功");
      console.log(res);
    },
    handleChange(e) {
      this.$set(this.smallRoutineList, "chooseList", e);
    },
  },
};
</script>

<style lang="less" scoped>
.buttomButton {
  margin-top: 10px;
}
</style>
