<template>
  <div class="div_home">
    <el-card>
      <el-form
        class="viewForm"
        :model="configuration"
        :rules="addFormRules"
        ref="addFormRed"
        label-width="auto"
      >
        <el-divider content-position="left">云码配置</el-divider>
        <!-- key -->
        <el-form-item label="key" prop="accessKeyId">
          <el-input
            v-model="configuration.accessKeyId"
            placeholder="key"
            maxlength="100"
            show-word-limit
            clearable
          ></el-input>
        </el-form-item>
        <!-- secret -->
        <el-form-item label="secret" prop="accessKeySecret">
          <el-input
            v-model="configuration.accessKeySecret"
            placeholder="secret"
            maxlength="100"
            show-word-limit
            clearable
          ></el-input>
        </el-form-item>
        <!-- 广告位id -->
        <el-form-item label="广告位id" prop="tagId">
          <el-input
            v-model="configuration.tagId"
            placeholder="tagId"
            maxlength="100"
            show-word-limit
            clearable
          ></el-input>
        </el-form-item>
        <!-- 媒体id -->
        <el-form-item label="媒体id" prop="mediaId">
          <el-input
            v-model="configuration.mediaId"
            placeholder="mediaId"
            maxlength="100"
            show-word-limit
            clearable
          ></el-input>
        </el-form-item>

        <el-divider content-position="left">活动小程序</el-divider>
        <!-- key -->
        <el-form-item label="自定义任务展示数" prop="numberOfCustomTasks">
          <el-input
            v-model="configuration.numberOfCustomTasks"
            placeholder="设置小程序首页自定义任务展示数（默认5个）"
            maxlength="2"
            show-word-limit
            clearable
          ></el-input>
        </el-form-item>
        <el-divider content-position="left">后台配置</el-divider>
        <!-- key -->
        <el-form-item label="后台路径" prop="baseUrl">
          <el-input
            v-model="configuration.baseUrl"
            placeholder="后台访问地址(http或https要带上)"
            maxlength="200"
            show-word-limit
            clearable
          ></el-input>
        </el-form-item>
        <el-divider content-position="left">转账配置</el-divider>
        <!-- appId -->
        <el-form-item label="转账appId">
          <el-input
            placeholder="发起转账的appId,涉及转账业务"
            v-model="configuration.certificateAppId"
            maxlength="100"
            show-word-limit
            clearable
          >
          </el-input>
        </el-form-item>
        <!-- csr证书密钥 -->
        <el-form-item label="csr证书密钥">
          <el-input
            type="textarea"
            :rows="4"
            placeholder="涉及转账业务请输入证书密钥(需要使用csr证书)"
            v-model="configuration.certificatePrivateKey"
            clearable
          >
          </el-input>
        </el-form-item>
        <!-- 应用公钥证书 -->
        <!-- <el-form-item label="应用公钥证书">
          <el-upload
            class="upload-demo"
            :action="$baseURL + 'upload/uploadFile'"
            :before-upload="beforeAvatarUpload"
            :on-progress="handleAvatarProgress1"
            multiple
            :headers="headerObj"
            :file-list="uploadFile.list1"
            ref="upload1"
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item> -->
        <!-- 支付宝公钥证书 -->
        <!-- 支付宝根证书 -->
        <!-- 每日限额 -->
        <el-form-item label="每日限额">
          <el-input
            v-model="configuration.certificateMaxQuota"
            placeholder="单个用户每日领取上限最大额度,放空则不设置上限,最多保存小数点后两位(如:10、10.00)"
            maxlength="7"
            show-word-limit
            clearable
          ></el-input>
        </el-form-item>
        <!-- 转账业务标题 -->
        <el-form-item label="转账业务标题">
          <el-input
            v-model="configuration.certificateOrderTitle"
            placeholder="转账业务的标题，用于在支付宝用户的账单里显示(不能包含敏感字符,转账时会校验)"
            maxlength="100"
            show-word-limit
            clearable
          ></el-input>
        </el-form-item>
        <!-- 业务备注 -->
        <el-form-item label="业务备注">
          <el-input
            v-model="configuration.certificateRemark"
            placeholder="业务备注(不能包含敏感字符,转账时会校验)"
            maxlength="100"
            show-word-limit
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="add">保 存</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      headerObj: {}, // token
      configuration: {
        // 云码配置
        accessKeyId: "",
        accessKeySecret: "",
        tagId: "",
        mediaId: "",
        // 后台配置
        baseUrl: "",
        // 转账配置
        certificateAppId: "",
        certificatePrivateKey: "",
        certificateMaxQuota: "",
        certificateOrderTitle: "",
        certificateRemark: "",
      },
      // 新增表单验证
      addFormRules: {
        accessKeyId: [
          { required: true, message: "请输入key", trigger: "blur" },
          {
            min: 1,
            max: 100,
            message: "长度在 1 到 100 个字符",
            trigger: "blur",
          },
        ],
        accessKeySecret: [
          { required: true, message: "请输入密钥", trigger: "blur" },
          {
            min: 1,
            max: 100,
            message: "长度在 1 到 100 个字符",
            trigger: "blur",
          },
        ],
        tagId: [
          { required: true, message: "请输入广告位id", trigger: "blur" },
          {
            min: 1,
            max: 100,
            message: "长度在 1 到 100 个字符",
            trigger: "blur",
          },
        ],
        mediaId: [
          { required: true, message: "请输入媒体id", trigger: "blur" },
          {
            min: 1,
            max: 100,
            message: "长度在 1 到 100 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.headerObj = {
      Authorization: window.sessionStorage.getItem("token"),
    };
    this.init();
  },
  methods: {
    async init() {
      const { data: res } = await this.$http.get(
        "yunMaConfiguration/getConfiguration"
      );
      let message = res.message;
      if ("200" != res.code) {
        return this.$message.error(message);
      }
      this.configuration = message;
    },
    add() {
      this.$refs.addFormRed.validate(async (valid) => {
        if (!valid) return;
        let configuration = this.configuration;
        // 活动小程序首页展示自定义任务数
        let numberOfCustomTasks = configuration.numberOfCustomTasks;
        if (
          !this.isNull(numberOfCustomTasks) &&
          !/(^[0-9]\d*$)/.test(numberOfCustomTasks)
        ) {
          return this.$message.error(
            "活动小程序首页展示自定义任务数格式有误，只能是正整数"
          );
        }
        let maxQuota = configuration.certificateMaxQuota;
        if (null != maxQuota && undefined != maxQuota && "" != maxQuota) {
          if (!this.moneyCheck(configuration.certificateMaxQuota)) {
            return this.$message.warning("每日限额格式有误");
          }
        }
        //发起请求
        const { data: res } = await this.$http.post(
          "yunMaConfiguration/updateConfiguration",
          configuration
        );
        if ("200" != res.code) {
          return this.$message.error(res.message);
        }
        this.$message.success("修改成功");
      });
    },
    moneyCheck(money) {
      var reg =
        /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/;
      if (reg.test(money)) {
        return true;
      } else {
        return false;
      }
    },
    isNull(e) {
      return e == null || e == undefined || e == "";
    },
  },
};
</script>

<style lang="less" scoped></style>
