<template>
  <div class="div_home">
    <el-card>
      <div class="div_home_title">
        <el-row :gutter="25">
          <!-- 搜索区域 -->
          <!-- 搜索添加 -->
          <div class="head_input">
            <el-button
              class="headButtonSingle"
              type="primary"
              @click="toList()"
              icon="el-icon-arrow-left"
            >
              返回
            </el-button>
          </div>
        </el-row>
      </div>
      <!-- 按钮模块 -->
      <div class="div_button div_modle">
        <!-- <div class="div_title">执行按钮</div> -->
        <div class="div_button_modle">
          <div class="div_button_modle_title">构建小程序版本</div>
          <el-button
            class="buttonSingle"
            :type="chooseButtonMes == 'upload' ? 'success' : 'primary'"
            @click="chooseMes('upload')"
          >
            上传版本
          </el-button>
          <!-- <el-button
            class="buttonSingle"
            :type="chooseButtonMes == 'listQuery' ? 'success' : 'primary'"
            @click="chooseMes('listQuery')"
          >
            版本列表查询
          </el-button> -->
          <el-button
            class="buttonSingle"
            :type="chooseButtonMes == 'buildQuery' ? 'success' : 'primary'"
            @click="chooseMes('buildQuery')"
          >
            查询版本构建状态
          </el-button>
          <el-button
            class="buttonSingle"
            :type="chooseButtonMes == 'versionDelete' ? 'success' : 'primary'"
            @click="chooseMes('versionDelete')"
          >
            删除商家小程序版本
          </el-button>
          <el-button
            class="buttonSingle"
            :type="
              chooseButtonMes == 'experienceCreate' ? 'success' : 'primary'
            "
            @click="chooseMes('experienceCreate')"
          >
            生成商家小程序体验版
          </el-button>
          <el-button
            class="buttonSingle"
            :type="chooseButtonMes == 'experienceQuery' ? 'success' : 'primary'"
            @click="chooseMes('experienceQuery')"
          >
            小程序体验版状态查询接口
          </el-button>
          <el-button
            class="buttonSingle"
            :type="
              chooseButtonMes == 'experienceCancel' ? 'success' : 'primary'
            "
            @click="chooseMes('experienceCancel')"
          >
            取消体验版
          </el-button>
          <el-button
            class="buttonSingle"
            :type="
              chooseButtonMes == 'appMembersCreate' ? 'success' : 'primary'
            "
            @click="chooseMes('appMembersCreate')"
          >
            添加开发者或体验者
          </el-button>
        </div>
        <div class="div_button_modle">
          <div class="div_button_modle_title">提交审核小程序</div>
          <el-button
            class="buttonSingle"
            :type="chooseButtonMes == 'auditApply' ? 'success' : 'primary'"
            @click="chooseMes('auditApply')"
          >
            提交审核
          </el-button>
          <el-button
            class="buttonSingle"
            :type="chooseButtonMes == 'auditCancel' ? 'success' : 'primary'"
            @click="chooseMes('auditCancel')"
          >
            撤销审核
          </el-button>
          <el-button
            class="buttonSingle"
            :type="chooseButtonMes == 'auditedCancel' ? 'success' : 'primary'"
            @click="chooseMes('auditedCancel')"
          >
            退回开发
          </el-button>
        </div>
        <div class="div_button_modle">
          <div class="div_button_modle_title">上架商家小程序</div>
          <el-button
            class="buttonSingle"
            :type="chooseButtonMes == 'online' ? 'success' : 'primary'"
            @click="chooseMes('online')"
          >
            上架
          </el-button>
          <el-button
            class="buttonSingle"
            :type="chooseButtonMes == 'offline' ? 'success' : 'primary'"
            @click="chooseMes('offline')"
          >
            下架
          </el-button>
          <el-button
            class="buttonSingle"
            :type="chooseButtonMes == 'rollback' ? 'success' : 'primary'"
            @click="chooseMes('rollback')"
          >
            回滚
          </el-button>
        </div>
        <div class="div_button_modle">
          <div class="div_button_modle_title">其他</div>
          <el-button
            class="buttonSingle"
            :type="chooseButtonMes == 'baseInfoModify' ? 'success' : 'primary'"
            @click="chooseMes('baseInfoModify')"
          >
            修改基础信息
          </el-button>
        </div>
      </div>
      <!-- 输入模块 -->
      <div class="div_input div_modle">
        <div class="div_title">输入模块</div>
        <el-form
          :model="postParameter"
          :rules="formMesRules"
          ref="formMesRef"
          label-width="auto"
        >
          <!-- 模板版本号 -->
          <el-form-item label="模板名称:" prop="">
            {{ templateMes.name }}
          </el-form-item>
          <el-form-item label="小程序名称:" prop="">
            {{ templateMes.smallRoutineName }}
          </el-form-item>
          <el-form-item
            label="模板版本号"
            prop="templateVersion"
            v-if="chooseButtonMes.match('upload')"
          >
            <el-select
              filterable
              v-model="postParameter.templateVersion"
              placeholder="请选择模板版本号"
            >
              <el-option
                v-for="item in templateMes.appVersionInfos"
                :key="item.app_version"
                :label="item.app_version + '(' + item.version_status_text + ')'"
                :value="item.app_version"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 商家小程序版本号(手动输入) -->
          <el-form-item
            label="商家小程序版本号"
            prop="appVersion"
            v-if="chooseButtonMes.match('upload')"
          >
            <el-input
              class="div_input_single"
              v-model="postParameter.appVersion"
              placeholder="请输入商家小程序版本号,例:0.0.1"
              maxlength="100"
              show-word-limit
              clearable
            ></el-input>
          </el-form-item>
          <!-- 商家小程序版本号(接口返回列表) -->
          <el-form-item
            label="商家小程序版本号"
            prop="appVersion"
            v-if="
              chooseButtonMes.match('buildQuery') ||
              chooseButtonMes.match('listQuery') ||
              chooseButtonMes.match('versionDelete') ||
              chooseButtonMes.match('experienceCreate') ||
              chooseButtonMes.match('experienceQuery') ||
              chooseButtonMes.match('experienceCancel') ||
              chooseButtonMes.match('auditApply') ||
              chooseButtonMes.match('auditCancel') ||
              chooseButtonMes.match('auditedCancel') ||
              chooseButtonMes.match('online') ||
              chooseButtonMes.match('offline') ||
              chooseButtonMes.match('rollback')
            "
          >
            <el-select
              filterable
              v-model="postParameter.appVersion"
              clearable
              placeholder="请选择模板版本号"
            >
              <el-option
                v-for="item in appVersionList.appVersionInfos"
                :key="item.app_version"
                :label="item.app_version + '(' + item.version_status_text + ')'"
                :value="item.app_version"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <template v-if="chooseButtonMes.match('appMembersCreate')">
            <!-- 添加开发者或体验者-->
            <el-form-item label="支付宝登录账号" prop="logonId">
              <el-input
                class="div_input_single"
                v-model="postParameter.logonId"
                placeholder="请输入支付宝登录账号"
                maxlength="100"
                show-word-limit
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="角色类型" prop="role">
              <el-select
                filterable
                v-model="postParameter.role"
                placeholder="请选择角色类型"
              >
                <el-option
                  v-for="item in appMembersCreateRoleList"
                  :key="item.label"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </template>
          <template v-if="chooseButtonMes.match('auditApply')">
            <!-- 提交审核 -->
            <!-- 小程序名称 -->
            <el-form-item label="应用名称" prop="appName">
              <el-input
                class="div_input_single"
                v-model="postParameter.appName"
                placeholder="请输入应用名称"
                maxlength="30"
                show-word-limit
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="应用英文名称" prop="appEnglishName">
              <el-input
                class="div_input_single"
                v-model="postParameter.appEnglishName"
                placeholder="请输入应用英文名称"
                maxlength="30"
                show-word-limit
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="小程序描述" prop="appDesc">
              <el-input
                type="textarea"
                class="div_input_single"
                v-model="postParameter.appDesc"
                placeholder="请输入版本描述"
                :rows="3"
                maxlength="200"
                show-word-limit
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="版本描述" prop="versionDesc">
              <el-input
                type="textarea"
                class="div_input_single"
                v-model="postParameter.versionDesc"
                placeholder="请输入版本描述"
                :rows="3"
                maxlength="500"
                show-word-limit
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="服务区域类型" prop="regionType">
              <el-select
                filterable
                v-model="postParameter.regionType"
                clearable
                placeholder="请选择服务区域类型"
              >
                <el-option
                  v-for="item in regionTypeList"
                  :key="item.label"
                  :label="item.label"
                  :value="item.value"
                  :disabled="item.disabled"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="营业执照有效期" prop="licenseValidDate">
              <el-date-picker
                v-model="postParameter.licenseValidDate"
                type="date"
                placeholder="选择营业执照有效期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="客服电话" prop="servicePhone">
              <el-input
                class="div_input_single"
                v-model="postParameter.servicePhone"
                placeholder="请输入客服电话"
                maxlength="30"
                show-word-limit
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="应用简介" prop="appSlogan">
              <el-input
                class="div_input_single"
                v-model="postParameter.appSlogan"
                placeholder="请输入小程序应用简介"
                maxlength="32"
                show-word-limit
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="logo图标" prop="appLogo">
              <el-upload
                ref="appLogoUploadRef"
                :action="$httpUrl + 'upload/smallRoutineTemplateUploadFile'"
                :show-file-list="false"
                :headers="headerObj"
                :on-success="handleAvatarSuccess"
                :on-change="handleChange"
                :before-upload="beforeAvatarUpload"
                :data="{
                  appId: postParameter.merchantAppId,
                  oldImg: chooseImages.appLogoHttp,
                }"
              >
                <img
                  v-if="chooseImages.appLogo"
                  :src="chooseImages.appLogo"
                  class="imgAppLogo"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <category
              ref="categoryRef"
              :code="categoryMes.code"
              :type="categoryMes.type"
              :chooseMes="categoryMes.chooseMes"
            />
          </template>
          <template v-if="chooseButtonMes.match('baseInfoModify')">
            <el-form-item label="应用名称">
              <el-input
                class="div_input_single"
                v-model="postParameter.appName"
                placeholder="请输入应用名称"
                maxlength="20"
                show-word-limit
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="英文名称">
              <el-input
                class="div_input_single"
                v-model="postParameter.appEnglishName"
                placeholder="请输入英文名称"
                maxlength="20"
                show-word-limit
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="应用简介">
              <el-input
                class="div_input_single"
                v-model="postParameter.appSlogan"
                placeholder="请输入应用简介"
                maxlength="32"
                show-word-limit
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="应用描述">
              <el-input
                type="textarea"
                class="div_input_single"
                v-model="postParameter.offlineLink"
                placeholder="请输入应用描述"
                :rows="2"
                maxlength="200"
                show-word-limit
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="客服电话">
              <el-input
                class="div_input_single"
                v-model="postParameter.servicePhone"
                placeholder="请输入客服电话"
                maxlength="30"
                show-word-limit
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="客服邮箱">
              <el-input
                class="div_input_single"
                v-model="postParameter.serviceEmail"
                placeholder="请输入客服邮箱"
                maxlength="128"
                show-word-limit
                clearable
              ></el-input>
            </el-form-item>
            <category
              ref="categoryRef"
              :code="categoryMes.code"
              :type="categoryMes.type"
              :chooseMes="categoryMes.chooseMes"
            />
          </template>
          <el-form-item label="">
            <el-button
              class="buttonSingle"
              type="primary"
              @click="submit('formMesRef')"
            >
              提交
            </el-button>
          </el-form-item>
        </el-form>
      </div>
      <!-- 结果展示 -->
      <div class="div_result div_modle">
        <div class="div_title">执行结果</div>
        <div class="div_result_mes">
          <div class="div_result_mes_success" v-show="resultMes.success">
            {{ resultMes.mes }}
          </div>
          <div class="div_result_mes_err" v-show="resultMes.err">
            {{ resultMes.mes }}
          </div>
          <el-link
            v-show="!isNull(resultMes.href)"
            type="primary"
            @click="toCard()"
          >
            查看链接
          </el-link>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import category from "../category/category.vue";
export default {
  props: ["showList", "showSingleMes", "refreshList"],
  components: {
    category,
  },
  data() {
    return {
      headerObj: { Authorization: window.sessionStorage.getItem("token") },
      formMesRules: {
        appVersion: [
          {
            required: true,
            message: "请输入商家小程序版本号",
            trigger: "blur",
          },
        ],
        templateVersion: [
          { required: true, message: "请输入模板版本号", trigger: "change" },
        ],
        logonId: [
          {
            required: true,
            message: "请输入被授权的支付宝登录账号",
            trigger: "blur",
          },
        ],
        role: [
          { required: true, message: "请选择角色类型", trigger: "change" },
        ],
        appName: [
          { required: true, message: "请输入小程序名称", trigger: "blur" },
          {
            min: 3,
            max: 30,
            message: "长度在 3 到 30 个字符",
            trigger: "blur",
          },
        ],
        appEnglishName: [
          {
            min: 3,
            max: 30,
            message: "长度在 3 到 30 个字符",
            trigger: "blur",
          },
        ],
        versionDesc: [
          {
            required: true,
            message: "请输入小程序版本描述",
            trigger: "blur",
          },
          {
            min: 30,
            max: 500,
            message: "长度在 30 到 500 个字符",
            trigger: "blur",
          },
        ],
        regionType: [
          { required: true, message: "请选择服务区域类型", trigger: "change" },
        ],
        licenseValidDate: [
          {
            required: true,
            message: "请选择营业执照有效期",
            trigger: "change",
          },
        ],
        servicePhone: [
          {
            required: true,
            message: "请输入小程序客服电话",
            trigger: "blur",
          },
          {
            min: 5,
            max: 30,
            message: "长度在 5 到 30 个字符",
            trigger: "blur",
          },
        ],
        appSlogan: [
          {
            required: true,
            message: "请输入小程序应用简介",
            trigger: "blur",
          },
          {
            min: 10,
            max: 32,
            message: "长度在 10 到 32 个字符",
            trigger: "blur",
          },
        ],
        appDesc: [
          {
            min: 20,
            max: 200,
            message: "长度在 20 到 200 个字符",
            trigger: "blur",
          },
        ],
      },
      postParameter: {
        // ———— 必填 ————
        // 小程序类型
        smallRoutineType: "",
        // 小程序唯一标识
        code: "",
        // 小程序模板 APPID
        templateId: "",
        // 商家appId
        merchantAppId: "",
        // 商家授权token
        appAuthToken: "",
        // 小程序模板版本号。版本号必须满足 x.y.z, 且均为数字。不传默认使用最新在架（已上架）模板版本。
        templateVersion: "",
        // 商家小程序版本号。版本号必须满足 x.y.z, 且均为数字。要求版本号比商户之前最新的版本号高。
        appVersion: "",
        // ———— 非必填 ————
        // 自定义参数，用于配置商家小程序的个性化内容，具体使用方法参见示例代码。
        ext: "",
        // 小程序投放的端参数，默认支付宝端
        bundleId: "",
        // ———— 添加开发者或体验者接口必填 ————
        logonId: "",
        role: "",
        // ———— 提交审核 ————
        // 小程序应用名称,长度限制 3~20 个字符
        appName: "",
        // 小程序应用英文名称,长度限制 3~20 个字符
        appEnglishName: "",
        // 小程序版本描述，30-500个字符
        versionDesc: "",
        // 小程序服务区域类型
        regionType: "CHINA",
        // 营业执照有效期，格式为 yyyy-MM-dd，9999-12-31表示长期有效。
        licenseValidDate: "9999-12-31",
        // 小程序客服电话，长度限制5~30个字符
        servicePhone: "13110655990",
        // 小程序应用简介，一句话描述小程序功能，长度限制 10~32个字符。
        appSlogan: "",
        // 小程序logo图标
        appLogo: "",
        // 小程序应用描述，长度限制 20~200 个字。
        appDesc: "",
      },
      // 模板版本列表
      templateMes: {
        // 模板名称
        name: "",
        appVersionInfos: [],
        // 小程序名称
        smallRoutineName: "",
      },
      // 选择的方法
      chooseButtonMes: "",
      // 输入模块展示
      inputMes: {
        templateVersion: false,
        appVersion: false,
        appVersionSelect: false,
        appMembersCreate: false,
        // 提交审核
        auditApply: false,
      },
      // 商家小程序版本列表
      appVersionList: {
        appVersionInfos: [],
        versionList: [],
      },
      resultMes: {
        success: false,
        err: false,
        // 结果内容
        mes: "",
        // 需要跳转的链接地址
        href: "",
      },
      // 添加开发者或体验者角色类型
      appMembersCreateRoleList: [
        {
          label: "开发者",
          value: "DEVELOPER",
        },
        {
          label: "体验者",
          value: "EXPERIENCER",
        },
      ],
      // 服务区域类型
      regionTypeList: [
        {
          label: "全球",
          value: "GLOBAL",
          disabled: true,
        },
        {
          label: "中国",
          value: "CHINA",
          disabled: false,
        },
        {
          label: "指定区域",
          value: "LOCATION",
          disabled: true,
        },
      ],
      // 选中的图片临时路径
      chooseImages: {
        // 本地路径
        appLogo: "",
        // 服务器路径
        appLogoHttp: "",
      },
      appLogoData: {
        appId: "",
      },
      // 类目
      categoryMes: {
        code: "",
        type: "",
        chooseMes: "",
      },
    };
  },
  watch: {
    showSingleMes(e) {
      this.init(e);
    },
  },
  created() {
    this.init(this.showSingleMes);
  },
  methods: {
    // 初始化
    async init(e) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.templateMes.smallRoutineName = e.name;
      await this.initPostParameter(e);
      this.chooseMes("upload");
      this.getTemplateVersion(e);
      this.listQuery();
      loading.close();
    },
    // async getTemplateAppIdById(id){
    //   this.postParameter.templateId = "";
    // },
    async initPostParameter(e) {
      let mes = await this.getIsvCodeByTemplateId(e.templateId);
      this.postParameter.code = mes.isvCode;
      this.postParameter.merchantTableId = e.id;
      this.postParameter.merchantAppId = e.merchantAppId;
      this.postParameter.templateId = mes.merchantAppId;
      this.postParameter.smallRoutineType = e.smallRoutineType;
      this.postParameter.appAuthToken = e.appAuthToken;
      this.categoryMes.chooseMes = mes.category;
      this.categoryMes.type = mes.isvType;
      this.categoryMes.code = mes.isvCode;
    },
    // 根据模板id获取模板数据
    async getIsvCodeByTemplateId(id) {
      const { data: res } = await this.$http.get(
        "/thirdSmallRoutineTemplate/getMesById?id=" + id
      );
      let message = res.message;
      if (res.code != "200") {
        return this.$message.error("版本列表查询失败:" + message);
      }
      return message;
    },
    toList() {
      this.showList();
    },
    // 获取模板版本号
    async getTemplateVersion(e) {
      const { data: res } = await this.$http.get(
        "/thirdSmallRoutine1/listQuery?id=" + e.templateId
      );
      let message = res.message;
      if (res.code != "200") {
        return this.$message.error("获取模板版本号失败:" + message);
      }
      this.templateMes.name = message.name;
      this.templateMes.appVersionInfos = message.appVersionInfos;
      if (message.versionList.length == 0) {
        this.$message.warning("当前模板还未上传版本，请先上传版本");
      }
    },
    // 选择接口
    chooseMes(e) {
      let chooseButtonMes = this.chooseButtonMes;
      if (e == chooseButtonMes) {
        return;
      }
      this.initResultMes();
      // switch (e) {
      //   case "upload":
      //   case "appMembersCreate":
      //   case "auditApply":
      //   case "buildQuery":
      //   case "listQuery":
      //   case "versionDelete":
      //   case "experienceCreate":
      //   case "experienceQuery":
      //   case "experienceCancel":
      //   case "auditCancel":
      //     // 上传版本
      //     // 添加开发者或体验者
      //     // 提交审核
      //     // 查询版本构建状态
      //     // 版本列表查询
      //     // 删除商家小程序版本
      //     // 生成商家小程序体验版
      //     // 小程序体验版状态查询接口
      //     // 取消体验版
      //     // 撤销审核
      //     break;
      //   default:
      //     return this.$message.error("选择失败,请重新选择");
      // }
      this.chooseButtonMes = e;
    },
    // 上传版本
    async upload() {
      // let postParameter = this.postParameter;
      // let appVersion = postParameter.appVersion;
      // if (this.isNull(appVersion)) {
      //   this.$message.warning("请输入小程序版本号");
      //   return;
      // }
      // const { data: res } = await this.$http.post(
      //   "/thirdSmallRoutineTemplate/upload",
      //   this.loginMes
      // );
      // if (res.code == "200") {
      //   let mes = res.message;
      //   window.sessionStorage.setItem("userName", mes.name);
      //   window.sessionStorage.setItem("roleGrade", mes.roleGrade);
      //   window.sessionStorage.setItem("token", mes.token);
      //   // 默认登录账号
      //   window.localStorage.setItem("loginDefaultMes", this.loginMes.account);
      //   this.$notify({
      //     title: "登陆登录成功",
      //     type: "success",
      //   });
      //   this.$router.push({ path: "/home" });
      // } else {
      //   this.$message.error(res.message);
      // }
    },
    // 提交
    submit(formName) {
      // 表单提交信息校验
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitFormMes();
        } else {
          return false;
        }
      });
    },
    async submitFormMes() {
      let chooseButtonMes = this.chooseButtonMes;
      let postParameter = this.postParameter;
      let categoryRef = this.$refs.categoryRef;
      if (!this.isNull(categoryRef)) {
        let category = categoryRef.getChooseCategoryMes();
        postParameter.miniCategoryIds = category;
      }
      const { data: res } = await this.$http.post(
        "/thirdParty/" + chooseButtonMes,
        postParameter
      );
      let message = res.message;
      if (res.code == "200") {
        let successMes = "操作成功";
        this.$message.success(successMes);
        switch (chooseButtonMes) {
          case "upload":
          case "versionDelete":
          case "auditApply":
          case "auditCancel":
          case "auditedCancel":
          case "online":
          case "offline":
          case "rollback":
            // 上传
            // 删除商家小程序版本
            // 提交审核
            // 撤销审核
            // 退回开发
            // 上架
            // 下架
            // 回滚
            this.listQuery();
            break;
          case "experienceQuery":
            // 小程序体验版状态查询接口
            this.resultMes.href = message.expQrCodeUrl;
            successMes = message.statusText;
            break;
          default:
            successMes = message;
            break;
        }
        if (chooseButtonMes == "auditApply") {
          // 更新页面小程序名称
          this.templateMes.smallRoutineName = postParameter.appName;
          // 提交审核刷新列表
          this.refreshList();
        }
        this.setResultSuccessMes(successMes);
        return;
      } else {
        this.$message.error(message);
        return this.setResultErrMes(message);
      }
    },
    // 版本列表查询
    async listQuery() {
      const { data: res } = await this.$http.post(
        "/thirdParty/listQuery",
        this.postParameter
      );
      let message = res.message;
      if (res.code != "200") {
        return this.$message.error("版本列表查询失败:" + message);
      }
      let appVersion = this.postParameter.appVersion;
      let appVersionInfos = message.appVersionInfos;
      let flay = true;
      for (let i = 0, length = appVersionInfos.length; i < length; i++) {
        if (appVersionInfos[i].app_version == appVersion) {
          flay = false;
          break;
        }
      }
      if (flay) {
        this.postParameter.appVersion = "";
      }
      this.appVersionList = message;
    },
    isNull(e) {
      return e == null || e == undefined || e == "";
    },
    setResultErrMes(e) {
      let resultMes = this.resultMes;
      resultMes.success = false;
      resultMes.err = true;
      resultMes.mes = e;
    },
    setResultSuccessMes(e) {
      let resultMes = this.resultMes;
      resultMes.success = true;
      resultMes.err = false;
      resultMes.mes = e;
    },
    initResultMes() {
      let resultMes = this.resultMes;
      resultMes.success = false;
      resultMes.err = false;
      resultMes.mes = "";
      resultMes.href = "";
    },
    // 打开结果里的链接
    toCard() {
      let resultMes = this.resultMes;
      let href = resultMes.href;
      if (this.isNull(href)) {
        this.$message.warning("打开失败,跳转链接为空");
      }
      window.open(href);
    },
    // logo图片上传成功
    handleAvatarSuccess(e) {
      let message = e.message;
      if (e.code != 200) {
        this.$message.error(message);
        return;
      }
      this.chooseImages.appLogoHttp = message;
      this.postParameter.appLogo = message;
    },
    async handleChange(file, fileList) {
      if (!this.beforeAvatarUpload(file.raw)) {
        return;
      }
      if (fileList.length == 0) {
        // 清空选中列表
        return;
      }
      if (fileList.length > 1) {
        // 多选删除之前选中的文件
        fileList.splice(0, 1);
        this.$refs.appLogoUploadRef.uploadFiles = fileList;
      }
      let url;
      if (window.createObjectURL != undefined) {
        url = window.createObjectURL(file.raw);
      } else if (window.URL != undefined) {
        url = window.URL.createObjectURL(file.raw);
      } else if (window.webkitURL != undefined) {
        url = window.webkitURL.createObjectURL(file.raw);
      } else {
        url = URL.createObjectURL(file.raw);
      }
      this.chooseImages.appLogo = url;
      // this.getArrayBuffer(file.raw);
    },
    getArrayBuffer(file) {
      let fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = function (ev) {
        const result = ev.target.result;
        this.postParameter.buffer = result;
      };
      // return new Promise((resolve, reject) => {
      //   const reader = new FileReader();
      //   reader.readAsArrayBuffer(file);
      //   reader.onload = () => resolve(reader.result);
      //   reader.onerror = (error) => reject(error);
      // });
    },
    beforeAvatarUpload(file) {
      switch (file.type) {
        case "image/jpeg":
        case "image/png":
        case "image/jpg":
        case "image/PNG":
        case "image/JPG":
        case "image/JPEG":
          // 图片格式校验
          break;
        default:
          this.$message.error("上传头像图片只能是 jpeg,png,jpg 格式!");
          return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        return this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isLt2M;
    },
  },
};
</script>

<style lang="less" scoped>
.div_home {
  // height: 90%;
  position: relative;
  .commodityImg {
    width: 100px;
    height: 100px;
  }
  .head_input {
    display: flex;
    .div_input {
      display: flex;
      align-items: center;
      // flex: 1;
      // max-width: 350px;
      padding-right: 5px;
      .div_input_text {
        white-space: nowrap;
        padding-right: 5px;
      }
    }
  }
  .div_modle {
    padding-top: 20px;
    .div_button_modle {
      .div_button_modle_title {
        padding-right: 20px;
        font-weight: 700;
        font-size: 20px;
      }
    }
  }
  .div_title {
    width: 100%;
    // background-color: aquamarine;
    // text-align: center;
    font-size: 30px;
    color: #626263;
    padding-bottom: 10px;
  }
  // 点击的按钮
  .div_button {
    .buttonSingle {
      margin-top: 10px;
    }
  }
  // 输入模块
  .div_input {
    .div_input_single {
      width: 60%;
    }
  }

  // 结果展示
  .div_result {
    // position: absolute;
    // bottom: 20px;
    width: 90%;
    .div_result_mes {
      border: 2px solid #ccc;
      border-radius: 10px;
      width: 100%;
      height: 200px;
      padding: 10px;
      overflow: auto;
      font-size: 20px;
      .div_result_mes_success {
        color: green;
      }
      .div_result_mes_err {
        color: red;
      }
    }
  }
  // 文件上传
  .avatar-uploader-icon,
  .imgAppLogo {
    height: 100px;
    width: 100px;
  }
  .avatar-uploader-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed #d9d9d9;
    font-size: 25px;
  }
}
</style>
